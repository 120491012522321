import {memo} from "react";
import {useIntl} from "react-intl";
import _ from "lodash";
import {CommonFilterProps, DimensionFilter, FiltersSchema} from "@feature/commons";
import Placeholder from "@ui-components/Placeholder";
import {getDimensionFilterMapping} from "./lib/constants";

export const CommonDimensionFilters = memo(
	function Component<F extends FiltersSchema>({
		                                            filters,
		                                            setFilters,
		                                            endpointURL,
		                                            loading,
		                                            asyncProps = {},
		                                            onFilterChangeCallback
	                                            }: CommonFilterProps<F>) {
		
		const intl = useIntl();
		
		// remove item with warehouse key from getDimensionFilterMapping(intl) object
		const dimensionMap = _.omit(getDimensionFilterMapping(intl), 'warehouse');
		
		const classNames = "mx-auto w-full max-w-[300px] gap-x-2";
		
		
		return (
			<>
				{
					Object.entries(dimensionMap).map(([key, value]) =>
						
						loading
							? <Placeholder key={key} classNames={classNames}/>
							: <DimensionFilter
								classNames={classNames}
								endpointURL={endpointURL}
								key={key}
								dimension={key}
								label={value.label}
								filters={filters}
								onFilterChange={
									(values) => {
										setFilters(prevState => ({
											...prevState,
											[key]: values
										}))
										if (onFilterChangeCallback)
											onFilterChangeCallback(values);
									}
								}
								showTooltip={Boolean(value?.tooltip_text)}
								tooltipText={value?.tooltip_text ?? ""}
								asyncProps={asyncProps}/>
					)
				}
			</>
		)
	}, (prevProps, nextProps) =>
		_.isEqual(prevProps.filters, nextProps.filters)
		&& prevProps.loading === nextProps.loading
		&& prevProps.endpointURL === nextProps.endpointURL
);