import React, {useMemo, useRef, useState} from "react";
import {useIntl} from "react-intl";
import MaterialTable, {Query} from "@material-table/core";
import {useInventoryAlerts} from "@feature/inventory";
import {AlertWasteExtendedSchema, InventoryAlertsDetailTableProps} from "../../types"
import {getColumns, getExportColumnMappings} from "./params";

type T = AlertWasteExtendedSchema;

export const AlertsWasteTable = ({
  runId,
  divisions,
  downloadAction,
  ...rest
}: InventoryAlertsDetailTableProps<T>) => {

  // hooks
  const intl = useIntl();

  // state
  const [query, setQuery] = useState<Query<T>>();

  // refs
  const ref = useRef<MaterialTable<T>>();

  // utilities
  const title = intl.formatMessage({id: "alertWasteFoodTitle"});
  const columns
    = useMemo(() => getColumns(divisions, intl), [divisions, intl]);  // avoid filter reset on rerender

  const {fetch, download, loading} = useInventoryAlerts<T>(runId, 'waste', columns);

  return (
    <MaterialTable
      tableRef={ref}
      data={fetch}
      columns={columns}
      title={title}
      isLoading={loading}
      onFilterChange={() => { setQuery((ref.current?.state as any)?.query) }}
      actions={[{
        ...downloadAction,
        onClick: () => {
          download(title, query, getExportColumnMappings(intl));
        }
      }]}
      {...rest}
    />
  )
}