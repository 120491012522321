import {PalletizationItemSchema, PalletizationOrderSchema} from "@feature/entry";

export const getFlgDays = <T extends PalletizationOrderSchema | PalletizationItemSchema>(isPickup: boolean = false): {
	value: keyof T,
	name: string
}[] => {
	
	const prefix = `flg_${isPickup ? 'pickup_' : ''}`;
	
	return [
		{value: `${prefix}monday` as keyof T, name: 'Lun'},
		{value: `${prefix}tuesday` as keyof T, name: 'Mar'},
		{value: `${prefix}wednesday` as keyof T, name: 'Mer'},
		{value: `${prefix}thursday` as keyof T, name: 'Gio'},
		{value: `${prefix}friday` as keyof T, name: 'Ven'},
		{value: `${prefix}saturday` as keyof T, name: 'Sab'},
		{value: `${prefix}sunday` as keyof T, name: 'Dom'}
	]
}

export const ENTRY_UM = [
	{value: null, name: "-"},
	{value: 'KG', name: "Kg"},
	{value: 'PZ', name: "Pezzi"},
	{value: 'CRT', name: "Cartoni"},
	{value: 'STR', name: "Strati"},
	{value: 'PAL', name: "Pallet"}
]
export const ICONSTYLE = "flex-shrink-0 h-6 w-6 text-gray-100 group-hover:text-white";