import {useLayoutEffect, useMemo} from "react";

import {apiRequest, api} from "@services/apiRequest";
import AuthenticationService from "@services/AuthenticationService";
import {AuthException} from "@services/AuthenticationService/exception";
import {debounce} from "@services/debounce";

import {useToast} from "@hooks/useToast";
import {useNavigation} from "@hooks/useNavigation";

import {RequestTokenHandler} from "./handlers";
import {
  DEBOUNCE_DELAY_MS,
  NO_REFRESH_ENDPOINTS,
  NO_REFRESH_STATUS_CODES,
  SHOW_ISREFRESHING_TOAST,
} from "./constants";

const shouldTriggerLogoutOnError = (error) => {
  /**
   * if the auth token is expired we get a 401 error or 403
   * in this case we redirect to the login page
   */
  const isAuthError = error instanceof AuthException;
  const isRefreshEndpoint = NO_REFRESH_ENDPOINTS.includes(error?.config?.url);
  const isRefreshStatusCode = NO_REFRESH_STATUS_CODES.includes(
    error?.response?.status
  );

  return !isRefreshEndpoint && (isRefreshStatusCode || isAuthError);
};

/**
 * debounce is not really needed because we handle multiple
 * refreshToken in flight calls through the RequestTokenHandler
 * but it is safe to debounce this if called from multiple places
 * and avoids multiple toasts
 */
const toastIsRefreshing = debounce(
  (toast) => {
    toast("refreshing_session_wait", "info", 1000);
  },
  DEBOUNCE_DELAY_MS,
  true
);

const toastSessionExpired = debounce(
  (toast) => {
    toast("session_expired", "info");
  },
  DEBOUNCE_DELAY_MS,
  true
);

/**
 * This function must return the access token if it was refreshed successfully
 * or null as we do in RequestTokenHandler.getToken
 */
const _refresh_token = async (tryrefresh, toast, notify = true) => {
  if (
    !tryrefresh ||
    !AuthenticationService.getRefreshToken() ||
    AuthenticationService.isRefreshTokenExpired()
  ) {
    return null;
  }
  if (notify && SHOW_ISREFRESHING_TOAST) {
    toastIsRefreshing(toast);
  }
  try {
    const access_token = await AuthenticationService.refreshToken();
    if (!access_token) {
      return null;
    }
    return access_token;
  } catch (error) {
    return null;
  }
};

/**
 * debounce is needed to handle
 * multiple logout_and_redirect calls
 * in sequence that would fire multiple
 * redirects and multiple notifications
 */
const _logout_and_redirect = debounce(
  (navigate, toast, notify = true) => {
    AuthenticationService.logout_and_redirect((path) => {
      if (notify) {
        toastSessionExpired(toast);
      }
      navigate(path);
    });
  },
  DEBOUNCE_DELAY_MS,
  true
);

export const useApiRequestInterceptors = (tryrefresh = true) => {
  const toast = useToast();
  const navigate = useNavigation();

  const apiAuth = useMemo(() => {
    return {
      refreshToken: (notify = true) =>
        _refresh_token(tryrefresh, toast, notify),
      logoutAndRedirect: (notify = true) =>
        _logout_and_redirect(navigate, toast, notify),
    };
  }, [toast, navigate, tryrefresh]);

  useLayoutEffect(() => {
    const tokenHandler = new RequestTokenHandler();

    apiRequest.defaults.headers.common["Content-Type"] = "application/json";
    api.defaults.headers.common["Content-Type"] = "application/json";

    const requestInterceptorFunctions = [
      async (request) => {
        const {publicRequest, optionalAuth} = request;
        if (publicRequest) {
          return request;
        }

        /**
         * do not catch exceptions for this await
         * we expect either null or a valid access token
         * getToken automatically enqueues the requests
         * that were waiting for the access token
         */
        request.headers["Authorization"] = "";
        let access_token = await tokenHandler.getToken(apiAuth);
        if (access_token) {
          request.headers["Authorization"] = `Bearer ${access_token}`;
        } else {
          if (optionalAuth) {
            /**
             * optional auth request
             * we don't need to have an auth token
             */
            return request;
          }
          /**
           * this could be the original request or any of the rejected
           * pending requests that were waiting for the access token
           * abort this axios request interceptor
           * and let the response interceptor handle the error
           */
          return Promise.reject(new AuthException());
        }

        return request;
      },
      (error) => {
        return Promise.reject(error);
      },
    ];

    const responseInterceptorFunctions = [
      (response) => {
        return response;
      },
      (error) => {
        /**
         * We, for now, do not handle in flight jwt expirations and retry
         * the request. We just redirect to the login page
         * if the auth token is expired we get a 401 error or 403
         * in this case we redirect to the login page
         */
        if (shouldTriggerLogoutOnError(error)) {
          apiAuth.logoutAndRedirect();
          // We return a new promise to avoid the error to be shown
          return new Promise(() => {
          });
        }
        return Promise.reject(error);
      },
    ];
    const requestInterceptor = apiRequest.interceptors.request.use(
      ...requestInterceptorFunctions
    );

    const responseInterceptor = apiRequest.interceptors.response.use(
      (response) => {
        return response.data;
      },
      responseInterceptorFunctions[1]
    );

    const requestInterceptorApi = api.interceptors.request.use(
      ...requestInterceptorFunctions
    );

    const responseInterceptorApi = api.interceptors.response.use(
      ...responseInterceptorFunctions
    );

    return () => {
      tokenHandler.clear();
      apiRequest.interceptors.request.eject(requestInterceptor);
      apiRequest.interceptors.response.eject(responseInterceptor);

      api.interceptors.request.eject(requestInterceptorApi);
      api.interceptors.response.eject(responseInterceptorApi);
    };
  }, [apiAuth]);

  return apiAuth;
};
