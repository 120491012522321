import React, {ChangeEvent} from "react";
import Select from "@ui-components/Select";
import {umChoiceProps} from "@feature/entry";

export const UmChoice = ({value, onChange, customStyle, options}: umChoiceProps) => (
	<div className="min-w-[6rem]">
		<Select
		options={options}
		value={value}
		label="Um"
		onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
		customStyles={customStyle}
	/>
	</div>
)