import DatePicker from "@ui-components/DatePicker";
import Button from "@ui-components/Button";
import {useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {DatesSelectorProps} from "@feature/home";

export const DatesSelector = ({from, to, onSubmit, disabled}: DatesSelectorProps) => {

  // hooks
  const intl = useIntl();

  // state
  const [localFrom, setLocalFrom] = useState(from);
  const [localTo, setLocalTo] = useState(to);

  // utilities
  const isSubmitDisabled = disabled
    || !localFrom
    || !localTo
    || (from === localFrom && to === localTo)  // no changes
    || localFrom > localTo;  // invalid dates
  const commonDatePickerProps = {
    disabled: disabled,
    minDate: null,
    labelClassNames: "text-xs mb-1",
    placeholder: intl.formatMessage({id: "insert_date"}),
    errorMessage: intl.formatMessage({id: "select_date"}),
  };
  
  return <div className="flex flex-col md:flex-row gap-3 items-center md:items-end">
    <div className="flex gap-3">
      <DatePicker
        value={localFrom}
        onChange={setLocalFrom}
        label={intl.formatMessage({id: "date_from"})}
        {...commonDatePickerProps}
      />
      <DatePicker
        value={localTo}
        onChange={setLocalTo}
        label={intl.formatMessage({id: "date_to"})}
        {...commonDatePickerProps}
      />
    </div>
    <div className="flex-1">
      <Button
        className="py-3 sm:py-2.5"
        onClick={() => { onSubmit(localFrom, localTo);}}
        disabled={isSubmitDisabled}
      >
        <FormattedMessage id="apply" />
      </Button>
    </div>
  </div>
}