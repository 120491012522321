import React, {Dispatch, SetStateAction, useContext, useEffect, useMemo, useRef, useState} from "react";
import MaterialTable, {MTableToolbar, Query} from "@material-table/core";
import {useIntl} from "react-intl";

import {tableIcons} from "@ui-components/TableIcons";
import TableStyle from "@ui-components/TableStyle";
import {DataContext} from "@services/DataContext";
import RadioGroup from "@ui-components/RadioGroup";

import {getCommonProps} from "@feature/commons";
import {InventoryContextSchema} from "@feature/inventory";
import {getColumns, getInventoryExportColumnMappings, OPTIONS} from "./params";
import {InventoryReplenishmentExtendedWithCodReorderAccountantSchema} from "../types";
import {useInventoryReplenishment, useInventoryUnderstockCount} from "../hooks";
import {addOrder} from "./lib/utils";
import {AddRowDialog} from "../add-row-dialog"
import {ModifyRowDialog} from "../modify-row-dialog";
import {getRadioButtonOptions} from "@feature/inventory/lib/utils";
import {useMrpLookup} from "@feature/inventory/replenishment/hooks";

type T = InventoryReplenishmentExtendedWithCodReorderAccountantSchema;

export function ReplenishmentTable() {
	
	const ref = useRef<MaterialTable<T[]>>();
	
	const didMount = useRef(false);
	
	// enable translations
	const intl = useIntl();
	
	const {
		inventoryCurrentRun,
		divisions,
		inventoryFilters,
	} = useContext(DataContext) as InventoryContextSchema;
	
	const [modifyData, setModifyData] = useState<T>();
	const [refreshTrigger, setRefreshTrigger] = useState<number>(0);
	const [showAdd, setShowAdd] = useState<boolean>(false);
	const [showType, setShowType] = useState<'All' | 'Emergency'>('All');
	const [query, setQuery] = useState<Query<T>>();
	
	const {mrpLookup, loading: mrpLoading} = useMrpLookup(inventoryCurrentRun?.id_run);
	
	const columns = useMemo(() => getColumns(
		divisions ?? [],
		mrpLookup ?? {},
		intl
	), [divisions, intl, mrpLookup]);
	
	const commonProps = getCommonProps<T>(intl, OPTIONS);
	
	const {count} = useInventoryUnderstockCount(inventoryCurrentRun?.id_run!);
	
	const {
		fetch,
		download,
		loading
	} = useInventoryReplenishment<T>(inventoryCurrentRun?.id_run!, columns, showType !== 'All');
	
	const refresh = () => setRefreshTrigger(prevState => prevState + 1);
	
	const msg = (id: string) => intl.formatMessage({id});
	
	// manually trigger pagination query when the deps change (e.g. new date or time selected, new filters, toggled "urgent")
	useEffect(() => {
		if (didMount.current)
			refresh();
		else didMount.current = true;
	}, [showType, inventoryFilters, inventoryCurrentRun?.id_run]);
	
	
	return (
		<>
			<TableStyle>
				<MaterialTable
					{...commonProps}
					key={refreshTrigger}
					tableRef={ref}
					data={fetch!}
					title={msg("inventoryReorderProposal")}
					columns={columns}
					isLoading={loading || mrpLoading}
					onFilterChange={() => setQuery((ref.current?.state as any)?.query)}
					components={{
						Toolbar: (props) => (
							<>
								<MTableToolbar {...props} />
								<RadioGroup horizontal options={getRadioButtonOptions(intl, count)}
								            currentValue={showType} classNames="m-5"
								            onChange={(value: "All" | "Emergency") => setShowType(value)}/>
							</>
						)
					}}
					actions={[
						{
							...commonProps.downloadAction,
							onClick: () => download!("InventoryOptimizationExport", query, getInventoryExportColumnMappings(intl)),
						},
						{
							icon: () => <tableIcons.Add/>,
							tooltip: `${msg("add")} ${msg("suggestion")}`,
							onClick: () => setShowAdd(true),
							isFreeAction: true,
						},
						rowData => ({
							icon: () => rowData.cod_order_status ? <tableIcons.CheckComplete/> : <tableIcons.Check/>,
							onClick: (_, rowData) => addOrder(rowData as T, inventoryCurrentRun?.id_run!, refresh),
							disabled: Boolean(rowData.cod_order_status),
							tooltip: !rowData.cod_order_status ? `${msg("add")} ${msg("order")}` : undefined
						}),
						rowData => ({
							icon: () => <tableIcons.Edit/>,
							tooltip: `${msg("edit")} ${msg("suggestion")}`,
							onClick: (_, rowData) => setModifyData(rowData as T),
							hidden: Boolean(rowData.cod_order_status),
						})
					]}
				/>
			</TableStyle>
			<AddRowDialog
				open={showAdd}
				toggleOpen={() => setShowAdd(false)}
				refresh={refresh}
			/>
			<ModifyRowDialog
				open={Boolean(modifyData)}
				toggleOpen={() => {
					setModifyData(undefined);
				}}
				refresh={refresh}
				data={modifyData!}
				setData={setModifyData as Dispatch<SetStateAction<Partial<T>>>}
			/>
		</>
	);
}
