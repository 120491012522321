import {useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useAsyncError} from "@hooks/useAsyncError";
import {NotificationsContext} from "@ui-components/Notifications";
import {api} from "@services/apiRequest";
import {DataContext} from "@services/DataContext";
import {InventoryContextSchema} from "@feature/inventory/types";
import {UnderstockCountType} from "../types";
import {castFiltersToArrays} from "../../../commons";

export const useInventoryUnderstockCount = (runId: number): UnderstockCountType => {

  // hooks
  const {push} = useContext(NotificationsContext);
  const throwError = useAsyncError();
  const intl = useIntl();

  // state
  const [count, setCount] = useState<number>(0);
  const [loadingCount, setLoadingCount] = useState<boolean>(false);

  const {inventoryFilters} = useContext(DataContext) as InventoryContextSchema;

  useEffect(() => {

    if (!inventoryFilters.division)
      return

    // fetch alert counts
    (async () => {
      setLoadingCount(true);
      try {
        const {data} = await api.post<number>(
          `/inventory/${runId}/understock-count`,
          castFiltersToArrays(inventoryFilters)
        );
        setCount(data);
      } catch (e) {
        push({title: intl.formatMessage({id: 'generic_error'}), type: "error"});
        throwError(e);
      } finally {
        setLoadingCount(false);
      }
    })();

  }, [runId, push, throwError, inventoryFilters, intl]);

  return {count, loadingCount};
};
