import {useState} from "react";
import {useIntl} from "react-intl";
import Tabs from "@ui-components/Tabs";
import {DemandChart} from "../demand-chart";
import {DemandTable} from "../demand-table";
import {BlurredPlaceholder} from "@feature/commons";
import {DemandForecastingResponseSchema} from "../types";

export function DemandTableChartBody({data, loading}: { data: DemandForecastingResponseSchema[], loading: boolean }) {

  const intl = useIntl();

  const [tabIndex, setTabIndex] = useState(0);

  const tabNames = ['demand_chart', 'demand_table'].map(translation_key => ({name: intl.formatMessage({id: translation_key})}))

  return (
    <div className="flex flex-col items-center gap-y-10">
      <Tabs setActiveTab={setTabIndex} active={tabIndex} tabs={tabNames}/>
      {
        tabIndex === 0
          ? (
            <BlurredPlaceholder loading={loading}>
              <DemandChart data={data}/>
            </BlurredPlaceholder>
          )
          : <DemandTable data={data} loading={loading}/>
      }
    </div>
  )
}