import {createContext, useContext, useCallback} from "react";
import {useHistory} from "react-router-dom";

const NavigationContext = createContext(null);

export const useNavigationContext = () => {
  const context = useContext(NavigationContext);

  if (!context) {
    throw new Error(
      "useNavigationContext must be used within a NavigationProvider"
    );
  }

  return context;
};

export const NavigationProvider = ({children}) => {
  const history = useHistory();

  const navigate = useCallback((path) => {
    history.push(path);
  }, [history]);

  return (
    <NavigationContext.Provider value={{navigate}}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigate = () => {
  const {navigate} = useNavigationContext();
  return navigate;
};
