import React from "react";
import {useIntl} from "react-intl";
import Modal from "@ui-components/Modal";
import Tooltip from "@ui-components/Tooltip";
import {IS_DC_ENABLED} from "@feature/commons";
import {ItemConstraintsModalProps} from "@feature/entry";
import {ItemConstraintsTable} from "@feature/entry/palletization/item-constraints";


export const ItemConstraintsModal = ({opened, onExit, itemConstraints, refetch}: ItemConstraintsModalProps) => {
	
	const intl = useIntl();
	
	const msg = (msgId: string) => intl.formatMessage({id: msgId});
	
	if (!opened)
		return <></>;
	
	return (
		<Modal opened={opened} onExit={onExit} maxWidth="max-w-[70vw]">
			<div className="flex items-center justify-between mx-8">
				<div className="flex flex-col gap-y-2 mx-5">
					{
						IS_DC_ENABLED &&
            <span><b>{msg('warehouse')}: </b>{`${itemConstraints.cod_dc} - ${itemConstraints.des_dc}`}</span>
					}
					<span><b>{msg('Fornitore')}: </b>{`${itemConstraints.cod_supplier} - ${itemConstraints.business_name}`}</span>
					<span>
          <b>{msg('address')}: </b>
						{
							`${itemConstraints.cod_purchase_address} - ${itemConstraints.des_purchase_address} ${itemConstraints.city}, ${itemConstraints.cod_province}`
						}
        </span>
					<span><b>{msg('Divisione')}: </b> {itemConstraints.des_division}</span>
				</div>
				{
					itemConstraints.item_validation_errors.length > 0 && (
						<div className="flex items-center gap-x-3">
							<span className="font-bold">{intl.formatMessage({id: 'error'})}</span>
							<Tooltip
								styleType='danger'
								placement="bottom"
								text={
									itemConstraints.item_validation_errors.map(error_msg => intl.formatMessage({id: error_msg}))
								}/>
						</div>
					)
				}
			</div>
			<h1 className="text-center text-2xl font-bold capitalize my-5">{msg('item_constraints')}</h1>
			<ItemConstraintsTable itemConstraints={itemConstraints} refreshPalletizationTableTrigger={refetch} />
		</Modal>
	)
}