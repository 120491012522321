import en_aware_generic from "./aware_generic/en_aware_generic";
import en_orders from "./orders/en_orders";
import en_demand from "./demand/en_demand";
import en_faq from "./faq/en_faq";
import en_form from "./form/en";
import en_data_entry from "./data-entry/en_data_entry"
import {en_camst} from "./camst/camst-en"

const en = {
  entity_user: "Management of users in the system",
  entity_role: "Management of authorization roles in the system",
  entity_audit: "Management of audit in the system",
  entity_price: "Manage operations on price-related data",
  name_and_surname: "Name and surname",
  language_it: "Italiano",
  language_en: "English",
  language: "Language",
  Home: "Home",
  disabled_user: "Disabled user",
  enter_name_and_surname: "Enter your name and surname",
  enter_name_or_email: "Enter email and name",
  need_to_select_a_role: "You must select a role",
  delete_user: "Delete the user?",
  disabled: "Disabled",
  profile: "Profile",
  user_updated_successfully: "User updated successfully",
  user_deleted_successfully: "User successfully deleted",
  user_created_successfully: "User successfully created",
  description: "Description",
  role_name: "Role name",
  add_role_name: "Enter the name of the role",
  add_role: "Add role",
  add_user: "Add user",
  deleted_role: "Role deleted",
  updated: "Updated",
  delete_element: "Are you sure you want to delete the element?",
  role_already_used: "Role already in use!",
  role_created_successfully: "Role created successfully",
  add: "Add",
  add_success: "Item Added successfully",
  edit_success: "Item modified successfully",
  server_error: "Server Error",
  confirm_new_password: "Confirm password",
  passwords_not_matching: "The two passwords do not match",
  password_without_spaces: "The password must not contain spaces",
  password_length: "The password must be at least 8 characters long",
  save: "Save",
  exit: "Exit",
  error: "Error",
  success: "Operation completed Successfully",
  warning: "Warning",
  edit: "Modifica",
  signup: "Register",
  error_while_saving: "Error while saving",
  authentication_failed: "Authentication failed",
  generic_error: "An Error Occurred, please contact support",
  send: "Send",
  user_not_found: "User not found",
  wrong_old_password: "Wrong old password",
  enter_actual_password: "Enter current password",
  actual_password: "Current password",
  new_password: "New password",
  email: "Email",
  name: "Name",
  role: "Role",
  user_type: "User type",
  my_profile: "My profile",
  access_denied: "Access denied",
  password_updated_successfully: "Password changed successfully",
  submit_password: "Send password",
  enter_your_email_address: "Enter the email address associated with your user",
  restore_password: "Recover password",
  forgot_password: "Forgot your password?",
  edit_password: "Change password",
  show_password: "Show password",
  form_error: "Check the form for errors",
  enter_email: "Enter email",
  missing_sender_mail: "Missing sender mail",
  log_into_your_account: "Log into your account",
  wrong_email_or_password: "Wrong email or password",
  unregistered_email: "Email not registered",
  forgot_password_msg: "Mail sent! If you don't hear from us in the next 15 minutes, please double check that you entered the correct email address and check your spam folder.",
  roles: "Roles",
  roles_displayed_rows: "{from}-{to} of {count} roles",
  users: "Users",
  users_displayed_rows: "{from}-{to} of {count} users",
  users_displayed_record: "{from}-{to} of {count} record",
  empty_table: "No records to display",
  no_data: "No available data",
  components: "Components",
  maps: "Maps",
  tables: "Tables",
  graphs: "Graphs",
  inventory: "Inventory",
  data_entry: "Data Entry",
  users_settings: "User management",
  welcome: "Welcome",
  test: "test_it",
  period: "Period",
  daily: "Daily",
  weekly: "Weekly",
  firsts: "first",
  results: "results",
  code: "Code",
  Cumulato: "grouped",
  Divisione: "Division",
  Famiglia: "Family",
  Fornitore: "Supplier",
  warehouse: "Warehouse",
  recipients_home_tooltip: "The recipient filter applies only to outbound products",
  Gruppo_merce: "Group",
  address: "Address",
  other_measures: "Other measures",
  on_sale: "On Sale",
  order_date: "Order date",
  expected_delivery: "Expected delivery",
  data_label: "Selected date:",
  set_hour: "Time:",
  waste_food: "Waste Food",
  parametri: "Parameters",
  model_errors: "Errors",
  price: "Price",
  orders: "Orders",
  order: "Order",
  weight: "Weight",
  quantity: "Quantity",
  suggestion: "Suggestion",
  supply_group: "Create a new supply group",
  over_stock: "Over Stock",
  under_stock: "Under Stock",
  inventoryReorderProposal: "Replenishment Suggestions",
  choose_multiple: "Choose a multiple of ",
  choose_period: "Choose period",
  Destinatario: "Recipient",
  Materiale: "Material",
  date_from: "Start date",
  date_to: "End date",
  insert_date: "Insert date",
  select_date: "Select date",
  filter_values: "Filter values",
  apply: "Apply",
  tooltip_home_map: "The map shows the outbound goods divided by province",
  filter_by: "Filter by {target}",
  ordinamento: "Sorting",
  Entrate: "In",
  Uscite: "Out",
  reference_date: "Reference date",
  expiration_date: "Expiration date",
  dat_last_out: "Last out date",
  no_options: "No available options",
  loading: "Loading ...",
  inventoryLoading: "Updating inventory data ...",
  inventoryLoadingStart: "Fetching Inventory data ...",
  inventoryEmptyTooltip: "'Empty' is the theoretical amount of goods in warehouse in case no reordering is made",
  inventorySafetyTooltip: "'Safety' is the minimum amount of goods. It is based on demand coverage, so it is variable over time.",
  inventoryStockTooltip: "'Stock' shows the amount of goods in warehouse in case the reordering proposal made by the optimizer is followed",
  stock: "Stock",
  empty: "Empty",
  safety: "Safety",
  inventoryMissingSuggestions: "No replenishment suggestions available",
  inventoryRunsTooltip: "The optimizer executes a complete run at night (05:15) and two partial runs at 9:25 and at 15:55. The last available run is set by default",
  inventoryChartNoData: "No available data",
  alertWasteFoodTitle: "Expired materials in inventory",
  alertOverStockTitle: "Exceeding good groups in the next 10 days",
  alertUnderStockTitle: "Top 100 good groups to reorder today",
  cod_item_error: "Item Code in error",
  des_item_error: "Item in error",
  cod_product_error: "Product Code in error",
  des_product_error: "Product in error",
  audit: "Audit",
  frontend: "Frontend",
  backend: "Backend",
  file_picker_placeholder: "Select one or more files",
  success_ticket_sent: "The ticket was added successfully, we will take the request in charge as soon as possibile.",
  hubspot_portal: "Hubspot Customer Portal",
  size_limit_exceeded: "Size limit exceeded",
  customer_portal_loading_failed: "Customer portal link loading error",
  week_datepicker: "Week ",
  week_datepicker_not_selected: "Weekly Datepicker",
  daily_datepicker_not_selected: "Daily Datepicker",
  thanks: "Thanks!",
  select_an_option: "Please select an option from the dropdown menu.",
  ticket_parameters_not_found: "Ticket parameters not found",
  troisi_parameters_not_found: "Mail parameters not found",
  error_new_ticket_creation: "An error occurred while creating a new ticket",
  error_ticket_hubspot_management: "New ticket is created, but went wrong during the operations made by our portal",
  error_ticket_file_attachment: "New ticket is created, but it's not possibile to attach the file specified",
  session_expired: "Session expired",
  refreshing_session_wait: "Refreshing session, please wait",
  item_association: "Item association",
  to_be_compiled: "Records to compile",
  to_be_compiled_only: "Consider only records to compile",
  sap_orders: "SAP Orders",
  type_something: "Type something",
  add_row_choose: "Select product to add to replenishment list",
  add_row_dat_order: "Select Replenishment date",
  add_row_dat_delivery: "Select Expected Delivery date",
  add_row_warehouse: "Select the warehouse",
  add_row_item: "Search the product you want to insert",
  add_row_alert: "The combination product - order date already exists among the Aware suggestions. Please modify the corresponding order instead of adding a new one",
  add_row_warning: "You are trying to insert an order outside the time horizon of Aware, so it is necessary to manually enter the expected delivery date",
  add_row_info: "The supplier of the selected product usually delivers in {leadtime} working days",
  add_row_val_quantity_multiples: "Desired Quantity",
  ...en_aware_generic,
  ...en_demand,
  ...en_faq,
  ...en_form,
  ...en_data_entry,
  ...en_orders,
  ...en_camst,
};

export default en;
