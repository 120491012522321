import React, {useState} from "react";
import IconButton from "../IconButton";
import {TooltipProps} from "./Tooltip.types";

function Tooltip({
                   text,
                   customStyle = "",
                   iconName = "ExclamationCircleIcon",
                   placement = "top",
                   styleType
                 }: TooltipProps) {
  const [isHovering, setIsHovering] = useState(false);

  let position;

  switch (placement) {
    case "top":
      position = "bottom-7"
      break;
    case "bottom":
      position = "top-7"
      break;
    case "left":
      position = "right-7 -bottom-3"
      break;
    case "right":
      position = "left-7 -bottom-3"
      break;
    default:
      break;
  }

  return (
    <div className={`group relative flex justify-center ${customStyle}`}>
      <IconButton
        styleType={styleType}
        onMouseOver={() => setIsHovering(true)}
        onMouseOut={() => setIsHovering(false)}
        iconName={iconName}/>
      {
        isHovering &&
        <span
          className={`absolute ${position} z-50 w-36 text-center scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100`}>
        {
          Array.isArray(text)
            ? <ul>{text.map(el => <li key={el} className="my-1">{el}</li>)}</ul>
            : text
        }
      </span>
      }
    </div>
  )
}

export default Tooltip;