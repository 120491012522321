import React, {useContext} from "react";
import {FormattedMessage} from "react-intl";
import {useLocation} from "react-router";
import {Link} from "react-router-dom";
import {DataContext} from "@services/DataContext";
import SmallNotification from "../SmallNotification";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const MenuLink = ({
                    name, href, Icon, clickable = true, setClickable, onTouchEnd = () => {
  }
                  }) => {
  const {pathname} = useLocation();
  const active = pathname.indexOf(href) >= 0;

  const {
    dtnItemsErrors,
    dtnItemsLoading,
    palletizationErrors,
    palletizationLoading,
    itemsToAssociateCount,
    associateErrorLoading
  } = useContext(DataContext);

  return (
    <>
      {clickable ? (
        <Link
          key={name}
          to={href}
          className={classNames(
            active
              ? "bg-gray-200 text-black"
              : "text-black hover:bg-gray-50 hover:text-gray-900",
            "group flex items-center px-2 py-2 text-base font-medium rounded-3xl"
          )}
          onTouchEnd={() => onTouchEnd()}
        >
          <Icon
            className={classNames(
              active ? "text-black" : "text-black group-hover:text-gray-500",
              "mr-4 h-6 w-6"
            )}
            aria-hidden="true"
          />
          <FormattedMessage id={name}/>
          {
            name === 'data_entry' &&
            !dtnItemsLoading && !associateErrorLoading && !palletizationLoading &&
            (palletizationErrors + itemsToAssociateCount + dtnItemsErrors > 0) &&
            <SmallNotification
              counter={palletizationErrors + itemsToAssociateCount + dtnItemsErrors}
              enableAnimation/>
          }
        </Link>) : (
        <div className={classNames(active ? " bg-gray-100 hover:text-" : "",
          "group flex items-center px-2 py-2 text-base font-medium rounded-3xl")}>
          <button onClick={() => setClickable()}>
            <Icon
              className={classNames(active ? "bg-gray-50  group-hover:text-gray-500" : "group-hover:text-white",
                "-ml-1 mr-4 h-6 w-6")}
              aria-hidden="true"
            />
          </button>
        </div>
      )}
    </>
  );
};

export default MenuLink;
