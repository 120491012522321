import {HomeTarget} from "../../types";

export const getTitleIntlId = (target: HomeTarget) => {
  switch (target) {
    case "item":
      return "Materiale";
    case "area":
      return "Famiglia";
    case "supplier":
      return "Fornitore";
    case "recipient":
      return "Destinatario";
    default:
      return target;
  }
}