import React, {Dispatch, SetStateAction, useContext, useState} from "react";
import {ChevronRightIcon, XCircleIcon} from "@heroicons/react/24/outline";
import {GroupedOrderType, OrdersContextSchema} from "@feature/orders";
import {DataContext} from "@services/DataContext";
import Modal from "@ui-components/Modal";
import {handleOrderRemoval} from "./lib/utils";
import {useIntl} from "react-intl";

export function OrderCard({order, setEditableOrder}: {
	order: GroupedOrderType,
	setEditableOrder: Dispatch<SetStateAction<GroupedOrderType | undefined>>
}) {
	
	const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
	
	const {setRefetchOrders} = useContext(DataContext) as OrdersContextSchema;
	
	const intl = useIntl();
	
	const msg = (id: string) => intl.formatMessage({id});
	
	return (
		<>
			<div
				className={`flex justify-between rounded-3xl bg-gray-100 w-full p-5 shadow ${confirmDelete && 'animate-wiggle'}`}>
				<div className="flex flex-col gap-y-5">
					<h1 className="font-bold text-xl capitalize">{msg('order')} {order.business_name}</h1>
					<ul className="ml-6 list-inside">
						{order.items.map(item => (
							<li key={item.cod_item} className="font-bold flex flex-col space-y-2">
								<span>{item.des_item}</span>
								<small>{item.des_division}</small>
								<small>{item.cod_reorder_accountant}</small>
								<small>{item.flg_order_on_sale && 'ordinato sul venduto'}</small>
							</li>
						))}
					</ul>
					<h3>
						<b>{msg('delivery_date')}:</b> {new Intl.DateTimeFormat('it-IT').format(new Date(order.current_dat_delivery))}
					</h3>
				</div>
				<div className="flex flex-col items-center justify-evenly">
					<ChevronRightIcon
						className="h-7 w-7 rounded-full p-0.5 bg-am-600 text-white cursor-pointer hover:bg-am-700"
						onClick={() => setEditableOrder(order)}/>
					<div className="flex gap-x-2 items-end">
          <span className="font-bold text-xl">
          {order.items.reduce((tot, el) => el.current_val_quantity_purchase_order + tot, 0)}
        </span>
						<span>{order.items[0].um_purchase_order}</span>
					</div>
					<div className="flex gap-x-2 items-center" onClick={() => setConfirmDelete(true)}>
						<h6 className="text-am-600 cursor-pointer">{msg('delete')}</h6>
						<XCircleIcon className="h-4 w-4 text-am-600 cursor-pointer"/>
					</div>
				</div>
			</div>
			<Modal
				opened={confirmDelete}
				title={msg('delete_order_text')}
				onExit={() => setConfirmDelete(false)}
				onDismiss={() => setConfirmDelete(false)}
				onConfirm={() => handleOrderRemoval(order, setRefetchOrders)}
				enableConfirm
				enableDismiss
				type="warning"
				confirmText="OK"
				dismissText={msg('cancel')}
				children={undefined}/>
		</>
	)
}
