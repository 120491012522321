import React, {Fragment} from "react";
import AuthenticationService from "../../services/AuthenticationService";
import {Link} from "react-router-dom";
import {Menu, Transition} from "@headlessui/react";
import {ChevronUpDownIcon} from "@heroicons/react/24/solid";
import {useIntl} from "react-intl";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const UserBox = () => {
  const userData = AuthenticationService.getUserData() || {};
  const {full_name, role_name} = userData;

  const intl = useIntl();

  return (
    <Menu as="div" className="mb-6 py-3 relative inline-block text-left w-full">
      <div>
        <Menu.Button
          className="group w-full bg-am-200 rounded-3xl pl-4 pr-2 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
          data-cy="user-box"
        >
          <span className="flex w-full justify-between items-center">
            <span className="flex min-w-0 items-center justify-between space-x-2">
              <span className="flex-1 flex flex-col min-w-0">
                <span className="text-white text-sm font-bold truncate group-hover:text-am-700 leading-none pb-1">
                  {full_name}
                </span>
                <span className="text-white text-xs truncate capitalize leading-none group-hover:text-am-700 pb-1">
                  {role_name}
                </span>
              </span>
            </span>
            <ChevronUpDownIcon
              className="shrink-0 h-5 w-5 text-white  group-hover:text-am-700"
              aria-hidden="true"
            />
          </span>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Menu.Items
          id="menu"
          className="z-10 overflow-hidden origin-top flex flex-col absolute right-0 left-0 mt-1 rounded-3xl shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
        >
          <Menu.Item className="hover:text-white w-full py-4 px-4 text-gray-700 hover:bg-am-200">
            {({active}) => (
              <Link
                to={`/profile`}
                className={classNames(
                  active ? "bg-am-200 text-gray-900" : "text-gray-700",
                  "block px-4 py-2 text-sm"
                )}
              >
                {intl.formatMessage({id: "profile"})}
              </Link>
            )}
          </Menu.Item>
          <Menu.Item className="hover:text-white w-full py-4 px-4 text-gray-700 hover:bg-am-200">
            {({ active }) => (
              <a
                href="/logout"
                onClick={(e) => {
                  e.preventDefault();
                  AuthenticationService.logout();
                  window.location = "/login";
                }}
                className={classNames(
                  active ? "bg-am-200 text-gray-900" : "text-gray-700",
                  "block px-4 py-2 text-sm "
                )}
                data-cy="logout-sidebar-button"
              >
                Logout
              </a>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default UserBox;
