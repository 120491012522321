import * as am5 from "@amcharts/amcharts5";

export const AWARE_CHART_PALETTE: [am5.Color, string][] = [
  [am5.color("rgb(80,126,145)"),
    am5.color("rgb(80,126,145)").toCSS(0.5)],
  [am5.color("rgb(164,36,59)"),
    am5.color("rgb(164,36,59)").toCSS(0.5)],
  [am5.color("rgb(229,148,53)"),
    am5.color("rgb(229,148,53)").toCSS(0.5)],
  [am5.color("rgb(127, 0, 255)"),
    am5.color("rgb(127, 0, 255)").toCSS(0.5)],
  [am5.color("rgb(97, 171, 109)"),
    am5.color("rgb(97, 171, 109)").toCSS(0.5)]
];

export const DEFAULT_CHART_COLORS = [
  am5.color("rgb(0, 60, 80)"),
  am5.color("rgb(8, 127, 140)"),
  am5.color("rgb(90, 170, 149)"),
  am5.color("rgb(134, 168, 115)"),
  am5.color("rgb(152,151,102)"),
  am5.color("rgb(187, 159, 6)"),
  am5.color("rgb(222,164,54)"),
  am5.color("rgb(234,151,59)"),
  am5.color("rgb(234,120,59)"),
  am5.color("rgb(206,96,76)"),
  am5.color("rgb(238,130,168)"),
  am5.color("rgb(232,131,219)"),
  am5.color("rgb(166,101,183)"),
  am5.color("rgb(109,97,150)"),
  am5.color("rgb(80,103,121)"),
  am5.color("rgb(67,80,107)"),
  am5.color("rgb(89,97,115)"),
];

export const ERROR_COLOR = 'rgb(242,118,126)'
export const DUMMY_SERIE_COLOR = 'rgb(156,168,157)'

export const ADD_CART_COLOR = '#fdfd96'
export const ORDER_CONFIRMED_COLOR = '#90ee90'

export const STOCK_IN_COLOR = '#31cc91'
export const STOCK_OUT_COLOR = '#589cf4'

export const getDefaultAwareChartPalette = () => AWARE_CHART_PALETTE.map(color => color[0]);