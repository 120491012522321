import React, {useState} from "react";
import {useIntl} from "react-intl";
import IconButton from "@ui-components/IconButton";
import Modal from "@ui-components/Modal";
import {InventoryAlertButtonProps} from "../types";
import {AlertsTable} from "../alerts-table";

export const AlertButton = ({
  runId,
  divisions,
  name,
  counts,
  loading,
  icon,
}: InventoryAlertButtonProps) => {

  // hooks
  const intl = useIntl();

  // state
  const [open, setOpen] = useState<boolean>(false);

  return <>
    <IconButton
      notification={!loading && counts || 0}
      disabled={loading || !counts}
      key={icon}
      classNames="items-center mx-2 h-14 w-14 px-4 py-2"
      iconName={icon}
      label={intl.formatMessage({id: name})}
      onClick={() => setOpen(true)}
      loading={loading}
    />
    <Modal opened={open} onExit={() => setOpen(false)} maxWidth="sm:max-w-5xl">
      <div className="pt-8">
        <AlertsTable runId={runId} divisions={divisions} name={name} />
      </div>
    </Modal>
  </>
}