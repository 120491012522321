import React, {Dispatch, SetStateAction} from "react";
import {IntlShape} from "react-intl";
import {Options} from "@material-table/core";
import {MinusIcon, PencilIcon, ShoppingBagIcon, TruckIcon} from "@heroicons/react/24/outline";
import Tooltip from "@ui-components/Tooltip";
import MaterialTableCustomCell from "@ui-components/MaterialTableCustomCell";
import {IS_DC_ENABLED} from "@feature/commons";
import {MTColumnsEditType, PalletizationSchema} from "@feature/entry/types";
import {DivisionSchema} from "@feature/commons/types";
import {ERROR_COLOR} from "@theme/index";
import Button from "@ui-components/Button";

type T = PalletizationSchema;

export const getColumns = (
	intl: IntlShape,
	divisions: DivisionSchema[],
	setEditReorderGroupId: Dispatch<SetStateAction<number | undefined>>,
	setOrderConstraints: Dispatch<SetStateAction<T | undefined>>,
	setItemConstraints: Dispatch<SetStateAction<T | undefined>>,
): MTColumnsEditType<T> => [
	{
		title: intl.formatMessage({id: "warehouse"}),
		field: "cod_dc",
		hidden: !IS_DC_ENABLED,
		editable: 'never',
		render: rowData => (
			<MaterialTableCustomCell vertical data1={rowData.cod_dc} data2={rowData.des_dc}/>
		),
		pagination: {
			spech: {
				column_name: ["cod_dc", "warehouse.des_dc"],
				filter_operator: "ilike any",
				value_parser(value) {
					return `%${value}%`
				},
			}
		},
	},
	{
		title: intl.formatMessage({id: "Divisione"}),
		field: "des_division",
		lookup: divisions.reduce((acc, {des_division}) => ({...acc, [des_division]: des_division}), {}),
		editable: 'never',
		pagination: {
			spech: {
				column_name: ["cod_division"],
				filter_operator: "in",
				value_parser(value: string[]) {
					return divisions.filter(({des_division}) => value.includes(des_division)).map(({cod_division}) => cod_division)
				}
			}
		},
	},
	{
		title: intl.formatMessage({id: "Fornitore"}),
		field: "cod_supplier",
		render: rowData => (
			<MaterialTableCustomCell vertical data1={rowData.cod_supplier} data2={rowData.business_name}/>
		),
		pagination: {
			spech: {
				column_name: ["cod_supplier", "supplier.business_name"],
				filter_operator: "ilike any",
				value_parser(value) {
					return `%${value}%`
				},
			}
		},
	},
	{
		title: intl.formatMessage({id: "address"}),
		field: "cod_purchase_address",
		render: rowData => (
			<MaterialTableCustomCell
				vertical
				data1={rowData.cod_purchase_address}
				data2={`${rowData.des_purchase_address}, ${rowData.city}, (${rowData.cod_province})`}/>
		),
		pagination: {
			spech: {
				column_name: ["cod_purchase_address", "purchase_address.des_purchase_address", "purchase_address.city", "purchase_address.cod_province"],
				filter_operator: "ilike any",
				value_parser(value) {
					return `%${value}%`
				},
			}
		},
	},
	{
		title: intl.formatMessage({id: "grouping"}),
		field: "id_delivery_group",
		filtering: false,
		cellStyle: {textAlign: 'center'},
		render: rowData => rowData.id_delivery_group
			? (
				<Button
					styleType="empty"
					onClick={() => setEditReorderGroupId(rowData.id_delivery_group!)}>
					{rowData.id_delivery_group}
					<PencilIcon className="shrink w-5 h-5 ml-1"/>
				</Button>
			)
			: <MinusIcon className="w-5 mx-auto"/>,
	},
	{
		title: intl.formatMessage({id: "active"}),
		field: "flg_active",
		type: "boolean"
	},
	{
		title: intl.formatMessage({id: "supplier_constraints"}),
		filtering: false,
		render: rowData =>
			<div className="flex items-center gap-x-2">
				<Button
					styleType={rowData.order_validation_errors.length ? "danger" : "empty"}
					onClick={() => setOrderConstraints(rowData)}
					className="text-sm"
				>
					<TruckIcon className="shrink w-6 h-6"/>
				</Button>
				{
					rowData.order_validation_errors.length ?
						<Tooltip styleType='danger' text={
							rowData.order_validation_errors.map(error_msg => intl.formatMessage({id: error_msg}))
						}/>
						: <></>
				}
			</div>
	},
	{
		title: intl.formatMessage({id: "item_constraints"}),
		field: "num_valid_items",
		render: rowData =>
			<div className="flex items-center gap-x-2">
				<p className="font-semibold">{rowData.num_valid_items}</p>
				<Button
					styleType={rowData.num_valid_items > 0 && rowData.item_validation_errors.length + rowData.order_item_relation_errors.length > 0 ? "danger" : "empty"}
					onClick={() => setItemConstraints(rowData)}
				>
					<ShoppingBagIcon className="shrink w-6 h-6"/>
				</Button>
				{
					rowData.num_valid_items > 0 && rowData.item_validation_errors.length + rowData.order_item_relation_errors.length > 0 ? (
							<Tooltip placement='left' styleType='danger' text={
								rowData.item_validation_errors.concat(rowData.order_item_relation_errors).map(error_msg => intl.formatMessage({id: error_msg}))
							}/>
						)
						: <></>
				}
			</div>,
		filtering: false
	}
]

export const OPTIONS: Options<T> = {
	pageSize: 20,
	pageSizeOptions: [20, 50, 80],
	rowStyle: (rowData: T) => ({
		backgroundColor: rowData.order_validation_errors.length + (
			rowData.num_valid_items > 0
				? rowData.item_validation_errors.length + rowData.order_item_relation_errors.length
				: 0
		) > 0
			? ERROR_COLOR
			: ''
	})
};