import {useContext, useState} from "react";
import {NotificationsContext} from "@ui-components/Notifications";
import {useAsyncError} from "@hooks/useAsyncError";
import {Query} from "@material-table/core";
import {MTColumnsType} from "@utils/pagination";
import {ExcelDownload, fetchPagination} from "@feature/commons/utils";

export function useDtnItemFetch<T extends object>(
  columns: MTColumnsType<T>,
  notCompleted: boolean
) {
  // hooks
  const {push} = useContext(NotificationsContext);
  const throwError = useAsyncError();

  // state
  const [loading, setLoading] = useState<boolean>(false);

  const baseUrl = `/entry/item`;


  const fetch = async (query: Query<T>) => fetchPagination(
    baseUrl,
    query,
    columns,
    undefined,
    {not_completed: notCompleted},
    push,
    setLoading,
    throwError
  )

  const download = async (name: string, query?: Query<T>, columnMappings?: { [key: string]: string }) => ExcelDownload(
    baseUrl,
    columns,
    name,
    query,
    columnMappings,
    setLoading,
    push,
    throwError,
    {not_completed: notCompleted}
  );

  return {fetch, download, loading};
}