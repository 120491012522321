import {useContext, useEffect, useState} from "react";
import {api} from "@services/apiRequest";
import {DataContext} from "@services/DataContext";
import {DemandContextSchema, DemandRunsTypes} from "@feature/demand/types";
import {NotificationsContext} from "@ui-components/Notifications";
import {useAsyncError} from "@hooks/useAsyncError";
import {useIntl} from "react-intl";

export function useDemandRuns() {

  const {push} = useContext(NotificationsContext);
  const throwError = useAsyncError();
  const intl = useIntl();

  const {
    demandRuns,
    setDemandRuns,
    setDemandCurrentRun
  } = useContext(DataContext) as DemandContextSchema;

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    if (!demandRuns.length)
      api.get<DemandRunsTypes[]>("demand/available_runs")
        .then(({data}) => {
          setDemandRuns(data);
          setDemandCurrentRun(data[0]);
        })
        .catch((error) => {
          push({title: intl.formatMessage({id: 'generic_error'}), type: "error"});
          throwError(error);
        });
    setLoading(false);
  }, [demandRuns]); // eslint-disable-line react-hooks/exhaustive-deps

  return loading;

}