import {HomeFilterDimensions, HomeTarget} from "../types";

export const MU: string = "KG";
export const HOME_TARGETS: HomeTarget[] = ['item', 'area', 'recipient', 'supplier'];

export const getUM = (currentDimension: HomeFilterDimensions) => {
  switch (currentDimension) {
    case "val_quantity":
      return MU;
    case "val_price":
      return "€";
  }
}
export const FILTERICONS: { icon: string, value: HomeFilterDimensions }[] = [
  {icon: 'CubeIcon', value: 'val_quantity'},
  {icon: 'CurrencyEuroIcon', value: 'val_price'},
]