import React, {useCallback, useContext, useState} from "react";
import {useIntl} from "react-intl";
import Modal from "@ui-components/Modal";
import {AddDeliveryGroupProps, GroupOptionsSchema} from "@feature/entry";
import {NotificationsContext} from "@ui-components/Notifications";
import {InformationCircleIcon} from "@heroicons/react/24/outline";
import Button from "@ui-components/Button";
import {ConstraintsMap} from "@feature/entry/palletization/delivery-group";
import {addPurchaseGroup} from "@feature/entry/palletization/lib/utils";

const InfoBadge = ({text}: { text: string }) => (
	<div className="flex items-center justify-evenly bg-blue-500 rounded-md py-5 px-4 gap-x-4">
		<InformationCircleIcon className="w-16 text-white p-2"/>
		<span className="text-white">{text}</span>
	</div>
)

export function AddDeliveryGroupModal({opened, onExit, refetch}: AddDeliveryGroupProps) {
	
	const intl = useIntl();
	
	const {push} = useContext(NotificationsContext);
	
	
	const [newDeliveryGroup, setNewDeliveryGroup] = useState<GroupOptionsSchema[]>([]);
	
	const msg = useCallback((msgId: string) => intl.formatMessage({id: msgId}), [intl]);
	
	const resetAndExit = () => {
		setNewDeliveryGroup([]);
		onExit();
	}
	
	const exitAndRefetch = () => {
		resetAndExit();
		refetch();
	}
	
	return (
		<Modal maxWidth="md:max-w-2xl" opened={opened} onExit={resetAndExit}
		       classNames="flex flex-col items-center gap-y-8">
			<h1 className="text-center text-2xl text-am-600 font-bold">{msg('add_supplier_group')}</h1>
			<InfoBadge text={msg('add_supplier_group_description')}/>
			<ConstraintsMap deliveryGroups={newDeliveryGroup} setDeliveryGroups={setNewDeliveryGroup}/>
			<Button
				disabled={newDeliveryGroup.length < 2 || newDeliveryGroup.every(el => !el.flg_delivery)}
				onClick={() => addPurchaseGroup(newDeliveryGroup, intl, push, exitAndRefetch)}>
				{msg('save')}
			</Button>
		</Modal>
	)
}