import {DemandForecastingResponseSchema, DetailLevelType, DimensionRadiosType} from "../types";
import {CustomFilterDatePicker} from "@components/Tables/Params/CustomDatePicker";
import {specialRound} from "@utils/functions";
import {MTColumnsType} from "@utils/pagination";
import {IntlShape} from "react-intl";
import MaterialTableCustomCell from "@ui-components/MaterialTableCustomCell";
import {DEFAULT_UM, DivisionSchema} from "@feature/commons";

type T = DemandForecastingResponseSchema

export const getColumns = (
  detail_level: DetailLevelType,
  currentRadioGroupDimension: DimensionRadiosType['dimension'],
  divisions: DivisionSchema[],
  intl: IntlShape
): MTColumnsType<T> => [
  {
    title: intl.formatMessage({id: (detail_level === 'daily') ? 'day' : 'week'}),
    field: "dat_forecast",
    type: "date",
    filterComponent: (props) => <CustomFilterDatePicker isFirstColumn {...props}/>,
    render: data => new Intl.DateTimeFormat("it-IT").format(new Date(data.dat_forecast)),
  },
  {
    title: "Dettaglio",
    field: "des_attribute",
    lookup: currentRadioGroupDimension === 'division'
      ? divisions.reduce((acc, {des_division}) => ({...acc, [des_division]: des_division}), {})
      : undefined,
  },
  {
    title: "Anno corrente",
    field: "val_quantity",
    render: rowData => (
      <MaterialTableCustomCell
        data1={specialRound(rowData.val_quantity, 1000).toLocaleString('it-IT')}
        data2={DEFAULT_UM}/>
    )
  },
  {
    title: "Anno passato",
    field: "val_quantity_py",
    render: rowData => (
      <MaterialTableCustomCell
        data1={specialRound(rowData.val_quantity_py, 1000).toLocaleString('it-IT')}
        data2={DEFAULT_UM}/>
    )
  },
];