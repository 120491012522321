import React, {useMemo, useRef} from "react";
import MaterialTable from "@material-table/core";
import {PageSection} from "@ui-components/Container";
import TableStyle from "@ui-components/TableStyle";
import {OrderType} from "@feature/orders";
import {getColumns} from "./params";
import {useIntl} from "react-intl";
import {getCommonProps} from "@feature/commons";
import {downloadExcel} from "@utils/downloadExcel";
import {MTColumnsEditType} from "@feature/entry";

type T = OrderType;

export function HistoricOrders({tableData}: { tableData: T[] }) {

  const intl = useIntl();

  const commonProps = getCommonProps<T>(intl, {emptyRowsWhenPaging: false});

  const columns = useMemo(() => getColumns(intl), [intl]);

  // override columns with jsx in the title
  // to use plain text in the excel export
  const parsedColumnsForExport = (columns:MTColumnsEditType<T>) => columns.map(column => {
    if (column.field === "tms_last_status_update") {
      return {
        ...column,
        title: intl.formatMessage({id: "dat_order_send"})
      }
    }
    return column
  });

  const ref = useRef<any>();

  return (
    <PageSection title={intl.formatMessage({id: 'historic_orders'})}>
      <TableStyle>
        <MaterialTable
          data={tableData}
          title=""
          columns={columns}
          {...commonProps}
          tableRef={ref}
          actions={[
            {
              ...commonProps.downloadAction,
              onClick: () => downloadExcel<T>({
                exportData: ref.current?.state.data as T[],
                columns: parsedColumnsForExport(columns),
                name: intl.formatMessage({id: 'download_excel'}),
              }),
            },
          ]}
        />
      </TableStyle>
    </PageSection>
  )
}