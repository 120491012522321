import {useContext, useEffect, useState} from "react";
import {api} from "@services/apiRequest";
import {HomeFilterDimensions, HomePayloadSchema, HomeTarget, StockSummarySchema} from "../types";
import {useAsyncError} from "@hooks/useAsyncError";
import {NotificationsContext} from "@ui-components/Notifications";
import {useIntl} from "react-intl";

export const useStockSummaries = (
  dateStart: string | undefined,
  dateEnd: string | undefined,
  divisions: Set<string> | undefined,
  filters: Record<HomeTarget, string[]>,
  val_dimension: HomeFilterDimensions
) => {

  // context
  const {push} = useContext(NotificationsContext);

  // hooks
  const intl = useIntl();
  const throwError = useAsyncError();

  // state
  const [data, setData] = useState<StockSummarySchema[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {

      // exit if divisions are not loaded yet
      if (!divisions || !dateStart || !dateEnd)
        return;

      setLoading(true);
      try {
        const payload = {
          date_start: dateStart,
          date_end: dateEnd,
          divisions: Array.from(divisions),
          filters,
          val_dimension
        } as HomePayloadSchema;
        const { data } = await api.post<StockSummarySchema[]>(
          "/home/stock/summaries",
          payload,
        );
        setData(data);
      }
      catch (e) {
        push({
          type: 'error',
          title: intl.formatMessage({id: 'server_error'}),
          message: `STOCK SUMMARIES`,
        });
        throwError(e);
      }
      finally {
        setLoading(false);
      }
    })();
  }, [dateStart, dateEnd, divisions, val_dimension, JSON.stringify(filters)]);  // eslint-disable-line react-hooks/exhaustive-deps

  return {data, loading};
}