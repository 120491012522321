import React from "react";
import Slider from "@material-ui/core/Slider";
import {createTheme, ThemeProvider} from "@material-ui/core/styles";

const sliderTheme = createTheme({
    overrides: {
        PrivateValueLabel: {
            circle: {
                display: "none"
            },
            offset: {
                display: "none"
            },
            label: {
                 display: "none"
            }
        }
    }
});

const SliderSelect = ({value, onChange, label = '', ...rest}) => {
    return (
        <ThemeProvider theme={sliderTheme}>
            <Slider
                aria-label={label}
                valueLabelDisplay="on"
                value={value}
                onChange={onChange}
                {...rest}
            />
        </ThemeProvider>
    );
};

export default SliderSelect;
