import {OrderStatus} from "@feature/orders";
import {
  CheckCircleIcon,
  EnvelopeIcon,
  ExclamationTriangleIcon,
  HandThumbUpIcon,
  XCircleIcon,
  PauseCircleIcon,
  BoltIcon,
} from "@heroicons/react/24/outline";
import {useIntl} from "react-intl";

export function StatusBadge({status}: { status: OrderStatus }) {

  const intl = useIntl();

  const defaultStyle: HTMLElement['className'] = 'h-5 w-5'

  let icon;
  let bgColor;
  switch (status) {
    case OrderStatus.COMPLETED:
      icon = <CheckCircleIcon className={defaultStyle}/>;
      bgColor = 'rgb(20,210,44)';
      break;
    case OrderStatus.CONFIRMED:
      icon = <HandThumbUpIcon className={defaultStyle}/>;
      bgColor = 'rgb(77,150,85)';
      break;
    case OrderStatus.SENT:
      icon = <EnvelopeIcon className={defaultStyle}/>;
      bgColor = 'rgb(77,137,208)';
      break;
    case OrderStatus.LATE:
      icon = <ExclamationTriangleIcon className={defaultStyle}/>;
      bgColor = 'rgb(241,206,55)';
      break;
    case OrderStatus.ERROR:
      icon = <XCircleIcon className={defaultStyle}/>;
      bgColor = 'rgb(155,44,44)';
      break;
    case OrderStatus.HOLD:
      icon = <PauseCircleIcon className={defaultStyle}/>;
      bgColor = 'rgb(3,7,140)';
      break;
    case OrderStatus.WIP:
      icon = <BoltIcon className={defaultStyle}/>;
      bgColor = 'rgb(3,7,140)';
      break;
    default:
      throw new Error(`status ${status} not mapped`)
  }
  return (
    <div style={{backgroundColor: bgColor}}
         className="flex rounded-3xl uppercase text-white p-1.5 font-semibold items-center justify-center gap-x-2">
      {intl.formatMessage({id: status})}
      {icon}
    </div>
  )
}
