import {useContext, useEffect, useState} from "react";
import {api} from "@services/apiRequest";
import {useIntl} from "react-intl";
import {NotificationsContext} from "@ui-components/Notifications";
import {useAsyncError} from "@hooks/useAsyncError";
import {GroupOptionsSchema} from "@feature/entry";

export function useDeliveryGroup(deliveryGroupId: number | undefined) {
	
	const [deliveryGroupData, setDeliveryGroupData] = useState<GroupOptionsSchema[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	
	const intl = useIntl();
	const {push} = useContext(NotificationsContext);
	const throwError = useAsyncError();
	
	useEffect(() => {
		if (!deliveryGroupId)
			return;
		
		setLoading(true);
		api
			.get<GroupOptionsSchema[]>(`entry//palletization/group/${deliveryGroupId}`)
			.then((res) => setDeliveryGroupData(res.data))
			.catch(err => {
				console.error(err);
				push({title: intl.formatMessage({id: "generic_error"}), type: "error"})
				throwError(err);
			});
		setLoading(false);
		
	}, [deliveryGroupId, intl, push, throwError]);
	
	return {deliveryGroupData, setDeliveryGroupData, loading};
}