import {useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {api} from "@services/apiRequest";
import {NotificationsContext} from "@ui-components/Notifications";
import {useAsyncError} from "@hooks/useAsyncError";
import {ModelParametersSchema} from "@feature/entry";

export function useParameters() {
	
	const {push} = useContext(NotificationsContext);
	const throwError = useAsyncError();
	const intl = useIntl();
	
	const [parameters, setParameters] = useState<ModelParametersSchema>();
	const [loading, setLoading] = useState<boolean>(false);
	
	useEffect(() => {
		setLoading(true);
		api.get<ModelParametersSchema>("entry/parameters")
			.then(({data}) => {
				setParameters(data);
			})
			.catch((error) => {
				push({title: intl.formatMessage({id: 'generic_error'}), type: "error"});
				throwError(error);
			});
		setLoading(false);
	}, [intl, push, throwError]);
	
	return {loading, parameters, setParameters};
	
}