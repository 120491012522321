import {useContext, useEffect, useState} from "react";

import {NotificationsContext} from "@ui-components/Notifications";
import {api} from "@services/apiRequest";
import {useAsyncError} from "@hooks/useAsyncError";
import {useIntl} from "react-intl";

export const useEntryProductsAlertCounts = () => {

  // hooks
  const { push } = useContext(NotificationsContext);
  const throwError = useAsyncError();
  const intl = useIntl();

  // state
  const [data, setData] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [refreshTrigger, setRefreshTrigger] = useState<boolean>(false);

  useEffect(() => {
    // fetch alert counts
    (async () => {
      setLoading(true);
      try {
        const {data} = await api.get<number>(`/entry/number_of_items_to_associate`);
        setData(data);
      } catch (e) {
        push({title: intl.formatMessage({id: 'generic_error'}), type: "error"});
        throwError(e);
      } finally {
        setLoading(false);
      }
    })();

  }, [intl, push, throwError, refreshTrigger]);

  return {counts: data, loading, setRefreshTrigger};
};
