import {CSSProperties, Dispatch, ReactNode, SetStateAction} from "react";
import {Filter, Query} from "@material-table/core";
import {MTColumnItemType} from "@utils/pagination";
import {CommonContextSchema, SelectOptionsType} from "@feature/commons/types";
import {TooltipProps} from "@ui-components/Tooltip/Tooltip.types";

interface CommonModalProps {
	opened: boolean;
	onExit: () => void;
}

export interface DtnItemResponseSchema {
	/** Cod Dc */
	cod_dc: string;
	/** Cod Item */
	cod_item: string;
	/** Num Day Safety */
	num_day_safety?: number;
	/** Num Additional Safety Kg */
	num_additional_safety_kg?: number;
	/** Val Additional Safety */
	val_additional_safety?: number;
	/** Des Dc */
	des_dc: string;
	/** Des Item */
	des_item?: string;
	/** Des Area */
	des_area?: string;
	/** Des Product */
	des_product?: string;
	/** Des Division */
	des_division: string;
	/** Supplier List */
	supplier_list: string[];
	/** Um Basic */
	um_basic: string;
}

export interface ItemTableProps {
	notCompleted: boolean
}

export interface ItemAssociationSchema {
}

export interface ItemAssociationReturnType<V extends ItemAssociationSchema> {
	fetch: (query: Query<V>) => Promise<{
		data: V[],
		page: number,
		totalCount: number
	}>;
	
	rowEdit: (newData: V, oldData: V | undefined) => Promise<any>;
	loading: boolean;
}

export interface ItemAssociationDetailTableProps {
	flgItemToAssociate: boolean;
}

export interface ItemBaseSchema {
	cod_dc: string
	cod_item: string;
	des_item?: string;
}

export interface ItemWithoutRelationshipSchema extends ItemBaseSchema {
	cod_product?: string;
	des_product?: string;
	dat_insertion: Date;
}

export interface ItemAssociationDetailSchema extends ItemWithoutRelationshipSchema {
	replaces: ItemWithoutRelationshipSchema[];
	replaced_by?: ItemWithoutRelationshipSchema;
}

export type ItemAssociationPath = 'item_association';

export interface DataEntryContextSchema extends CommonContextSchema {
	dtnItemsErrors: number;
	palletizationErrors: number;
	palletizationLoading: boolean;
	itemsToAssociateCount: number;
	associateErrorLoading: boolean;
	dtnItemsLoading: boolean;
	unassignedItemsTrigger: boolean;
	setUnassignedItemsTrigger: () => void;
	triggerDtnItemRefresh: () => void;
	triggerAssociateItemsRefresh: () => void;
	triggerPalletizationRefresh: () => void;
}

export type tabIndexType = 0 | 1 | 2 | 3;

export type BulkEditType = Pick<DtnItemResponseSchema, 'cod_dc' | 'cod_item'>;

export interface MTColumnEditType<T extends object> extends MTColumnItemType<T> {
	editTitle?: string;
	bulkEditable?: boolean;
}

export type MTColumnsEditType<T extends object> = MTColumnEditType<T>[];

export type EditFieldsType<T extends object> =
	Pick<MTColumnEditType<T>, 'title' | 'field' | 'type' | 'lookup' | 'editTitle'>
	& {
	value?: string | number | Date;
}

export interface BulkEditModalProps<T extends object> {
	bulkEditValues: BulkEditType[] | Filter<T>[];
	open: boolean;
	onExit: () => void;
	isAllBulkEdit: boolean;
	refresh: () => void;
	tableColumns: MTColumnsEditType<T>;
	query: Query<T>;
	notCompleted: boolean;
}

export enum StockPenaltyType {
	volume = 'volume',
	balanced = 'balanced',
	value = 'value',
}

export enum OrderPenaltyType {
	low = 'low',
	medium = 'medium',
	high = 'high',
}


export interface ModelParametersSchema {
	penalty_stock: StockPenaltyType;
	penalty_orders: OrderPenaltyType;
}

export interface PalletizationItemSchema {
	/** Cod Dc */
	cod_dc: string;
	/** Cod Item */
	cod_item: string;
	/** Cod Purchase Address */
	cod_purchase_address?: string;
	/** Cod Division */
	cod_division: string;
	/** Cod Supplier */
	cod_supplier: string;
	/** Business Name */
	business_name: string;
	/** Id Delivery Group */
	id_delivery_group: number;
	/** Des Dc */
	des_dc: string;
	/** Des Item */
	des_item: string;
	/** Flg Delivery */
	flg_delivery?: boolean;
	/** Flg Active */
	flg_active: boolean;
	/** Num Min Weight */
	num_min_weight?: number;
	/** Num Max Weight */
	num_max_weight?: number;
	/** Num Min Order */
	num_min_order?: number;
	/** Num Max Order */
	num_max_order?: number;
	/** Um Order */
	um_order?: string;
	/** Num Multiples */
	num_multiples?: number;
	/** Um Multiples */
	um_multiples?: string;
	/** Num Lead Time */
	num_lead_time?: number;
	/** Num Lead Time Custom */
	num_lead_time_custom?: number;
	/**
	 * Flg Delivery Days
	 * @default false
	 */
	flg_delivery_days?: boolean;
	/**
	 * Flg Monday
	 * @default false
	 */
	flg_monday?: boolean;
	/**
	 * Flg Tuesday
	 * @default false
	 */
	flg_tuesday?: boolean;
	/**
	 * Flg Wednesday
	 * @default false
	 */
	flg_wednesday?: boolean;
	/**
	 * Flg Thursday
	 * @default false
	 */
	flg_thursday?: boolean;
	/**
	 * Flg Friday
	 * @default false
	 */
	flg_friday?: boolean;
	/**
	 * Flg Saturday
	 * @default false
	 */
	flg_saturday?: boolean;
	/**
	 * Flg Sunday
	 * @default false
	 */
	flg_sunday?: boolean;
	/** Amt Min Price */
	amt_min_price?: number;
	/** Amt Max Price */
	amt_max_price?: number;
}

export interface PalletizationItemResponseSchema extends PalletizationItemSchema {
	/** Is Valid Item */
	is_valid_item: boolean;
}

/** PalletizationSchema */
export interface PalletizationSchema {
	/** Cod Dc */
	cod_dc: string;
	/** Cod Division */
	cod_division: string;
	/** Cod Purchase Address */
	cod_purchase_address: string;
	/** Cod Supplier */
	cod_supplier: string;
	/** Id Delivery Group */
	id_delivery_group?: number;
	/** Des Dc */
	des_dc: string;
	/**
	 * Flg Valid
	 * @default false
	 */
	flg_valid: boolean;
	/** Flg Active */
	flg_active: boolean;
	/** Des Division */
	des_division?: string;
	/** Business Name */
	business_name?: string;
	/** Des Purchase Address */
	des_purchase_address?: string;
	/** City */
	city?: string;
	/** Cod Province */
	cod_province?: string;
	/** Num Valid Items */
	num_valid_items: number;
	/**
	 * Flg Pickup Days
	 * @default false
	 */
	flg_pickup_days: boolean;
	/**
	 * Flg Pickup Monday
	 * @default false
	 */
	flg_pickup_monday: boolean;
	/**
	 * Flg Pickup Tuesday
	 * @default false
	 */
	flg_pickup_tuesday: boolean;
	/**
	 * Flg Pickup Wednesday
	 * @default false
	 */
	flg_pickup_wednesday: boolean;
	/**
	 * Flg Pickup Thursday
	 * @default false
	 */
	flg_pickup_thursday: boolean;
	/**
	 * Flg Pickup Friday
	 * @default false
	 */
	flg_pickup_friday: boolean;
	/**
	 * Flg Pickup Saturday
	 * @default false
	 */
	flg_pickup_saturday: boolean;
	/**
	 * Flg Pickup Sunday
	 * @default false
	 */
	flg_pickup_sunday: boolean;
	/** Order Validation Errors */
	order_validation_errors: string[];
	/** Item Validation Errors */
	item_validation_errors: string[];
	/** Order Item Relation Errors */
	order_item_relation_errors: string[];
}

export interface PalletizationPaginationSchema {
	/** Total */
	total: number;
	/** Items */
	items: PalletizationSchema[];
	/** Current Page */
	current_page?: number;
	/** Page Size */
	page_size?: number;
}

export interface PalletizationTableProps {
	showInvalidConstraints: boolean
	openCreateGroup: boolean;
	setOpenCreateGroup: Dispatch<SetStateAction<boolean>>;
	refresh: () => void;
	refreshTrigger: number;
}

export interface OrderConstraintsModalProps extends CommonModalProps {
	orderConstraints: PalletizationSchema;
	refetch: () => void;
}

export interface BasicCheckBoxProps {
	value: boolean;
	label: string;
	onChange: (newValue: boolean) => void;
	classNames?: string;
}

export interface BoxTitleProps {
	title: string;
	enableCheckBox?: boolean;
	enableTooltip?: boolean;
	checkBoxLabel?: BasicCheckBoxProps['label'];
	checkBoxValue?: BasicCheckBoxProps['value'];
	checkBoxOnChange?: BasicCheckBoxProps['onChange'];
	tooltipMessage?: TooltipProps['text'];
	tooltipPosition?: TooltipProps['placement'];
	classNames?: string;
	disabled?: boolean;
	children: ReactNode;
}

export interface BasicInputProps {
	value: number | undefined;
	onChange: (newValue: number | undefined) => void;
	label: string;
	classNames?: string;
	isValid?: boolean;
	errorMessage?: string
}

export interface umChoiceProps {
	value: string;
	onChange: (newValue: string) => void;
	customStyle?: CSSProperties;
	options: SelectOptionsType[];
}


/** PalletizationOrderSchema */
export interface PalletizationOrderSchema {
	/** Cod Dc */
	cod_dc: string;
	/** Cod Division */
	cod_division: string;
	/** Cod Purchase Address */
	cod_purchase_address: string;
	/** Cod Supplier */
	cod_supplier: string;
	/** Id Delivery Group */
	id_delivery_group?: number;
	/** Des Dc */
	des_dc: string;
	/**
	 * Flg Valid
	 * @default false
	 */
	flg_active: boolean;
	/** Des Division */
	des_division?: string;
	/** Business Name */
	business_name?: string;
	/** Des Purchase Address */
	des_purchase_address?: string;
	/** City */
	city?: string;
	/** Cod Province */
	cod_province?: string;
	/** Num Valid Items */
	num_valid_items: number;
	/**
	 * Flg Delivery
	 * @default false
	 */
	flg_delivery?: boolean;
	/** Num Preference */
	num_preference?: number;
	/** Num Min Weight */
	num_min_weight?: number;
	/** Num Max Weight */
	num_max_weight?: number;
	/** Num Min Order */
	num_min_order?: number;
	/** Num Max Order */
	num_max_order?: number;
	/** Um Order */
	um_order?: string;
	/** Amt Min Price */
	amt_min_price?: number;
	/** Amt Max Price */
	amt_max_price?: number;
	/** Flg Round Down */
	flg_round_down: boolean;
	/** Num Min Round Down */
	num_min_round_down?: number;
	/** Num Max Round Down */
	num_max_round_down?: number;
	/** Um Round Down */
	um_round_down?: string;
	/**
	 * Flg International
	 * @default false
	 */
	flg_international?: boolean;
	/** Num Min Weight International */
	num_min_weight_international?: number;
	/** Num Max Weight International */
	num_max_weight_international?: number;
	/** Num Min Order International */
	num_min_order_international?: number;
	/** Num Max Order International */
	num_max_order_international?: number;
	/** Um International */
	um_international?: string;
	/**
	 * Flg Alternative Rule
	 * @default false
	 */
	flg_alternative_rule?: boolean;
	/** Num Min Weight Alternative Rule */
	num_min_weight_alternative_rule?: number;
	/** Num Max Weight Alternative Rule */
	num_max_weight_alternative_rule?: number;
	/** Num Min Order Alternative Rule */
	num_min_order_alternative_rule?: number;
	/** Num Max Order Alternative Rule */
	num_max_order_alternative_rule?: number;
	/** Um Alternative Rule */
	um_alternative_rule?: string;
	/**
	 * Flg Alternative Rule Rounds Down
	 * @default false
	 */
	flg_alternative_rule_rounds_down?: boolean;
	/** Num Min Alternative Rule Rounds Down */
	num_min_alternative_rule_rounds_down?: number;
	/** Num Max Alternative Rule Rounds Down */
	num_max_alternative_rule_rounds_down?: number;
	/** Um Alternative Rule Rounds Down */
	um_alternative_rule_rounds_down?: string;
	/**
	 * Flg Multiples
	 * @default false
	 */
	flg_multiples?: boolean;
	/** Num Multiples */
	num_multiples?: number;
	/** Um Multiples */
	um_multiples?: string;
	/**
	 * Flg 2 Ref Per Pallet
	 * @default false
	 */
	flg_2_ref_per_pallet?: boolean;
	/** Num Max Deliveries Per Month */
	num_max_deliveries_per_month?: number;
	/**
	 * Flg Monday
	 * @default false
	 */
	flg_monday?: boolean;
	/**
	 * Flg Tuesday
	 * @default false
	 */
	flg_tuesday?: boolean;
	/**
	 * Flg Wednesday
	 * @default false
	 */
	flg_wednesday?: boolean;
	/**
	 * Flg Thursday
	 * @default false
	 */
	flg_thursday?: boolean;
	/**
	 * Flg Friday
	 * @default false
	 */
	flg_friday?: boolean;
	/**
	 * Flg Saturday
	 * @default false
	 */
	flg_saturday?: boolean;
	/**
	 * Flg Sunday
	 * @default false
	 */
	flg_sunday?: boolean;
	/** Num Transit Time */
	num_transit_time?: number;
	/** Num Lead Time */
	num_lead_time?: number;
	/**
	 * Flg Pickup Days
	 * @default false
	 */
	flg_pickup_days: boolean;
	/**
	 * Flg Pickup Monday
	 * @default false
	 */
	flg_pickup_monday: boolean;
	/**
	 * Flg Pickup Tuesday
	 * @default false
	 */
	flg_pickup_tuesday: boolean;
	/**
	 * Flg Pickup Wednesday
	 * @default false
	 */
	flg_pickup_wednesday: boolean;
	/**
	 * Flg Pickup Thursday
	 * @default false
	 */
	flg_pickup_thursday: boolean;
	/**
	 * Flg Pickup Friday
	 * @default false
	 */
	flg_pickup_friday: boolean;
	/**
	 * Flg Pickup Saturday
	 * @default false
	 */
	flg_pickup_saturday: boolean;
	/**
	 * Flg Pickup Sunday
	 * @default false
	 */
	flg_pickup_sunday: boolean;
	/** Num Max Sunday Track */
	num_max_sunday_track?: number;
	/** Order Validation Errors */
	order_validation_errors: string[];
}

/** PalletizationItemPrimaryKeyDataSchema */
export interface PalletizationItemPrimaryKeyDataSchema {
	/** Cod Dc */
	cod_dc: string;
	/** Cod Item */
	cod_item: string;
	/** Cod Supplier */
	cod_supplier: string;
	/** Cod Division */
	cod_division: string;
	/** Id Delivery Group */
	id_delivery_group: number;
	/** Cod Purchase Address */
	cod_purchase_address?: string;
 }


/** ConstraintsPayloadSchema */
export interface ConstraintsPayloadSchema {
	/** Cod Dc */
	cod_dc: string;
	/** Cod Division */
	cod_division: string;
	/** Cod Purchase Address */
	cod_purchase_address: string;
	/** Cod Supplier */
	cod_supplier: string;
	/** Id Delivery Group */
	id_delivery_group?: number;
}

export interface PalletizationHeaderProps {
	showInvalidConstraints: boolean;
	setShowInvalidConstraints: Dispatch<SetStateAction<boolean>>;
	setOpenCreateGroup: Dispatch<SetStateAction<boolean>>;
	refresh: () => void;
}

export interface ItemConstraintsModalProps extends CommonModalProps {
	itemConstraints: PalletizationSchema;
	refetch: () => void;
}

export interface AssignModalProps extends CommonModalProps {
	unassignedItems: PalletizationItemSchema[];
	refresh: () => void;
}

export type PalletizationItemWithAddressSchema = Array<{ des_purchase_address?: string } & PalletizationItemSchema>


/** GroupOptionsSchema */
export interface GroupOptionsSchema extends ConstraintsPayloadSchema {
	/** Business Name */
	business_name: string;
	/** Des Purchase Address */
	des_purchase_address: string;
	/** Des Division */
	des_division: string;
	/** Flg Delivery */
	flg_delivery: boolean;
}

export interface ConstraintsMapProps {
	deliveryGroups: GroupOptionsSchema[];
	setDeliveryGroups: Dispatch<SetStateAction<GroupOptionsSchema[]>>;
}

export interface AddDeliveryGroupProps extends CommonModalProps {
	refetch: () => void;
}

export interface EditDeliveryGroupProps extends CommonModalProps {
	deliveryGroupId: number;
	refetch: () => void;
}


export interface RangeBoxProps<T extends PalletizationOrderSchema | PalletizationItemSchema> {
	title: string;
	classNames?: BoxTitleProps['classNames'];
	tooltipMessage?: string;
	tooltipPosition?: TooltipProps['placement'];
	constraints: T;
	minValField: keyof T;
	maxValField: keyof T;
	umField?: keyof T;
	enableConstraintField?: keyof T;
	updateCallback: (key: keyof T, newValue: string | number | boolean | undefined) => void;
	customDisabled?: boolean;
}

export interface ApplyAllSchema extends ConstraintsPayloadSchema {
	/** Num Multiples */
	num_multiples?: number;
	/** Um Multiples */
	um_multiples?: string;
	/** Num Lead Time Custom */
	num_lead_time_custom?: number;
}