import {IntlShape} from "react-intl";
import TableDatePicker from "@components/Tables/Controls/TableDatePicker";
import {MTColumnsType} from "@utils/pagination";
import {IS_DC_ENABLED} from "@feature/commons";
import {AlertWasteExtendedSchema} from "../../types";
import {formatValue} from "../lib/format";

type T = AlertWasteExtendedSchema;

const castToItalianFormat = (d: Date | string | undefined) => {
  if(!d){
    return
  }
  if(typeof d === "string"){
    d = new Date(d);
  }
  return new Intl.DateTimeFormat('it-IT').format(d);
}

export const getColumns = (divisions: string[], intl: IntlShape): MTColumnsType<T> => [
  {
    title: intl.formatMessage({id: "warehouse"}),
    field: "cod_dc",
    type: "string",
    hidden: !IS_DC_ENABLED,
    render: ({cod_dc, warehouse: {des_dc}}) => <>
      <p className="font-bold">{cod_dc}</p>
      <p>{des_dc}</p>
    </>,
    pagination: {
      spech: {
        column_name: ["cod_dc", "warehouse.des_dc"],
      }
    },
  },
  {
    title: intl.formatMessage({id: "Divisione"}),
    field: "item.des_division",
    lookup: divisions.reduce((acc, curr) => ({...acc, [curr]: curr}), {}),
    cellStyle: {fontSize: "smaller"},
    headerStyle: {maxWidth: 95},
  },
  {
    title: intl.formatMessage({id: "Materiale"}),
    field: "cod_item",
    type: "string",
    render: ({cod_item, item: {des_item}}) => <>
      <p className="font-bold">{cod_item}</p>
      <p>{des_item}</p>
    </>,
    cellStyle: {paddingTop: 4, paddingBottom: 4},
    headerStyle: {minWidth: 220},
    pagination: {
      spech: {
        column_name: ["cod_item", "item.des_item"],
      }
    },
  },
  {
    title: "Pallet",
    field: "cod_pallet",
    type: "string",
  },
  {
    title: intl.formatMessage({id: "quantity"}),
    field: "val_stock_quantity",
    type: "numeric",
    render: ({val_stock_quantity, um}) => formatValue(val_stock_quantity, um),
  },
  {
    title: intl.formatMessage({id: "weight"}),
    field: "val_stock_quantity_kg",
    type: "numeric",
    render: ({val_stock_quantity_kg}) => formatValue(val_stock_quantity_kg, "KG"),
  },
  {
    title: intl.formatMessage({id: "expiration_date"}),
    field: "dat_expiration",
    type: "date",
    render: rowData => castToItalianFormat(rowData.dat_expiration),
    filterComponent: (props: any) => <TableDatePicker tableProps={props} minDate={null}/>,
  },
  {
    title: intl.formatMessage({id: "dat_last_out"}),
    field: "dat_last_out",
    type: "date",
    render: rowData => castToItalianFormat(rowData.dat_last_out),
    filterComponent: (props: any) => <TableDatePicker tableProps={props} minDate={null}/>,
  },
];

export const getExportColumnMappings = (intl: IntlShape) => {
  const msg = (msgId: string) => intl.formatMessage({id: msgId});
  const unpackColAttr = (field: string, translationKey: string) => {

    const translation = msg(translationKey);

    const cod_field = 'cod_' + field;
    const des_field = 'des_' + field;

    return {[`${msg("code")} ${translation}`]: cod_field, [msg(translationKey)]: des_field}
  }
  return {
    ...(IS_DC_ENABLED ? unpackColAttr('dc', 'warehouse') : {}),
    [msg("Divisione")]: "des_division",
    ...unpackColAttr('item', 'Materiale'),
    Pallet: "cod_pallet",
    [msg("quantity")]: "val_stock_quantity",
    [`um ${msg("quantity")}`]: "um",
    [`kg ${msg("weight")}`]: "val_stock_quantity_kg",
    [msg("expiration_date")]: "dat_expiration",
    [msg("dat_last_out")]: "dat_last_out",
  }
};
