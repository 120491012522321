import React from "react";
import Label from "./Label";
import Icon from "./Icon";
import Checkbox from "./Checkbox";

const CheckboxGroup = ({
                         options,
                         values = [],
                         title = "",
                         description = "",
                         onChange,
                         horizontal,
                         labelClasses = "",
                       }) => {
  if (!options || !options.length) {
    throw new Error("missing options");
  }

  return (
    <div className="mt-3 flex flex-row justify-center">
      {title && <Label classNames={labelClasses}>{title}</Label>}
      {description && <p className="text-sm leading-5 text-gray-500">{description}</p>}

      <fieldset
        className={
          horizontal
            ? "flex flex-row justify-items-center gap-x-3"
            : "flex flex-col space-y-4"
        }
      >
        {options.map(({value, icon_name}) => {
          return (
            <div
              key={value}
              onClick={() => {
                onChange(value);
              }}
              className={
                horizontal
                  ? "relative flex items-start mb-2"
                  : "relative flex items-start "
              }
            >
              <div className="flex flex-col items-center">
                <div className="flex items-center h-5">
                  <Checkbox
                    readOnly={true}
                    id={value}
                    name={value}
                    checked={values.indexOf(value) >= 0}
                  />
                </div>
                <div className="mt-2 text-sm">
                  {icon_name &&
                    <div className="flex justify-center">
                      <Icon iconName={icon_name} className="h-8 w-8 px-1 "/>
                    </div>}
                  <label className="font-medium text-gray-700 ml-1">
                    {value}
                  </label>
                </div>
              </div>
            </div>
          );
        })}
      </fieldset>
    </div>
  );
};

export default CheckboxGroup;
