import React from "react";
import {IntlShape} from "react-intl";
import {MTColumnsType} from "@utils/pagination";
import {IS_DC_ENABLED} from "@feature/commons";
import {ModelErrorSchema} from "../../types";

type T = ModelErrorSchema;

export const getColumns = (divisions: string[], intl: IntlShape): MTColumnsType<T> => [
  {
    title: intl.formatMessage({id: "warehouse"}),
    field: "warehouse.des_dc",
    type: "string",
    hidden: !IS_DC_ENABLED,
    render: ({cod_dc, warehouse: {des_dc}}) => <>
      <p className="font-bold">{cod_dc}</p>
      <p>{des_dc}</p>
    </>,
    pagination: {
      spech: {
        column_name: ["cod_dc", "warehouse.des_dc"],
      }
    },
  },
  {
    title: intl.formatMessage({id: "Divisione"}),
    field: "des_division",
    lookup: divisions.reduce((acc, curr) => ({...acc, [curr]: curr}), {}),
    cellStyle: {fontSize: "smaller", textAlign: "center"},
    headerStyle: {maxWidth: 95},
  },
  {
    title: "MRP",
    field: "cod_reorder_accountant",
    cellStyle: {textAlign: "center"},
  },
  {
    title: intl.formatMessage({id: "Materiale"}),
    field: "cod_item",
    type: "string",
    render: ({cod_item, des_item}) => <>
      <p className="font-bold">{cod_item}</p>
      <p>{des_item}</p>
    </>,
    headerStyle: {minWidth: 220},
    pagination: {
      spech: {
        column_name: ["cod_item", "des_item"],
      }
    },
  },
  {
    title: intl.formatMessage({id: "Fornitore"}),
    field: "business_name",
    render: ({cod_supplier, business_name}) => <>
      <p className="font-bold">{cod_supplier}</p>
      <p>{business_name}</p>
    </>,
    headerStyle: {minWidth: 150},
    pagination: {
      spech: {
        column_name: ["item_supplier.cod_supplier", "item_supplier.supplier.business_name"],
        filter_operator: "ilike",
        value_parser: (value: string) => `%${value}%`
      }
    },
  },
];

export const getExportColumnMappings = (intl: IntlShape) => {
  const msg = (msgId: string) => intl.formatMessage({id: msgId});
  return {
    ...(IS_DC_ENABLED ? {[`${msg("code")} ${msg("warehouse")}`]: "cod_dc", [msg("warehouse")]: "des_dc"} : {}),
    [msg("Divisione")]: "des_division",
    [msg("cod_item_error")]: "cod_item",
    [msg("des_item_error")]: "des_item",
    [msg("Fornitore")]: "business_name",
    [msg("des_item_error")]: "des_product"
  }
};