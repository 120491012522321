import {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {api} from "@services/apiRequest";
import {useIntl} from "react-intl";
import {NotificationsContext} from "@ui-components/Notifications";
import {useAsyncError} from "@hooks/useAsyncError";
import {ConstraintsPayloadSchema, PalletizationOrderSchema} from "@feature/entry";

export function useSupplierConstraints(
	payload: ConstraintsPayloadSchema | undefined,
	setData: Dispatch<SetStateAction<PalletizationOrderSchema | undefined>>
) {
	
	const [loading, setLoading] = useState<boolean>(false);
	
	const intl = useIntl();
	const {push} = useContext(NotificationsContext);
	const throwError = useAsyncError();
	
	useEffect(() => {
		
		if (!payload)
			return;
		
		setLoading(true);
		api
			.post<PalletizationOrderSchema>('entry/palletization/order', payload)
			.then((res) => setData(res.data))
			.catch(err => {
				console.error(err);
				push({title: intl.formatMessage({id: "generic_error"}), type: "error"})
				throwError(err);
			});
		setLoading(false);
		
	}, [intl, JSON.stringify(payload), push, setData, throwError]); // eslint-disable-line react-hooks/exhaustive-deps
	
	return loading;
}