import SearchSelect from "@ui-components/SearchSelect";
import _ from "lodash";
import {OptionSchema} from "@feature/commons";
import {GroupedOrderType, OrderFilterType} from "@feature/orders";
import {Dispatch, SetStateAction} from "react";
import {useIntl} from "react-intl";
import {getFilterLocalization} from "@feature/orders/lib/utils";

type T = GroupedOrderType;
type F = OrderFilterType;
export const CustomFilter = ({orders, filters, setFilters, dimension}: {
	orders: T[],
	filters: F,
	setFilters: Dispatch<SetStateAction<F>>,
	dimension: keyof F
}) => {
	
	const intl = useIntl();
	
	let translationKey: string;
	let codField: keyof T | keyof T["items"][0];
	let desField: keyof T | keyof T["items"][0];
	let isNested: boolean = false;
	
	switch (dimension) {
		case "supplier":
			translationKey = "Fornitore";
			codField = "cod_supplier";
			desField = "business_name";
			isNested = false;
			break;
		case "purchase_address":
			translationKey = "address";
			codField = "cod_purchase_address";
			desField = "des_purchase_address";
			isNested = false;
			break;
		case "item":
			translationKey = "Materiale";
			codField = "cod_item";
			desField = "des_item";
			isNested = true;
			break;
		case "division":
			translationKey = "Divisione";
			codField = "cod_division";
			desField = "des_division";
			isNested = true;
			break;
		case "reorder_accountant":
			translationKey = "cod_reorder_accountant";
			codField = "cod_reorder_accountant";
			desField = "cod_reorder_accountant";
			isNested = true;
			break;
		default:
			throw new Error(`Dimension ${dimension} not supported`);
	}
	
	return (
		<SearchSelect
			loadOptions={undefined}
			label={getFilterLocalization(translationKey, intl)}
			options={
				isNested ?
					_.uniqBy(
						orders.flatMap(
							({items}) => (
								items.map(
									(o) => ({label: o[desField as keyof T["items"][0]], value: o[codField as keyof T["items"][0]]})
								)
							)
						), "value"
					)
					: _.uniqBy(
						orders.map(
							o => ({label: o[desField as keyof T], value: o[codField as keyof T]})
						), "value"
					)
			}
			onChange={(i: OptionSchema[]) => {
				setFilters(
					prevState => ({
						...prevState,
						[dimension]: i,
					})
				)
			}}
			value={filters[dimension]}
			isMulti={true}
			CustomOption={undefined}/>
	)
}