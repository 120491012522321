import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {useIntl} from "react-intl";
import MaterialTable from "@material-table/core";
import {DataContext} from "@services/DataContext";
import TableStyle from "@ui-components/TableStyle";
import {BlurredPlaceholder, getCommonProps} from "@feature/commons";
import {DataEntryContextSchema, PalletizationSchema, PalletizationTableProps} from "@feature/entry";
import {getColumns, OPTIONS} from "./params";
import {ItemConstraintsModal, OrderConstraintsModal, usePalletizationFetch} from "@feature/entry/palletization";
import {AddDeliveryGroupModal, EditDeliveryGroupModal} from "@feature/entry/palletization/delivery-group";

type T = PalletizationSchema;

export const PalletizationTable = ({
	                                   showInvalidConstraints,
	                                   openCreateGroup,
	                                   setOpenCreateGroup,
												  refresh,
												  refreshTrigger,
                                   }: PalletizationTableProps) => {
	
	// hooks
	const intl = useIntl();
	
	const {divisions} = useContext(DataContext) as DataEntryContextSchema;
	
	// state
	const [editReorderGroupId, setEditReorderGroupId] = useState<number>();
	const [orderConstraints, setOrderConstraints] = useState<T>();
	const [itemConstraints, setItemConstraints] = useState<T>();
	
	const columns = useMemo(() => getColumns(
		intl,
		divisions ?? [],
		setEditReorderGroupId,
		setOrderConstraints,
		setItemConstraints,
	), [intl, divisions]);  // avoid filter reset on rerender
	
	const {fetch, loading} = usePalletizationFetch<T>(columns, showInvalidConstraints);
	
	// refs
	const ref = useRef<MaterialTable<T>>();
	
	const commonProps = getCommonProps<T>(intl, OPTIONS);
	
	useEffect(() => {
		refresh();
	}, [showInvalidConstraints]); // eslint-disable-line react-hooks/exhaustive-deps
	
	
	return (
		<>
			<BlurredPlaceholder loading={divisions === undefined || loading}>
				<TableStyle>
					<MaterialTable
						{...commonProps}
						key={refreshTrigger}
						tableRef={ref}
						data={fetch}
						columns={columns}
						title=""
						isLoading={loading}
					/>
				</TableStyle>
			</BlurredPlaceholder>
			<OrderConstraintsModal
				opened={!!orderConstraints}
				refetch={refresh}
				orderConstraints={orderConstraints!}
				onExit={() => setOrderConstraints(undefined)}/>
			<ItemConstraintsModal
				opened={!!itemConstraints}
				onExit={() => setItemConstraints(undefined)}
				itemConstraints={itemConstraints!}
				refetch={refresh} />
			<AddDeliveryGroupModal
				opened={openCreateGroup}
				onExit={() => setOpenCreateGroup(false)}
				refetch={refresh}/>
			<EditDeliveryGroupModal
				opened={!!editReorderGroupId}
				onExit={() => setEditReorderGroupId(undefined)}
				deliveryGroupId={editReorderGroupId!}
				refetch={refresh}/>
		</>
	)
}