import {ConstraintsMapProps, GroupOptionsSchema} from "@feature/entry";
import React, {useCallback, useContext, useState} from "react";
import {useIntl} from "react-intl";
import {PlusIcon, TrashIcon, XCircleIcon} from "@heroicons/react/24/outline";
import Button from "@ui-components/Button";
import SearchSelect from "@ui-components/SearchSelect";
import Checkbox from "@ui-components/Checkbox";
import {ArticleProps, IS_DC_ENABLED} from "@feature/commons";
import {api} from "@services/apiRequest";
import {NotificationsContext} from "@ui-components/Notifications";

export const ConstraintsMap = ({deliveryGroups, setDeliveryGroups}: ConstraintsMapProps) => {
	
	const [loading, setLoading] = useState<boolean>(false);
	const [addDeliveryGroup, setAddDeliveryGroup] = useState<boolean>(false);
	
	const intl = useIntl();
	const {push} = useContext(NotificationsContext);
	
	const msg = useCallback((msgId: string) => intl.formatMessage({id: msgId}), [intl]);
	
	const fetchItemsFn = useCallback(
		(query: string) => new Promise<GroupOptionsSchema[]>((resolve) => {
			setLoading(true)
			// adding timeout to avoid firing too many requests
			const params = new URLSearchParams({search_keyword: query});
			setTimeout(
				() => api.get<GroupOptionsSchema[]>(`/entry/palletization/group/options?${params.toString()}`)
					.then(r => {
						resolve(r.data);
					})
					.catch(() => {
						push({title: msg('generic_error'), type: "error"});
						resolve([]);
					})
					.finally(() => setLoading(false)), 1000
			)
		}), [msg, push])
	
	return (
		<>
			<PlusIcon
				className={`h-8 ${!deliveryGroups.length && !addDeliveryGroup && 'animate-pulse'} border-white rounded-full bg-am-600 text-white p-0.5 hover:bg-am-700 cursor-pointer`}
				onClick={() => setAddDeliveryGroup(true)}/>
			<div className="grid grid-cols-4 place-items-center text-center w-full gap-5">
				{
					deliveryGroups.map((c, i) => (
						<>
							<h3
								className="text-lg col-span-2 col-start-2 font-semibold">{intl.formatMessage({id: 'supplier_group_list'})}</h3>
							<h3 className="text-lg font-semibold">{intl.formatMessage({id: 'delivery'})}</h3>
							<Button
								styleType="white"
								onClick={() => setDeliveryGroups(
									prevState => prevState.filter(
										p => p.cod_supplier !== c.cod_supplier && p.cod_purchase_address !== c.cod_purchase_address
									)
								)}
								disabled={c.flg_delivery || deliveryGroups.length < 3}>
								<TrashIcon className="shrink w-6 h-6"
								           color={c.flg_delivery || deliveryGroups.length < 3 ? 'grey' : "primary"}/>
							</Button>
							<div className="flex flex-col justify-evenly col-span-2 text-sm">
								<span><b>{intl.formatMessage({id: "Divisione"})}:</b> {c.des_division}</span>
								<span><b>{intl.formatMessage({id: "Fornitore"})}:</b> {c.cod_supplier} - {c.business_name}</span>
								<span><b>{intl.formatMessage({id: "address"})}:</b> {c.cod_purchase_address} - {c.des_purchase_address}</span>
							</div>
							<Checkbox
								readOnly id={i}
								name={c.cod_supplier}
								checked={c.flg_delivery}
								classNames=""
								disabled={deliveryGroups.length < 2}
								onChange={() => setDeliveryGroups(
									prevState => {
										const newState = [...prevState];
										newState[i].flg_delivery = !newState[i].flg_delivery;
										return newState;
									}
								)}/>
						</>
					))
				}
			</div>
			{
				addDeliveryGroup && (
					<div className="flex items-center justify-evenly w-full">
						<div className="w-80">
							<SearchSelect
								value={{}}
								loadOptions={(inputValue: string) => fetchItemsFn(inputValue)}
								loadingMessage={() => intl.formatMessage({id: "loading"})}
								noOptionsMessage={() => intl.formatMessage({id: "type_something"})}
								onChange={(e: GroupOptionsSchema) => {
									setDeliveryGroups([...deliveryGroups, e]);
									setAddDeliveryGroup(false);
								}}
								isMulti={false}
								isLoading={loading}
								cacheOptions
								defaultOptions
								CustomOption={({data, innerProps, innerRef}: ArticleProps<GroupOptionsSchema>) => (
									<article
										ref={innerRef} {...innerProps}
										className="flex flex-col gap-y-1 px-4 py-2 text-sm cursor-pointer hover:bg-am-400">
										{IS_DC_ENABLED && <span><b>{data.cod_dc}</b></span>}
										<span>{data.des_division}</span>
										<span><b>{data.cod_supplier}</b> - {data.business_name}</span>
										<span><b>{data.cod_purchase_address}</b> - {data.des_purchase_address}</span>
									</article>
								)}
								options={undefined}
								label={`${intl.formatMessage({id: "add"})} ${intl.formatMessage({id: "address"})}`}/>
						</div>
						<Button styleType="white" className="w-32 gap-x-2" onClick={() => setAddDeliveryGroup(false)}>
							<XCircleIcon className="h-6 shrink text-am-600"/>
							{intl.formatMessage({id: "cancel"})}
						</Button>
					</div>
				)
			}
		</>
	)
}