import {useContext, useState} from "react";
import type {Query} from "@material-table/core";
import {NotificationsContext} from "@ui-components/Notifications";
import {useAsyncError} from "@hooks/useAsyncError";
import {MTColumnsType,} from "@utils/pagination";
import {AlertEntitySchema, InventoryAlertPath, InventoryAlertsReturnType} from "../types";
import {ExcelDownload, fetchPagination} from "../../../commons/utils";

export const useInventoryAlerts = <T extends AlertEntitySchema>(
  runId: number,
  path: InventoryAlertPath,
  columns: MTColumnsType<T>,
): InventoryAlertsReturnType<T> => {

  // hooks
  const {push} = useContext(NotificationsContext);
  const throwError = useAsyncError();

  // state
  const [loading, setLoading] = useState<boolean>(false);

  // utilities
  const baseUrl = `/inventory/${runId}/${path}`;


  const fetch = async (query: Query<T>) => fetchPagination(
    baseUrl,
    query,
    columns,
    undefined,
    undefined,
    push,
    setLoading,
    throwError
  )

  const download = async (name: string, query?: Query<T>, columnMappings?: {
    [key: string]: string
  }) => ExcelDownload(
    baseUrl,
    columns,
    name,
    query,
    columnMappings,
    setLoading,
    push,
    throwError
  );

  return {fetch, download, loading};
};
