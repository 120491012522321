import Tooltip from "@ui-components/Tooltip";
import {FormattedMessage} from "react-intl";
import {getTitleIntlId} from "./lib/utilities";
import {Filter} from "../filter";
import {StockBarchart} from "../stock-barchart";
import {DimensionBlockProps} from "@feature/home";


export const DimensionBlock = ({
  target,
  hint,
  filterFn,
  filterValues,
  onFilterChange,
  data,
  loading,
  currentDimension,
}: DimensionBlockProps) => {

  let skipSeries: "in_" | "out" | undefined;
  if (target === "recipient")
    skipSeries = "in_";
  else if (["province", "supplier"].includes(target))
    skipSeries = "out";
  else
    skipSeries = undefined;

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between mb-2">
        <p className="text-sm font-semibold">
          <FormattedMessage id={getTitleIntlId(target)} />
        </p>
        {hint && <Tooltip text={hint} placement="top" />}
      </div>
      <Filter
        fetch={filterFn}
        value={filterValues}
        onChange={onFilterChange}
        disabled={!data}
        placeholder={<FormattedMessage id="filter_values" />}
      />
      <div className="w-full" style={{aspectRatio: "16/9", minHeight: "14rem"}}>
        <StockBarchart
          id={`stock-${target}`}
          data={data}
          loading={loading}
          skipSeries={skipSeries}
          currentDimension={currentDimension}
        />
      </div>
    </div>
  )
}