export const simpleNumberFormatter = (number, maxDecimalDigits = 0, nullPlaceholder = "-") => {
  return number !== undefined && number !== null ?
    (Math.round(number * 100) / 100).toLocaleString("it",{
      minimumFractionDigits: 0,
      maximumFractionDigits: maxDecimalDigits,
    }) :
    nullPlaceholder;
}

export const specialRound = (number, threshold=100) => {
  return Math.abs(number) < threshold ?
    Number(number.toFixed(1)) :
    Number(Math.round(number))
}