import React, {useState} from "react";
import {CarouselProps} from "../types";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";

export function Carousel({totalItems, itemsPerPage, onPageChange}: CarouselProps) {
	
	const [currentPage, setCurrentPage] = useState<number>(0);
	
	const pageNumbers = Math.ceil(totalItems / itemsPerPage);
	const carouselLength = pageNumbers > 10 ? 10 : pageNumbers;
	
	const handlePageChange = (newPage: number) => {
		setCurrentPage(newPage);
		onPageChange(newPage);
	}
	
	const getIconStyle = (disabled: boolean) => `w-7 text-gray-400 ${disabled ? 'opacity-50 pointer-events-none' : 'cursor-pointer hover:text-gray-600'}`
	
	return (
		<div className="flex items-center mx-auto gap-x-2">
			<ChevronLeft
				className={getIconStyle(currentPage === 0)}
				onClick={() => handlePageChange(currentPage - 1)}/>
			{
				Array.from(Array(carouselLength).keys()).map((i) => (
					<div key={i}
					     className={
						     `w-7 text-center rounded-md cursor-pointer border border-am-600 ${
							     i === currentPage || (currentPage >= carouselLength - 1 && i === carouselLength - 1) ? 'text-white bg-am-600' : 'text-am-600'}`
					     }
					     onClick={() => handlePageChange(i)}>
						{carouselLength < pageNumbers && i === carouselLength - 1 ? '...' : i + 1}
					</div>
				))
			}
			<ChevronRight
				className={getIconStyle(currentPage === pageNumbers - 1)}
				onClick={() => handlePageChange(currentPage + 1)}/>
		</div>
	)
}