import React from "react";
import Label from "./Label";
import SmallNotification from "./SmallNotification";

const RadioGroup = ({
                      id = "r-groups",
                      title = "",
                      description = "",
                      options,
                      onChange,
                      currentValue,
                      horizontal,
                      labelClasses = "",
                      classNames = "mb-1 my-3 md:my-0",
                      disabled = false,
                    }) => {
  if (!options || !options.length) {
    throw new Error("missing options");
  }

  return (
    <div className={`text-center ${classNames}`}>
      {title && <Label className={`text-base font-medium text-gray-900 ${labelClasses}`}>
        {title}
      </Label>}
      {description && <p className="text-sm leading-5 text-gray-500">{description}</p>}
      <fieldset className="mt-4" id={id}>
        <div
          className={
            horizontal
              ? "mt-1 flex flex-wrap -mx-4"
              : "mt-1 flex flex-col space-y-4"
          }
        >
          {options.map(({name, value, notifications}) => (
            <div
              onClick={() => {
                if (!disabled)
                  onChange(value);
              }}
              key={value}
              className={`relative flex items-center cursor-pointer ${horizontal && "mb-2 ml-4"} ${disabled && "opacity-50 pointer-events-none"}`}
            >
              <input
                name={id}
                id={value}
                type="radio"
                checked={currentValue === value}
                className="focus:ring-am-500 h-4 w-4 cursor-pointer text-am-600 border-gray-300"
                disabled={disabled}
                readOnly={true}
              />
              {notifications > 0 &&
                <SmallNotification counter={notifications} classStyle="-right-10 -mt-5 z-20 absolute" enableAnimation/>}
              <label
                htmlFor={value}
                className="ml-3 block text-sm font-medium cursor-pointer text-gray-700"
              >
                {name}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
};

export default RadioGroup;
