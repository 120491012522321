export const dataFaqIT = [
  {
    question: "La pagina non funziona, cosa devo fare?",
    answer: "A volte il server riceve molte richieste contemporaneamente ed ad alcune fa fatica a rispondere. Prova a ricaricare la pagina e se il messaggio di errore si ripresenta apri un ticket",
  },
  {
    question: "Non vedo le previsioni di oggi, cosa è successo?",
    answer: "Se non vedi le previsioni significa che qualcosa è andato storto nelle rielaborazioni notturne; il team di Ammagamma riceve una notifica quando questo succede e ci preoccupiamo di risolvere il problema il più velocemente possibile.",
  },
];


export const dataFaqEN = [
  {
    question: "I don't remember the password, how can I recover it?",
    answer: "Your passwords are saved to your Google Account. To see a list of accounts with saved passwords, visit the passwords.google.com page or view your passwords in Chrome. To view passwords you need to sign in again.",
  },
  {
    question: "I tried to register on the site but the system tells me that my data already exists. How can I retrieve credentials?",
    answer: "You can recover credentials with sms system.",
  },
  {
    question: "After retrieving my credentials I still can't log in. What can I do?",
    answer: "If you already have another Gmail account, we recommend you to use a private browsing or change your browser as the data of the other account or the old password may remain stored.",
  },
];
