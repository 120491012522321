const orderStatusTranslations = {
  COMPLETED: "Completato",
  CONFIRMED: "Confermato",
  SENT: "Inviato",
  LATE: "In ritardo",
  ERROR: "Errore",
}

const it_orders = {
  delivery_date: "Data di consegna",
  historic_orders: "Ordini storici",
  no_orders_message: "Seleziona i prodotti da ordinare nella pagina Magazzino",
  delete_order_text: "Sei sicuro di voler eliminare l'ordine?",
  send_order_text: "Sei sicuro di voler inviare l'ordine?",
  send_orders: "Invia ordini",
  orders_summary: "Riepilogo ordini",
  status: "Stato",
  cod_order: "Codice ordine",
  dat_order: "Data ordine",
  dat_order_send: "Data invio ordine",
  confirmation_time: "Tempo di conferma",
  delivery_time: "Tempo di consegna",
  ...orderStatusTranslations,
};

export default it_orders;