import {useContext, useEffect, useRef, useState} from "react";
import {api} from "@services/apiRequest";
import {HomeFilterDimensions, HomeOrdering, HomeTarget, StockPayloadSchema, StockSchema} from "../types";
import {useAsyncError} from "@hooks/useAsyncError";
import {NotificationsContext} from "@ui-components/Notifications";
import {useIntl} from "react-intl";
import {HOME_TARGETS} from "@feature/home";

const NON_ORDERABLE_TARGETS: HomeTarget[] = ['province', 'recipient', 'supplier'];


export const useStockData = (
	dateStart: string | undefined,
	dateEnd: string | undefined,
	divisions: Set<string> | undefined,
	filters: Record<HomeTarget, string[]>,
	ordering: HomeOrdering,
	val_dimension: HomeFilterDimensions
) => {
	
	// context
	const {push} = useContext(NotificationsContext);
	
	// hooks
	const intl = useIntl();
	const throwError = useAsyncError();
	
	// state
	const [data, setData]
		= useState<Record<HomeTarget, StockSchema[]>>(
		HOME_TARGETS.reduce(
			(acc, target) => ({...acc, [target]: undefined}),
			{} as Record<HomeTarget, StockSchema[]>
		)
	);
	const [loading, setLoading]
		= useState<HomeTarget[] | null>(null);
	
	// refs
	const orderingRef = useRef(ordering);
	
	useEffect(() => {
		(async () => {
			
			// exit if divisions are not loaded yet
			if (!divisions || !dateStart || !dateEnd)
				return;
			
			const orderingChanged = ordering !== orderingRef.current;
			const targetsToUpdate = [...HOME_TARGETS, "province" as HomeTarget].filter(
				(t) => !data[t] || !orderingChanged || !NON_ORDERABLE_TARGETS.includes(t) || !!filters.recipient?.length
			);
			
			setLoading(targetsToUpdate);
			try {
				// define common payload to be passed to each API call
				const payload = {
					date_start: dateStart,
					date_end: dateEnd,
					divisions: Array.from(divisions),
					filters,
				};
				// send requests (only to targets that are not affected by the filter change)
				const newData =
					await Promise.all(targetsToUpdate
						.map((t) => api.post<StockSchema[]>(
							`/home/stock/${t}`,
							{
								...payload,
								...(t === 'province' ? {} : {limit: 10}),
								...(NON_ORDERABLE_TARGETS.includes(t) ? {} : {ordering}),
								val_dimension
							} as StockPayloadSchema)
						)
					)
						.then((res) => res.reduce(
							(acc, {data}, i) => ({
								...acc,
								[targetsToUpdate[i]]: data
							}),
							{} as Record<HomeTarget, StockSchema[]>,
						));
				// update state
				setData((prev) => ({
					...prev,
					...newData,
				}));
			} catch (e) {
				push({
					type: 'error',
					title: intl.formatMessage({id: 'server_error'}),
					message: `STOCK DATA`,
				});
				throwError(e);
			} finally {
				setLoading(null);
				orderingRef.current = ordering;
			}
		})();
	}, [dateStart, dateEnd, divisions, JSON.stringify(filters), ordering, val_dimension]);  // eslint-disable-line react-hooks/exhaustive-deps
	
	return {data, loading};
}