import {AuthenticationService} from "@services/AuthenticationService";

/**
 * This class is used to handle the queue of requests
 * that are waiting for a token refresh
 * #process is used to resolve or reject all pending requests
 * push is used to add a new request to the queue, it returns a promise
 * that will be resolved or rejected when #process is called
 * clear is used to reject all pending requests
 * resolve is used to resolve all pending requests with a value (the new token)
 */
export class RequestQueueHandler {
  constructor() {
    this.pendingQueue = [];
  }

  #process(valid = true, value = null) {
    this.pendingQueue.forEach((prom) => {
      if (!valid) {
        prom.reject();
      } else {
        prom.resolve(value);
      }
    });
    this.pendingQueue = [];
  }

  async push() {
    return new Promise((resolve, reject) => {
      this.pendingQueue.push({resolve, reject});
    })
      .then((token) => {
        return token;
      })
      // eslint-disable-next-line no-unused-vars
      .catch((err) => {
        // do Not return a rejected promise
        // let the caller handle the null value instead
        // of catching an exception in case of await
        return null;
      });
  }

  clear() {
    this.#process(false);
  }

  resolve(value) {
    this.#process(true, value);
  }
}

export class RequestTokenHandler {
  constructor() {
    this.isRefreshing = false;
    // this is used to pause and execute all pending requests
    // after a token refresh
    this.requestQueue = new RequestQueueHandler();
  }

  /**
   *
   * @param {*} apiAuth
   * @returns Promise<string> access_token or Promise<null>
   */
  async getToken(apiAuth) {
    let access_token = AuthenticationService.getUserData()?.access_token;
    if (!access_token) {
      return null;
    }

    if (this.isRefreshing) {
      // if a token refresh is already in progress
      // add this request to the queue
      return this.requestQueue.push();
    } else if (AuthenticationService.isTokenExpired()) {
      this.isRefreshing = true;
      access_token = await apiAuth.refreshToken();
      this.isRefreshing = false;
    }
    if (!access_token) {
      this.requestQueue.clear();
    } else {
      this.requestQueue.resolve(access_token);
    }
    return access_token;
  }

  clear() {
    this.requestQueue.clear();
  }
}
