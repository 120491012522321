import React from "react";
import {IntlShape} from "react-intl";
import {Options} from "@material-table/core";
import Tooltip from "@ui-components/Tooltip";
import MaterialTableCustomCell from "@ui-components/MaterialTableCustomCell";
import Badge from "@ui-components/Badge";
import {IS_DC_ENABLED} from "@feature/commons";
import {DtnItemResponseSchema, MTColumnsEditType} from "@feature/entry/types";
import {DivisionSchema} from "@feature/commons/types";
import {ERROR_COLOR} from "@theme/index";

type T = DtnItemResponseSchema;

export const getColumns = (intl: IntlShape, divisions: DivisionSchema[]): MTColumnsEditType<T> => {
  const baseColumns: MTColumnsEditType<T> = [
    {
      title: intl.formatMessage({id: "warehouse"}),
      field: "cod_dc",
      hidden: !IS_DC_ENABLED,
      editable: 'never',
      render: rowData => (
        <MaterialTableCustomCell vertical data1={rowData.cod_dc} data2={rowData.des_dc}/>
      ),
      pagination: {
        spech: {
          column_name: ["cod_dc", "warehouse.des_dc"],
          filter_operator: "ilike any",
          value_parser(value) {
            return `%${value}%`
          },
        }
      },
    },
    {
      title: intl.formatMessage({id: "Materiale"}),
      field: "cod_item",
      editable: 'never',
      pagination: {
        spech: {
          column_name: ["cod_item"],
          filter_operator: "ilike",
          value_parser(value) {
            return `%${value}%`
          },
        }
      },
    },
    {
      title: intl.formatMessage({id: "description"}),
      field: "des_item",
      editable: 'never',
      pagination: {
        spech: {
          column_name: ["des_item"],
          filter_operator: "ilike",
          value_parser(value) {
            return `%${value}%`
          },
        }
      },
    },
    {
      title: intl.formatMessage({id: "Gruppo_merce"}),
      field: "des_product",
      editable: 'never',
      pagination: {
        spech: {
          column_name: ["des_product"],
          filter_operator: "ilike",
          value_parser(value) {
            return `%${value}%`
          },
        }
      },
    },
    {
      title: intl.formatMessage({id: "Famiglia"}),
      field: "des_area",
      editable: 'never',
      pagination: {
        spech: {
          column_name: ["item.des_area"],
          filter_operator: "ilike",
          value_parser(value) {
            return `%${value}%`
          },
        }
      },
    },
    {
      title: intl.formatMessage({id: "Divisione"}),
      field: "des_division",
      lookup: divisions.reduce((acc, {des_division}) => ({...acc, [des_division]: des_division}), {}),
      editable: 'never',
      pagination: {
        spech: {
          column_name: ["item.cod_division"],
          filter_operator: "in",
          value_parser(value: string[]) {
            return divisions.filter(({des_division}) => value.includes(des_division)).map(({cod_division}) => cod_division)
          }
        }
      },
    },
    {
      title: intl.formatMessage({id: "Fornitore"}),
      field: "supplier_list",
      editable: 'never',
      sorting: false,
      pagination: {
        spech: {
          column_name: ["item.item_supplier.cod_supplier", "item.item_supplier.supplier.business_name"],
          filter_operator: "ilike",
          value_parser(value) {
            return `%${value}%`
          },
        }
      },
      render: rowData => {
        const uniqueSuppliers = Array.from(new Set(rowData.supplier_list));
        return (
          <div className="flex flex-col gap-x-1 min-w-[120px]">
            {uniqueSuppliers.map(s => <Badge key={s} text={s} textSizeClass={"text-xs"}/>)}
          </div>
        )
      }
    }
  ]

  const editableCols: MTColumnsEditType<T> = [
    {
      title:
        <div className="flex-col flex items-center">
          <h3>Safety {intl.formatMessage({id: 'days'})}</h3>
          <Tooltip
            placement="bottom"
            text={intl.formatMessage({id: 'safety_days_tooltip'})}
          />
        </div>,
      editTitle: `Safety ${intl.formatMessage({id: 'days'})}`,
      editPlaceholder: `Safety ${intl.formatMessage({id: 'days'})}`,  // necessary due to title type (react component)
      field: 'num_day_safety',
      bulkEditable: true,
      render: rowData => (
        typeof rowData.num_day_safety === 'number'
          ? <MaterialTableCustomCell
            data1={rowData.num_day_safety.toString()}
            data2={intl.formatMessage({id: 'days'})}/>
          : <></>
      ),
    },
    /* not used in CAMST
    {
      title: 'Extra Safety',
      field: 'val_additional_safety',
      bulkEditable: true,
      render: rowData => (
        typeof rowData.val_additional_safety === 'number'
          ? <MaterialTableCustomCell
            data1={rowData.val_additional_safety.toString()}
            data2={rowData.um_basic}/>
          : <></>
      ),
    },
    */
    {
      title:
        <div className="flex-col flex items-center">
          <h3>Extra Safety (Kg)</h3>
          <Tooltip
            placement="bottom"
            text={intl.formatMessage({id: 'extra_safety_kg_tooltip'})}
          />
        </div>,
      field: 'num_additional_safety_kg',
      bulkEditable: true,
      editTitle: 'Extra Safety (Kg)',
      editPlaceholder:  'Extra Safety (Kg)', // necessary due to title type (react component)
      render: rowData => (
        typeof rowData.num_additional_safety_kg === 'number'
          ? <MaterialTableCustomCell
            data1={rowData.num_additional_safety_kg.toString()}
            data2='KG'/>
          : <></>
      ),
    }
  ]

  const editCols: MTColumnsEditType<T> = editableCols.map((c) => ({
    ...c,
    filtering: false,
    type: "numeric",
    align: "center",
  }))

  return baseColumns.concat(editCols)
};

export const OPTIONS: Options<T> = {
  rowStyle: (rowData: T) => ({
    backgroundColor: typeof rowData.num_day_safety !== 'number' ? ERROR_COLOR : ''
  })
};

export const getDtnItemExportColumnMappings = (intl: IntlShape) => {
  const msg = (msgId: string) => intl.formatMessage({id: msgId});

  const unpackColAttr = (field: string, translationKey: string) => {

    const translation = msg(translationKey);

    const cod_field = 'cod_' + field;
    const des_field = 'des_' + field;

    return {[`${msg("code")} ${translation}`]: cod_field, [msg(translationKey)]: des_field}
  }

  return {
    ...(IS_DC_ENABLED ? unpackColAttr('dc', 'warehouse') : {}),
    ...unpackColAttr('item', 'Materiale'),
    [msg("Divisione")]: "des_division",
    [msg("Gruppo_merce")]: "des_product",
    [msg("Famiglia")]: "des_area",
    [msg("Fornitore")]: "supplier_list",
    [`${msg("days")} Safety`]: "num_day_safety",
    "Extra Safety": "val_additional_safety",
    "Extra Safety (Kg)": "num_additional_safety_kg",
  }
}
