import {useContext, useEffect, useState} from "react";
import {DivisionSchema} from "../types";
import {api} from "@services/apiRequest";
import {useIntl} from "react-intl";
import {useAsyncError} from "@hooks/useAsyncError";
import {NotificationsContext} from "@ui-components/Notifications";

export const useDivisions = () => {

  // context
  const {push} = useContext(NotificationsContext);

  // hooks
  const intl = useIntl();
  const throwError = useAsyncError();

  // state
  const [data, setData] = useState<DivisionSchema[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const {data} = await api.get<DivisionSchema[]>('/common/divisions');
        setData(data);
      } catch (e) {
        push({type: 'error', title: intl.formatMessage({id: 'server_error'}), message: 'DIVISIONS'});
        throwError(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [intl, push, throwError]);

  return {data, loading};
}