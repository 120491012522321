import {useContext, useState} from "react";
import {NotificationsContext} from "@ui-components/Notifications";
import {useAsyncError} from "@hooks/useAsyncError";
import {Query} from "@material-table/core";
import {MTColumnsType} from "@utils/pagination";
import {fetchPagination} from "@feature/commons/utils";

export function usePalletizationFetch<T extends object>(
	columns: MTColumnsType<T>,
	showInvalidConstraints: boolean
) {
	// hooks
	const {push} = useContext(NotificationsContext);
	const throwError = useAsyncError();
	
	// state
	const [loading, setLoading] = useState<boolean>(false);
	
	const baseUrl = '/entry/palletization';
	
	
	const fetch = async (query: Query<T>) => fetchPagination(
		baseUrl,
		query,
		columns,
		undefined,
		{invalid_constraints: showInvalidConstraints},
		push,
		setLoading,
		throwError
	)
	
	return {fetch, loading};
}