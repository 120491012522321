import React, {useState} from "react";
import {useIntl} from "react-intl";

import {ItemAssociationTable} from "../products-table";
import Toggle from "@ui-components/Toggle";

export function ItemAssociation() {

  const intl = useIntl();

  const [flgItemToAssociate, setFlgItemToAssociate] = useState<boolean>(false);

  return (
    <>
      <div className="flex items-center justify-between">
        <Toggle
          classNames="my-5 w-1/6 mx-10"
          label={intl.formatMessage({id: "to_be_compiled"})}
          checked={flgItemToAssociate}
          onChange={() => setFlgItemToAssociate(prevState => !prevState)}/>
      </div>
      <div className={"my-7"}>
        <ItemAssociationTable flgItemToAssociate={flgItemToAssociate}/>
      </div>
    </>
  )
}