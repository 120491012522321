import axios from "axios";
import {API_URL} from "../../utils/constants";

/**
 * Deprecated
 * this axios instance used to return only data from the response
 * ignoring HTTP status codes and other response data
 */
export const apiRequest = axios.create({
  baseURL: API_URL,
});

export const refreshApiRequest = axios.create({
  baseURL: API_URL,
});


/**
 * this axios instance returns the whole response
 * including HTTP status codes and other response data
 */
export const api = axios.create({
  baseURL: API_URL,
});