import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { useIntl, FormattedMessage } from "react-intl";

// Services
import AuthenticationService from "../../../services/AuthenticationService";
import {NotificationsContext} from "../../Notifications";
import {api} from "@services/apiRequest";
import ErrorBoundary from "../../ErrorBoundary";

// Components
import RoleTable from "./RoleTable";
import AddRoleDialog from "./AddRoleDialog";

// UI
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { PageHeading } from "../../Container";
import Button from "../../Button";

const Roles = () => {
  const intl = useIntl();
  const [roles, setRoles] = useState();
  const [showNewRoleDialog, setShowNewRoleDialog] = useState(false);
  const scopes = AuthenticationService.getUserScopes();
  const { push } = useContext(NotificationsContext);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const {data: roles} = await api.get("/roles");

        setRoles(
          (roles || []).reduce(
            (rolesObj, role) => _.set(rolesObj, [role.id], role),
            {}
          )
        );
      } catch (error) {
        setRoles({})
        push({
          title: intl.formatMessage({ id: "server_error" }),
          type: "error",
        });
      }
    };

    if (!roles) {
      fetchRoles();
    }
    // eslint-disable-next-line
  }, [roles]);

  const toggleNewRoleDialog = () => setShowNewRoleDialog(!showNewRoleDialog);
  const canCreate = scopes.indexOf("role:create") >= 0;

  return (
    <>
      <PageHeading
        title={intl.formatMessage({ id: "roles" })}
        contentActions={
          canCreate ? (
            <Button
              variant="contained"
              color="primary"
              onClick={toggleNewRoleDialog}
              data-cy="add-role"
            >
              <PlusCircleIcon
                className="-ml-0.5 mr-2 h-4 w-4"
                aria-hidden="true"
              />
              <FormattedMessage id="add" />
            </Button>
          ) : null
        }
      />
      <ErrorBoundary from="roles-table">
        <RoleTable
          rows={roles}
          setRow={setRoles}
          push={push}
          apiKey="roles"
          roleKey="role"
        />
      </ErrorBoundary>
      {canCreate ? (
        <ErrorBoundary from="roles-add-dialog">
          <AddRoleDialog
            open={showNewRoleDialog}
            toggleOpen={toggleNewRoleDialog}
            roles={roles}
            setRoles={setRoles}
          />
        </ErrorBoundary>
      ) : null}
    </>
  );
};

export default Roles;
