import {useContext, useEffect, useState} from "react";
import {api} from "@services/apiRequest";
import {useIntl} from "react-intl";
import {useAsyncError} from "@hooks/useAsyncError";
import {NotificationsContext} from "@ui-components/Notifications";

export const useLastUpdate = () => {
	
	// context
	const {push} = useContext(NotificationsContext);
	
	// hooks
	const intl = useIntl();
	const throwError = useAsyncError();
	
	// state
	const [data, setData] = useState<string>();
	const [loading, setLoading] = useState(false);
	
	useEffect(() => {
		(async () => {
			setLoading(true);
			try {
				const {data} = await api.get<string>('/runs/last_update');
				setData(data);
			} catch (e) {
				push({type: 'error', title: intl.formatMessage({id: 'generic_error'}), message: 'LAST UPDATE'});
				throwError(e);
			} finally {
				setLoading(false);
			}
		})();
	}, [intl, push, throwError]);
	
	return {data, loading};
}