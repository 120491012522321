import it_aware_generic from "./aware_generic/it_aware_generic";
import it_demand from "./demand/it_demand";
import it_faq from "./faq/it_faq";
import it_form from "./form/it";
import it_data_entry from "./data-entry/it_data_entry";
import it_orders from "./orders/it_orders";
import {it_camst} from "./camst/camst-it";

const it = {
  entity_user: "Gestione delle utenze in sistema",
  entity_role: "Gestione dei ruoli di autorizzazione in sistema",
  entity_price: "Visibilità ed operazioni su informazioni di prezzo",
  name_and_surname: "Nome e cognome",
  entity_audit: "Gestione dell'audit",
  language_it: "Italiano",
  language_en: "English",
  language: "Lingua",
  Home: "Home",
  disabled_user: "Utente disabilitato",
  enter_name_and_surname: "Inserisci nome e cognome",
  enter_name_or_email: "Inserisci email e nome",
  need_to_select_a_role: "Devi selezionare un ruolo",
  delete_user: "Eliminare l'utenza?",
  disabled: "Disabilitato",
  user_updated_successfully: "Utenza aggiornata con successo",
  user_deleted_successfully: "Utenza eleminata con successo",
  user_created_successfully: "Utente creato con successo",
  description: "Descrizione",
  role_name: "Nome ruolo",
  add_role_name: "Inserisci il nome del ruolo",
  add_role: "Aggiungi ruolo",
  add_user: "Aggiungi utente",
  deleted_role: "Ruolo eliminato",
  updated: "Aggiornati",
  delete_element: "Eliminare l'elemento?",
  role_already_used: "Ruolo già in uso!",
  role_created_successfully: "Ruolo creato con successo",
  add: "Aggiungi",
  add_success: "Elemento aggiunto correttamente",
  edit_success: "Elemento modificato correttamente",
  server_error: "Errore del server",
  model_errors: "Errori",
  confirm_new_password: "Conferma nuova password",
  passwords_not_matching: "Le due password non coincidono",
  password_without_spaces: "La password non deve contenere spazi",
  password_length: "La password deve essere lunga almeno 8 caratteri",
  signup: "Registrati",
  error_while_saving: "Errore durante il salvataggio",
  authentication_failed: "Autenticazione fallita",
  send: "Invia",
  user_not_found: "Utente non trovato",
  wrong_old_password: "Vecchia password errata",
  enter_actual_password: "Inserisci password attuale",
  actual_password: "Password attuale",
  new_password: "Nuova password",
  email: "Email",
  name: "Nome",
  role: "Ruolo",
  user_type: "Tipo utente",
  my_profile: "Il mio profilo",
  access_denied: "Accesso negato",
  password_updated_successfully: "Password modificata con successo",
  submit_password: "Invia password",
  enter_your_email_address: "Inserisci l'indirizzo email associato alla tua utenza",
  restore_password: "Recupera password",
  forgot_password: "Password dimenticata?",
  edit_password: "Modifica password",
  show_password: "Mostra password",
  save: "Salva",
  exit: "Esci",
  error: "Errore",
  success: "Operazione Completata con Successo",
  warning: "Attenzione",
  edit: "Modifica",
  generic_error: "Si è verificato un errore; contatta l'assistenza",
  form_error: "Controlla i campi del form",
  enter_email: "Inserisci email",
  missing_sender_mail: "Mail del mittente mancante",
  log_into_your_account: "Entra nel tuo account",
  wrong_email_or_password: "Email o password sbagliati",
  unregistered_email: "Email non registrata",
  forgot_password_msg: "Mail inviata! Se non ricevi alcuna notizia da noi nei prossimi 15 minuti, controlla di aver inserito l'indirizzo e-mail corretto e controlla nella cartella spam.",
  roles: "Ruoli",
  roles_displayed_rows: "{from}-{to} di {count} ruoli",
  users: "Utenti",
  profile: "Profilo",
  users_displayed_rows: "{from}-{to} di {count} utenti",
  users_displayed_record: "{from}-{to} di {count} record",
  empty_table: "Nessun risultato disponibile",
  no_data: "Nessun dato disponibile",
  components: "Componenti",
  maps: "Mappe",
  tables: "Tabelle",
  graphs: "Grafici",
  inventory: "Inventory",
  demand_forecasting: "Demand Forecasting",
  data_entry: "Data Entry",
  users_settings: "Gestione utenze",
  welcome: "Benvenuto",
  test: "test_it",
  period: "Periodo",
  daily: "Giornaliero",
  weekly: "Settimanale",
  Cumulato: "Cumulato",
  Divisione: "Divisione",
  Famiglia: "Famiglia",
  Fornitore: "Fornitore",
  warehouse: "Magazzino",
  recipients_home_tooltip: "Il filtro destinatari si applica solo ai prodotti in uscita",
  Gruppo_merce: "Gruppo merce",
  data_label: "Dati al:",
  set_hour: "Orario:",
  waste_food: "Articoli da Forzare",
  over_stock: "Over Stock",
  under_stock: "Under Stock",
  inventoryReorderProposal: "Proposta di Riordino",
  tooltip_inventory: "L'ottimizzatore esegue un run completo di notte (ore 05.15) e due run parziali alle 9.25 e alle 15.55. Di default è selezionato l'ultimo run disponibile",
  tooltip_inventory2: "Lo Svuotamento è la quantità teorica di merce in magazzino nel caso non si facesse alcun riordino",
  tooltip_inventory3: "La Safety è la quantità di scorta della merce. Si basa sulla copertura della domanda, pertanto è variabile nel tempo",
  tooltip_inventory4: "La Giacenza indica la quantità in magazzino nell'ipotesi che si segua la proposta di riordino dell'ottimizzatore",
  choose_period: "Seleziona il periodo",
  Destinatario: "Destinatario",
  Materiale: "Materiale",
  date_from: "Data di inizio",
  date_to: "Data di fine",
  insert_date: "Inserisci data",
  select_date: "Seleziona data",
  filter_values: "Filtra valori",
  apply: "Applica",
  tooltip_home_map: "La cartina mostra la suddivisione per provincia della merce in uscita",
  filter_by: "Filtra per {target}",
  address: "Indirizzo",
  firsts: "primi",
  results: "risultati",
  code: "Codice",
  ordinamento: "Ordinamento",
  Entrate: "Entrate",
  Uscite: "Uscite",
  reference_date: "Data di riferimento",
  expiration_date: "Data di scadenza",
  dat_last_out: "Data ultima uscita",
  no_options: "Nessuna opzione disponibile",
  loading: "Caricamento ...",
  inventoryLoading: "Aggiornamento dati inventario ...",
  inventoryLoadingStart: "Caricamento dati magazzino ...",
  inventoryEmptyTooltip: "Svuotamento è la quantità teorica di merce in magazzino nel caso non si facesse alcun riordino",
  inventorySafetyTooltip: "Safety è la quantità di scorta della merce. Si basa sulla copertura della domanda, pertanto è variabile nel tempo",
  inventoryStockTooltip: "Giacenza indica la quantità in magazzino nell'ipotesi che si segua la proposta di riordino dell'ottimizzatore",
  stock: "Giacenza",
  empty: "Svuotamento",
  safety: "Safety",
  parametri: "Parametri",
  price: "Prezzo",
  orders: "Ordini",
  order: "Ordine",
  weight: "Peso",
  quantity: "Quantità",
  other_measures: "Altre misure",
  suggestion: "Suggerimento",
  on_sale: "Sul Venduto",
  order_date: "Data ordine",
  expected_delivery: "Consegna prevista",
  supply_group: "Crea un nuovo raggruppamento",
  inventoryMissingSuggestions: "Suggerimenti di riordino non disponibili",
  inventoryRunsTooltip: "L'ottimizzatore esegue un run completo di notte (ore 05:15) e due run parziali alle 09:25 e alle 15:55. Di default è selezionato l'ultimo run disponibile",
  inventoryChartNoData: "Nessun dato disponibile",
  alertWasteFoodTitle: "Materiali scaduti in magazzino",
  alertOverStockTitle: "Gruppi merce in esubero nei prossimi 10 giorni",
  alertUnderStockTitle: "Top 100 gruppi merce da riordinare oggi",
  cod_item_error: "Codice Materiale In Errore",
  des_item_error: "Materiale In Errore",
  cod_product_error: "Codice Prodotto In Errore",
  des_product_error: "Prodotto In Errore",
  choose_multiple: "Scegliere un valore multiplo di ",
  audit: "Audit",
  frontend: "Frontend",
  backend: "Backend",
  file_picker_placeholder: "Seleziona uno o più file",
  success_ticket_sent: "Il ticket è stato aggiunto correttamente, prenderemo in carico la richiesta il prima possibile.",
  hubspot_portal: "Hubspot Customer Portal",
  size_limit_exceeded: "Dimensione massima superata",
  customer_portal_loading_failed: "Errore durante il caricamento del link al portale cliente",
  week_datepicker: "Settimana ",
  week_datepicker_not_selected: "Datepicker settimanale",
  daily_datepicker_not_selected: "Datepicker giornaliero",
  sap_orders: "Ordini SAP",
  thanks: "Grazie!",
  select_an_option: "Seleziona un'opzione dal menu a tendina.",
  ticket_parameters_not_found: "Parametri di invio ticket non presenti",
  troisi_parameters_not_found: "Parametri di invio mail non presenti",
  error_new_ticket_creation: "Errore imprevisto nella creazione di un nuovo ticket",
  error_ticket_hubspot_management: "É stato creato un nuovo ticket, ma qualcosa è andato storto durante le operazioni effettuate dal nostro portale",
  error_ticket_file_attachment: "É stato creato un nuovo ticket, ma non è possibile allegare il file specificato",
  session_expired: "Sessione scaduta",
  refreshing_session_wait: "Rinnovo della sessione in corso, attendere...",
  item_association: "Associazione articoli",
  to_be_compiled: "Record da Compilare",
  to_be_compiled_only: "Considera soltanto i record da compilare",
  type_something: "Digita un valore",
  add_row_choose: "Seleziona prodotto da aggiungere a lista riordino",
  add_row_dat_order: "Seleziona data Riordino",
  add_row_dat_delivery: "Seleziona data di Prevista Consegna",
  add_row_warehouse: "Seleziona il magazzino",
  add_row_item: "Cerca il prodotto che vuoi inserire",
  add_row_alert: "Esiste già la combinazione prodotto - data ordine tra i suggerimenti di Aware. Prego modificare l'ordine corrispondente invece che aggiungerne uno nuovo",
  add_row_warning: "Si sta cercando di inserire un ordine al di fuori dell'orizzonte temporale di Aware, quindi è necessario inserire manualmente la data di prevista consegna",
  add_row_info: "il fornitore del prodotto selezionato solitamente consegna in {leadtime} giorni lavorativi",
  add_row_val_quantity_multiples: "Quantità desiderata",
  ...it_aware_generic,
  ...it_demand,
  ...it_faq,
  ...it_form,
  ...it_data_entry,
  ...it_orders,
  ...it_camst,
};

export default it;
