import {Action, Localization, Options, Query} from "@material-table/core";
import {AxiosRequestConfig} from "axios";
import {api} from "@services/apiRequest";
import {dateToString} from "@utils/index";
import {buildFilterFromQuery, MTColumnsType, orderingFromQuery} from "@utils/pagination";
import {PaginationResponseSchema} from "./types";
import {Dispatch, SetStateAction} from "react";
import {IntlShape} from "react-intl";
import {tableIcons} from "@ui-components/TableIcons";


export const buildPaginationPayload = <T extends object>(query: Query<T> | undefined, columns: MTColumnsType<T>, optionsOnly = false, additionalOptions: {
  [key: string]: any
} = {}) => {

  const filters = (query && buildFilterFromQuery(query, columns)) || null;
  const options = {
    filters,
    ordering: query && orderingFromQuery(query) || null,
    ...additionalOptions
  };
  if (optionsOnly)
    return options;
  return {
    ...options,
    offset: query!.page * query!.pageSize,
    limit: query!.pageSize
  };
}

export const fetchPagination = async <T extends object>(
  baseUrl: string,
  query: Query<T>,
  columns: MTColumnsType<T>,
  paginationOptionsOnly: boolean = false,
  additionalPaginationOptions: { [key: string]: any } = {},
  push?: (notification: { title: string, type: "success" | "error" | "warning" | "info" }) => void,
  setLoading?: Dispatch<SetStateAction<boolean>>,
  customThrowError?: (e: any) => void,
) => {

  setLoading && setLoading(true);
  try {
    const payload = buildPaginationPayload(query, columns, paginationOptionsOnly, additionalPaginationOptions);
    const {data} = await api.post<PaginationResponseSchema<T>>(baseUrl, payload);
    const {items, total} = data;
    return {data: items, page: query.page, totalCount: total};
  } catch (e) {
    push && push({title: "Si è verificato un errore", type: "error"});
    if (customThrowError)
      customThrowError(e);
    else
      throw e;
  } finally {
    setLoading && setLoading(false);
  }
  return {data: [], page: query.page, totalCount: 0};
}

const downloadFile = (b: Blob, filename: string) => {
  const url = URL.createObjectURL(b);
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  link.click();
  link.remove();
}

export const downloadExcelBE = async <T extends object>(
  baseUrl: string,
  name: string,
  columns: MTColumnsType<T>,
  query?: Query<T>,
  columnMappings?: { [key: string]: string },
  additionalPayload: { [key: string]: any } = {},
) => {
  const options: AxiosRequestConfig = {responseType: "blob"};
  const paginationPayload = buildPaginationPayload(query, columns, true);
  const payload = {...paginationPayload, ...additionalPayload, mappings: columnMappings}
  const {data} = await api.post<Blob>(`${baseUrl}/export`, payload, options);
  downloadFile(data, `${name}_${dateToString(new Date())}.xlsx`);
}


export const ExcelDownload = async <T extends object>(
  baseUrl: string,
  columns: MTColumnsType<T>,
  name: string,
  query?: Query<T>,
  columnMappings?: { [key: string]: string },
  setLoading?: Dispatch<SetStateAction<boolean>>,
  push?: (notification: { title: string, type: "success" | "error" | "warning" | "info" }) => void,
  customThrowError?: (e: any) => void,
  additionalPayload: { [key: string]: any } = {},
) => {
  setLoading && setLoading(true);
  try {
    await downloadExcelBE(baseUrl, name, columns, query, columnMappings, additionalPayload);
  } catch (e) {
    push && push({title: "Si è verificato un errore", type: "error"});
    if (customThrowError)
      customThrowError(e);
    else
      throw e;
  } finally {
    setLoading && setLoading(false);
  }
}

export const getLocalization = (intl: IntlShape): Localization => ({
  header: {
    actions: "",
  },
  pagination: {
    labelDisplayedRows: intl.formatMessage({id: "users_displayed_record"}),
    labelRowsSelect: "record",
  },
  body: {
    emptyDataSourceMessage: intl.formatMessage({id: "empty_table"}),
  }
});
export const getCommonProps = <T extends object>(intl: IntlShape, customOptions: Options<T> | undefined = {}) => {

  const defaultOptions: Options<T> = {
    filtering: true,
    toolbar: true,
    pageSize: 10,
    pageSizeOptions: [10, 25, 50],
    showTitle: true,
    filterCellStyle: {maxWidth: 100},
    search: false,
  }

  return {
    icons: tableIcons,
    localization: getLocalization(intl),
    options: {...defaultOptions, ...customOptions},
    downloadAction: {
	    icon: tableIcons.Download,
      tooltip: "Export to Excel",
      onClick: () => {
        throw Error("Not implemented");
      },  // to be implemented inside each table
      isFreeAction: true,
    } as Action<object>,
  }
};