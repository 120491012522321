import {BasicCheckBoxProps} from "@feature/entry";
import Checkbox from "@ui-components/Checkbox";
import React from "react";

export const BasicCheckBox = ({value, label, onChange, classNames = ""}: BasicCheckBoxProps) => (
	<div className={`flex items-center justify-between ${classNames}`}>
		<Checkbox
			id={label}
			name=""
			readOnly
			checked={value}
			onChange={() => onChange(!value)}
			classNames="mr-2"
		/>
		{label}
	</div>
)
	