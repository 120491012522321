import React, {ReactElement} from "react";
import Placeholder from "@ui-components/Placeholder";
import ModifyOrder from "./modify-order";
import {CustomSelection, ModifyOrderPropsType} from "../types";
import {useInventoryGetCustomSelection} from "../hooks";
import {useIntl} from "react-intl";
import Alert from "@ui-components/Alert";

const CheckModifyOrder = ({
	                          data,
	                          setData,
                          }: ModifyOrderPropsType) => {
	
	const intl = useIntl();
	
	const {customSelection, loading} = useInventoryGetCustomSelection(
		{
			cod_item: data.cod_item!,
			dat_order: data.new_dat_order!,
			cod_dc: data.cod_dc!
		},
		setData
	);
	
	if (!data.new_dat_order)
		return null;
	
	let selectionComponent: ReactElement;
	
	switch (customSelection) {
		case CustomSelection.missing_delivery_date:
			selectionComponent = <ModifyOrder data={data} setData={setData}/>;
			break;
		case CustomSelection.already_suggested:
			selectionComponent = <Alert
				type="error"
				title={intl.formatMessage({id: 'already_suggested'})}
				text={undefined}
				onConfirm={undefined}
				onDismiss={undefined}
				slim={false}/>;
			break;
		default:
			selectionComponent = <></>;
		
	}
	
	return (
		!loading
			? selectionComponent
			: <Placeholder classNames="h-20 w-full"/>
	);
	
};

export default CheckModifyOrder;