import React, {useContext, useLayoutEffect} from "react";
import {useIntl} from "react-intl";
import Tooltip from "@ui-components/Tooltip";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import {DemandChartProps, DemandContextSchema, DemandForecastingResponseSchema} from "@feature/demand";
import {DataContext} from "@services/DataContext";
import {AWARE_CHART_PALETTE} from "@theme/index";
import {DEFAULT_UM} from "@feature/commons";
import {addCursor, addLicense, getRenderAxisX, getRenderAxisY, getXYChart} from "@components/Charts/commons";
import {createDateLine, getDateOfPreviousYear, makeDemandSeries, toggleDataItem} from "./lib/utils";
import {dateToString} from "@utils/index";
import am5locales_it_IT from "@amcharts/amcharts5/locales/it_IT";
import am5locales_en_US from "@amcharts/amcharts5/locales/en_US";

type T = DemandForecastingResponseSchema;

export function DemandChart({data}: DemandChartProps) {

  const {demandCurrentRun} = useContext(DataContext) as DemandContextSchema;

  const intl = useIntl();

  const id = "demand-chart";

  const dummySerieName = `Forecast ${new Date(demandCurrentRun?.dat_execution!).getFullYear() - 1}`

  // value chartDimensions with the unique combinations of cod_attribute and des_attribute
  const chartDimensions = Array.from(
    new Set(data.map(el => JSON.stringify({cod_attribute: el.cod_attribute, des_attribute: el.des_attribute})))
  ).map(el => JSON.parse(el))

  const currentData = data?.map(el => (
    dateToString(new Date(el.dat_forecast)) === demandCurrentRun?.dat_execution
      ? {...el, strokeSettings: {strokeDasharray: [3, 3]}}
      : el
  ))

  useLayoutEffect(() => {

    if (!data.length)
      return;


    addLicense();
    const root = am5.Root.new(id);
    root.setThemes([am5themes_Animated.new(root)])
	  
	  // Set locale
	  root.locale = intl.locale === 'it' ? am5locales_it_IT : am5locales_en_US;


    // Create chart
    const chart = getXYChart(root, {layout: root.verticalLayout})
    addCursor(root, chart)


    const responsiveXAxis = window.innerWidth < 640 ? 12 : 7;

    // Create axes
    const xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
      baseInterval: {timeUnit: "day", count: 1},
      gridIntervals: [{timeUnit: "day", count: responsiveXAxis},],
      renderer: getRenderAxisX(root, false),
      tooltip: am5.Tooltip.new(root, {})
    }));

    createDateLine(xAxis, demandCurrentRun?.dat_execution!);

    const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      renderer: getRenderAxisY(root)
    }));

    const yLabel = am5.Label.new(root, {
      rotation: -90,
      text: `Quantità (${DEFAULT_UM})`,
      y: am5.p50,
      centerX: am5.p50
    })

    yAxis.children.unshift(yLabel);

    // add legend
    const legend = chart.children.push(am5.Legend.new(root, {
      nameField: "name",
      fillField: "color",
      strokeField: "color",
      centerX: am5.percent(50),
      x: am5.percent(50),
      centerY: am5.percent(100),
      Y: am5.percent(100),
      useDefaultMarker: true,
    }));


    const demandSeries = chartDimensions.flatMap(({cod_attribute, des_attribute}, index) => {

        const [currentYearColor, previousYearColor] = AWARE_CHART_PALETTE[index]

        const currentYearSeries = makeDemandSeries<T>(
          root,
          legend,
          xAxis,
          yAxis,
          chart,
          'val_quantity',
          currentData.filter(el => el.cod_attribute === cod_attribute),
          des_attribute,
          currentYearColor
        )

        const previousYearSeries = makeDemandSeries<T>(
          root,
          legend,
          xAxis,
          yAxis,
          chart,
          'val_quantity_py',
          data.filter(el => el.cod_attribute === cod_attribute).map(el => ({
            ...el,
            dat_forecast_py: getDateOfPreviousYear(el.dat_forecast)
          })),
          `${des_attribute} ${new Date(demandCurrentRun?.dat_execution!).getFullYear() - 1}`,
          previousYearColor
        )

        return [currentYearSeries, previousYearSeries]
      }
    )

    // const dummySerie = makeDemandSeries<T>(
    //   root,
    //   legend,
    //   xAxis,
    //   yAxis,
    //   chart,
    //   'val_quantity',
    //   [],
    //   dummySerieName,
    //   DUMMY_SERIE_COLOR
    // )
    //
    // demandSeries.push(dummySerie)

    // FIXME CURRENTLY NOT WORKING
    legend.itemContainers?.template.events.on(
      "click",
      (e: am5.ISpritePointerEvent & {
        type: "click";
        target: am5.Container;
      }) => toggleDataItem(e, demandSeries, dummySerieName)
    );

    return () => root.dispose()

  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <>
      <Tooltip placement="bottom" text={intl.formatMessage({id: "tooltip_demand_chart"})}/>
      <div id={id} style={{minHeight: "350px"}}/>
    </>
  )
}