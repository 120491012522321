import React, {useContext} from "react";
import {DataContext} from "@services/DataContext";
import CheckboxGroup from "@ui-components/CheckboxGroup";
import {DivisionFilterProps, FiltersSchema} from "../types";
import Placeholder from "@ui-components/Placeholder";
import {InventoryContextSchema} from "@feature/inventory/types";


export function DivisionFilter<F extends FiltersSchema>({filters, onFilterChange, classNames = ""}: DivisionFilterProps<F>) {

  const {divisions} = useContext(DataContext) as InventoryContextSchema;


  return (
    <div className={classNames}>
      {
        divisions?.length && filters.division
          ? <CheckboxGroup
            horizontal
            options={divisions.map(x => ({
              value: x.des_division,
              icon_name: x.icon_name
            }))}
            values={filters.division.map((el) => divisions.find(d => d.cod_division === el)?.des_division)}
            onChange={(value: string) => {
              const cod = divisions.find(el => el.des_division === value)?.cod_division
              const newDivFilters = filters.division!;

              const i = newDivFilters.indexOf(cod!);
              if (i < 0)
                newDivFilters.push(cod!);
              else
                newDivFilters.splice(newDivFilters.indexOf(cod!), 1);

              onFilterChange(newDivFilters)
            }}
          />
          : <Placeholder classNames="h-20"/>
      }
    </div>
  )
}
