import React, {useContext, useState} from "react";
import {useIntl} from "react-intl";
import Modal from "@ui-components/Modal";
import {DataEntryContextSchema, OrderConstraintsModalProps, PalletizationOrderSchema} from "@feature/entry";
import {useSupplierConstraints} from "@feature/entry/palletization";
import {getFlgDays} from "@feature/entry/lib/constants";
import {BasicCheckBox, BasicInput, Box, RangeBox} from "@feature/entry/palletization/generic";
import Checkbox from "@ui-components/Checkbox";
import Button from "@ui-components/Button";
import Placeholder from "@ui-components/Placeholder";
import {saveNewConstraints} from "@feature/entry/palletization/lib/utils";
import {NotificationsContext} from "@ui-components/Notifications";
import {IS_DC_ENABLED} from "@feature/commons";
import Tooltip from "@ui-components/Tooltip";
import {DataContext} from "@services/DataContext";

type T = PalletizationOrderSchema;
export const OrderConstraintsModal = ({
	                                      opened,
	                                      onExit,
	                                      orderConstraints,
	                                      refetch,
                                      }: OrderConstraintsModalProps) => {
	
	const intl = useIntl();
	const {push} = useContext(NotificationsContext);
	
	const {triggerPalletizationRefresh} = useContext(DataContext) as DataEntryContextSchema;
	
	const [newConstraints, setNewConstraints] = useState<PalletizationOrderSchema>();
	
	const msg = (id: string) => intl.formatMessage({id});
	
	const closeModalAfterSaving = () => {
		refetch();
		triggerPalletizationRefresh();
		onExit();
	}
	
	// check if at least one delivery day is flagged
	const getDaysValidity = (isPickup: boolean = false) => newConstraints && newConstraints.flg_active
		? getFlgDays<T>(isPickup).some(({value}) => newConstraints[value])
		: true
	
	const constraintsLoading = useSupplierConstraints(
		orderConstraints,
		setNewConstraints
	)
	
	const updateConstraints = (key: keyof PalletizationOrderSchema, newValue: string | number | boolean | undefined) => setNewConstraints(prevState => ({
		...prevState!,
		[key]: newValue
	}))
	
	
	if (!opened)
		return <></>
	
	
	return (
		<Modal opened={opened} onExit={onExit} maxWidth="max-w-[80vw]">
			{
				!constraintsLoading && newConstraints
					? (
						<>
							<div className="flex items-center justify-between mx-8">
								<div className="flex flex-col gap-y-2">
									{
										IS_DC_ENABLED &&
                    <span><b>{msg('warehouse')}: </b>{newConstraints.cod_dc} - {newConstraints.des_dc}</span>
									}
									<span><b>{msg('Fornitore')}: </b>{`${newConstraints.cod_supplier} - ${newConstraints.business_name}`}</span>
									<span> <b>{msg('address')}: </b>
										{
											[
												newConstraints.cod_purchase_address,
												newConstraints.des_purchase_address,
												newConstraints.city,
												newConstraints.cod_province
											].filter(el => el).join(', ').replace(',,', ',')
										}
									</span>
									<span><b>{msg('Divisione')}: </b>{newConstraints.des_division}</span>
								</div>
								{
									newConstraints.order_validation_errors.length > 0 && (
										<div className="flex items-center gap-x-3">
											<span className="font-bold">{intl.formatMessage({id: 'error'})}</span>
											<Tooltip
												styleType='danger'
												placement="bottom"
												text={
													newConstraints.order_validation_errors.map(error_msg => intl.formatMessage({id: error_msg}))
												}/>
										</div>
									)
								}
							</div>
							<h1
								className="text-center my-10 text-2xl font-bold capitalize">{intl.formatMessage({id: "supplier_constraints"})}</h1>
							<div className="grid grid-cols-3 gap-5 gap-y-10 my-5">
								<Box
									title={msg('supplier_usage')}
									enableTooltip
									tooltipMessage={msg('supplier_usage_tooltip')}
									tooltipPosition="right">
									<div className="flex justify-between items-center gap-x-5">
										<BasicCheckBox
											classNames="my-auto"
											value={newConstraints.flg_active}
											label={msg('supplier_enabled')}
											onChange={newValue => updateConstraints('flg_active', newValue)}/>
										<BasicInput
											value={newConstraints.num_preference ?? 0}
											onChange={newValue => updateConstraints('num_preference', newValue)}
											label={msg("preference")}/>
									</div>
								</Box>
								<Box
									title={msg('delivery_days')}
									classNames={`col-span-${newConstraints.flg_pickup_days ? 1 : 2}`}>
									<div className="flex items-center justify-evenly w-full my-auto">
										{
											getFlgDays<T>().map(({value, name}) => (
												<div key={value} className="flex flex-col items-center gap-y-2">
													<Checkbox
														readOnly
														id={value}
														name={name}
														checked={newConstraints[value]}
														onChange={() => updateConstraints(value, !newConstraints[value])}
														classNames=""/>
													<label className="text-gray-700">{name}</label>
												</div>
											))
										}
									</div>
									{
										!getDaysValidity() &&
                    <span className="text-red-500 font-bold my-3">{msg("delivery_days_error")}</span>
									}
								</Box>
								{
									newConstraints.flg_pickup_days && (
										<Box
											title={`${msg('delivery_days')} Pickup`}
											classNames="col-span-2">
											<div className="flex items-center justify-evenly w-full my-auto">
												{
													getFlgDays<T>(true).map(({value, name}) => (
														<div key={value} className="flex flex-col items-center gap-y-2">
															<Checkbox
																readOnly
																id={value}
																name={name}
																checked={newConstraints[value]}
																onChange={() => updateConstraints(value, !newConstraints[value])}
																classNames=""/>
															<label className="text-gray-700">{name}</label>
														</div>
													))
												}
											</div>
											{
												!getDaysValidity(true) &&
                        <span className="text-red-500 font-bold my-3">{msg("delivery_days_error")}</span>
											}
										</Box>
									)
								}
								<RangeBox
									title={msg('weight_range')}
									tooltipMessage={msg('weight_range_tooltip')}
									tooltipPosition="right"
									constraints={newConstraints}
									minValField={'num_min_weight'}
									maxValField={'num_max_weight'}
									updateCallback={updateConstraints}/>
								<RangeBox
									title={msg('price_range')}
									tooltipMessage={msg('price_rage_tooltip')}
									tooltipPosition="right"
									constraints={newConstraints}
									minValField={'amt_min_price'}
									maxValField={'amt_max_price'}
									updateCallback={updateConstraints}/>
								<RangeBox
									title={msg('order_range')}
									tooltipMessage={msg('order_range_tooltip')}
									tooltipPosition="left"
									constraints={newConstraints}
									minValField={'num_min_order'}
									maxValField={'num_max_order'}
									umField={'um_order'}
									updateCallback={updateConstraints}/>
								<Box
									title={msg('order_multiple')}
									enableCheckBox
									checkBoxValue={newConstraints.flg_multiples}
									checkBoxOnChange={newValue => updateConstraints('flg_multiples', newValue)}
									checkBoxLabel={msg('active')}>
									{
										newConstraints.flg_multiples &&
                    <BasicInput
                      value={newConstraints.num_multiples}
                      onChange={newValue => updateConstraints('num_multiples', newValue)}
                      label={msg('quantity')}/>
									}
								</Box>
								<Box title={msg('pallet_constraints')}>
									<BasicCheckBox
										classNames="my-auto"
										value={!!newConstraints.flg_2_ref_per_pallet}
										label={msg('two_ref_per_pallet')}
										onChange={newValue => updateConstraints('flg_2_ref_per_pallet', newValue)}/>
								</Box>
								<Box title={msg('max_orders_per_month')}>
									<BasicInput
										value={newConstraints.num_max_deliveries_per_month}
										onChange={newValue => updateConstraints('num_max_deliveries_per_month', newValue)}
										label="Ordini"/>
								</Box>
								<RangeBox
									title={msg('round_down_range')}
									constraints={newConstraints}
									minValField={'num_min_round_down'}
									maxValField={'num_max_round_down'}
									umField={'um_round_down'}
									enableConstraintField={'flg_round_down'}
									updateCallback={updateConstraints}/>
								<RangeBox
									title={msg('foreign_range')}
									classNames="col-span-2"
									tooltipMessage={msg('foreign_range_tooltip')}
									tooltipPosition={'left'}
									constraints={newConstraints}
									minValField={'num_min_weight_international'}
									maxValField={'num_max_weight_international'}
									umField={'um_international'}
									enableConstraintField={'flg_international'}
									updateCallback={updateConstraints}/>
								<RangeBox
									title={msg('alternative_weight_range')}
									constraints={newConstraints}
									minValField={'num_min_weight_alternative_rule'}
									maxValField={'num_max_weight_alternative_rule'}
									enableConstraintField={'flg_alternative_rule'}
									updateCallback={updateConstraints}/>
								<RangeBox
									title={msg('alternative_order_range')}
									tooltipMessage={msg('alternative_order_range_tooltip')}
									tooltipPosition="right"
									constraints={newConstraints}
									minValField={'num_min_order_alternative_rule'}
									maxValField={'num_max_order_alternative_rule'}
									umField={'um_alternative_rule'}
									enableConstraintField={'flg_alternative_rule'}
									updateCallback={updateConstraints}/>
								<RangeBox
									title={msg('alternative_round_down_rule')}
									constraints={newConstraints}
									minValField={'num_min_alternative_rule_rounds_down'}
									maxValField={'num_max_alternative_rule_rounds_down'}
									umField={'um_alternative_rule_rounds_down'}
									enableConstraintField={'flg_alternative_rule_rounds_down'}
									updateCallback={updateConstraints}/>
								<Box
									title={msg("shipment_rules")}
									classNames="col-span-3">
									<div className="flex items-center justify-evenly w-full gap-x-5">
										<BasicInput
											value={newConstraints.num_max_sunday_track}
											onChange={newValue => updateConstraints('num_max_sunday_track', newValue)}
											label={msg('max_sunday_truck')}/>
										<BasicInput
											value={newConstraints.num_transit_time}
											onChange={newValue => updateConstraints('num_transit_time', newValue)}
											label={msg('transport_days')}/>
										<BasicInput
											value={newConstraints.num_lead_time}
											onChange={newValue => updateConstraints('num_lead_time', newValue)}
											label={msg('delivery_days')}
											isValid={!newConstraints.flg_active || !!newConstraints.num_lead_time}
											errorMessage={msg('delivery_days_error')}/>
									</div>
								</Box>
							</div>
							<div className="flex justify-between">
								<Button styleType="white" onClick={onExit}>{msg("cancel")}</Button>
								<Button type="submit" disabled={
									(newConstraints.flg_active && (!newConstraints.num_lead_time || !getDaysValidity())) ||
									(newConstraints.flg_pickup_days && !getDaysValidity(true))
								}
								        onClick={() => saveNewConstraints(newConstraints, closeModalAfterSaving, 'order', push, intl)}>
									{msg("save")}
								</Button>
							</div>
						</>
					)
					: <Placeholder height="h-[80vh]"/>
			}
		</Modal>
	)
}