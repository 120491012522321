import {IntlShape} from "react-intl";
import {api} from "@services/apiRequest";
import {
	ApplyAllSchema,
	ConstraintsPayloadSchema,
	PalletizationItemPrimaryKeyDataSchema,
	PalletizationItemSchema,
	PalletizationItemWithAddressSchema,
	PalletizationOrderSchema
} from "@feature/entry";

export const saveNewConstraints = <T extends PalletizationOrderSchema | PalletizationItemSchema | ApplyAllSchema>(
	newConstraints: T,
	refetch: () => void,
	type: 'order' | 'item' | 'item/all',
	notificationPush: (notification: { type: "success" | "error", title: string }) => void,
	intl: IntlShape
) => api.put(
	`/entry/palletization/${type}`, newConstraints
).then(() => {
	refetch();
	notificationPush({
		type: "success", title: intl.formatMessage({id: "success"})
	})
}).catch((e) => {
	console.error("Error during item constraints update: ", e);
	notificationPush({type: "error", title: intl.formatMessage({id: "generic_error"})})
})


export const saveAssignedItems = (
	newAssignedItems: PalletizationItemWithAddressSchema,
	notificationPush: (notification: { type: "success" | "error", title: string }) => void,
	intl: IntlShape
) => api.put<PalletizationItemWithAddressSchema>(
	'/entry/palletization/item/assign', newAssignedItems
).then(() => {
	notificationPush({
		type: "success", title: intl.formatMessage({id: "success"})
	})
}).catch((e) => {
	console.error("Error during assigned items update: ", e);
	notificationPush({type: "error", title: intl.formatMessage({id: "generic_error"})})
})

export const saveUnassignedItems = (
	newUnassignedItems: PalletizationItemPrimaryKeyDataSchema[],
	notificationPush: (notification: { type: "success" | "error", title: string }) => void,
	intl: IntlShape,
	refreshData: () => void
) => api.put<PalletizationItemWithAddressSchema>(
	'/entry/palletization/item/unassign', newUnassignedItems
).then(() => {
	notificationPush({
		type: "success", title: intl.formatMessage({id: "success"})
	})
	refreshData();
}).catch((e) => {
	console.error("Error while unassigning items: ", e);
	notificationPush({type: "error", title: intl.formatMessage({id: "generic_error"})})
})


export const addPurchaseGroup = (
	newSuppliersList: ConstraintsPayloadSchema[],
	intl: IntlShape,
	push: (notification: { type: string; title: string }) => void,
	onClose: () => void,
) => api.post(
	'entry/palletization/group',
	newSuppliersList
).then(() => {
	onClose()
	push({type: "success", title: intl.formatMessage({id: 'success'})});
})
	.catch((err) => {
		console.error(err)
		push({type: "error", title: intl.formatMessage({id: 'generic_error'})})
	})

export const editPurchaseGroup = (
	updatedSupplierList: ConstraintsPayloadSchema[],
	currentGroupId: number,
	intl: IntlShape,
	push: (notification: { type: string; title: string }) => void,
	onClose: () => void,
) => api.put(
	`entry/palletization/group/${currentGroupId}`,
	updatedSupplierList
).then(() => {
	onClose()
	push({type: "success", title: intl.formatMessage({id: 'success'})});
})
	.catch((err) => {
		console.error(err)
		push({type: "error", title: intl.formatMessage({id: 'generic_error'})})
	})

export const deletePurchaseGroup = (
	purchaseGroupId: number,
	intl: IntlShape,
	push: (notification: { type: string; title: string }) => void,
	onClose: () => void,
) => api.delete(
	`entry/palletization/group/${purchaseGroupId}`
).then(() => {
	onClose()
	push({type: "success", title: intl.formatMessage({id: 'success'})});
})
	.catch((err) => {
		console.error(err)
		push({type: "error", title: intl.formatMessage({id: 'generic_error'})})
	})