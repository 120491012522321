import React, {useContext} from 'react';
import {useIntl} from "react-intl";
import {api} from "@services/apiRequest";
import Slider from "@ui-components/Slider";
import Button from "@ui-components/Button";
import Placeholder from "@ui-components/Placeholder";
import {NotificationsContext} from "@ui-components/Notifications";
import {useParameters} from "./hooks";
import {getParameterDimensions} from "./lib/constants";

export function Parameters() {
	
	const {push} = useContext(NotificationsContext);
	const intl = useIntl();
	
	const {loading, parameters, setParameters} = useParameters();
	
	const handleUpdate = () => {
		api
			.post("entry/parameters", parameters)
			.then(() => push({title: intl.formatMessage({id: "success"}), type: "success"}))
			.catch(() => push({title: intl.formatMessage({id: "generic_error"}), type: "error"}))
	}
	
	
	return (
		!loading && parameters
			? <div className="flex flex-col gap-24 my-14 content-center items-center justify-around w-full">
				{
					Object.entries(getParameterDimensions(intl)).map(([key, {marks, dimension_enum}]) => (
						<div key={key} className="inline-flex flex-col gap-10 w-1/2">
							<h2 className="font-bold text-lg">{intl.formatMessage({id: key})}</h2>
							<Slider max={2}
							        defaultValue={1}
							        value={Object.values(dimension_enum).indexOf(parameters[key as keyof typeof parameters])}
							        onChange={(_: Event, v: number) => setParameters(
								        prevState => ({
									        ...prevState!,
									        [key]: Object.values(dimension_enum)[v]
								        })
							        )}
							        marks={marks}
							/>
						</div>
					))
				}
				<Button onClick={handleUpdate}>{intl.formatMessage({id: "save"})}</Button>
			</div>
			: <Placeholder height="h-96"/>
	)
}