import React, {useLayoutEffect} from "react";
import * as am5 from "@amcharts/amcharts5";
import {addLicense} from "@components/Charts/commons";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5map from "@amcharts/amcharts5/map";
import _ from "lodash";
import {COUNTRY_DATA} from "./lib/consts";
import {getUM} from "../lib/consts";
import Spinner from "@ui-components/Spinner";
import Placeholder from "@ui-components/Placeholder";
import Tooltip from "@ui-components/Tooltip";
import {useIntl} from "react-intl";
import {StockOutMapChartProps, StockSchema} from "../types";
import {formatNumber} from "@utils/format";

const Component = ({
	                   country,
	                   precision,
	                   data,
	                   loading,
	                   currentDimension,
                   }: StockOutMapChartProps) => {
	
	// hooks
	const intl = useIntl();
	
	// utilities
	const id = "stock-out-map-chart";
	const {
		geoData,
		defaultPrecision,
		exclude,
		prefix,
	} = COUNTRY_DATA[country];
	const chartData = data
		?.map(({code, out}: StockSchema) => ({
			id: prefix + code,
			value: out,
		}))
		?.filter(({id}) => !id.includes("**"));
	
	useLayoutEffect(() => {
		
		if (!chartData)
			return;
		
		addLicense();  // amcharts general license
		am5.addLicense("AM5M328990290"); // amcharts map license
		
		const root = am5.Root.new(id);
		root.setThemes([am5themes_Animated.new(root)]);
		
		// Create chart
		const chart = root.container.children.push(
			am5map.MapChart.new(root, {
				panX: "rotateX",
				projection: am5map.geoMercator(),
				layout: root.horizontalLayout,
			})
		);
		
		// Create polygon series
		// https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/
		const polygonSeries = chart.series.push(
			am5map.MapPolygonSeries.new(root, {
				geoJSON: geoData[precision || defaultPrecision],
				exclude,
				calculateAggregates: true,
				valueField: "value",
				fill: am5.color("#d9d9d9"),
			})
		);
		
		// Enable hover functionality
		polygonSeries.mapPolygons.template.setAll({
			tooltipText: "[bold]{name}[/]: {value} Unit",
			interactive: true, // nonScalingStroke: true,
			strokeWidth: 0.5,
		});
		polygonSeries.mapPolygons.template.adapters.add(
			"tooltipText",
			(__, target) => {
				const data: any = target.dataItem?.dataContext;
				const mu: string = _.capitalize(getUM(currentDimension));
				return `[bold]{name}[/]: ${formatNumber(data.value, {maximumFractionDigits: 0})}${
					data.value && mu ? " " + mu : ""
				}`;
			}
		);
		
		// Set hover fill color
		polygonSeries.mapPolygons.template.states.create("hover", {
			fill: am5.color("#f8f8f8"),
		});
		
		// Set Heatmap colors
		polygonSeries.set("heatRules", [
			{
				target: polygonSeries.mapPolygons.template,
				dataField: "value",
				min: am5.Color.brighten(am5.color("#5d8eae"), 0),
				max: am5.Color.brighten(am5.color("#0b485c"), -0.5),
				key: "fill",
			},
		]);
		
		// Add heat map data
		polygonSeries.data.setAll(chartData);
		
		return () => {
			root.dispose();
		};
	}, [country, chartData, id]); // eslint-disable-line react-hooks/exhaustive-deps
	
	if (!chartData)
		return <Placeholder width="w-full" height="h-full"/>
	
	return (
		<div className="h-full flex flex-col gap-3">
			<div className="flex flex-shrink">
				<Tooltip
					text={intl.formatMessage({id: "tooltip_home_map"})}
					placement="right"
				/>
			</div>
			<div className="flex-1 relative w-full h-full">
				{loading && (
					<div className="absolute inset-0 backdrop-blur z-10">
						<Spinner
							classes="relative"
							styleType="loader"
							description={undefined}
						/>
					</div>
				)}
				<div id={id} className="h-full"/>
			</div>
		</div>
	);
}

export const StockOutMapChart = React.memo(
	Component,
	(props, nextProps) => (
		props.loading === nextProps.loading
		&& props.country === nextProps.country
		&& props.precision === nextProps.precision
		&& _.isEqual(props.data, nextProps.data)
	)
);
