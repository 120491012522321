const it_demand = {
  demand_chart: "Grafico",
  demand_forecasting: "Previsione Domanda",
  demand_loading: "Aggiornamento dati domanda ...",
  demand_runs_tooltip: "L'ottimizzatore esegue un run completo di notte (ore 05:15) e due run parziali alle 09:25 e alle 15:55. Di default è selezionato l'ultimo run disponibile",
  demand_table: "Dato Tabellare",
  demand_table_title: "Andamento della domanda, consuntivo e forecast",
  tooltip_demand_chart: "La linea principale indica l'anno corrente; a sinistra della linea rossa il passato, a destra (tratteggiata) la previsione futura. La linea più chiara è il consuntivo dello stesso periodo dell'anno precedente",
  tooltip_demand_filter: "I filtri sono in gerarchia Magazzino - Famiglia - Fornitore - Gruppo merce. Ad esempio, filtrando un fornitore, sarà possibile selezionare tra tutti i gruppi merce ordinabili da quel fornitore",
};

export default it_demand;