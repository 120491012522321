import {useContext} from "react";
import {TrashIcon} from "@heroicons/react/24/outline";
import Modal from "@ui-components/Modal";
import {EditCardModalProps,OrdersContextSchema} from "@feature/orders";
import Button from "@ui-components/Button";
import {DataContext} from "@services/DataContext";
import {handleItemRemoval, safeItemRemoval} from "./lib/utils";
import {useIntl} from "react-intl";

export function EditCardModal({order, setOrder}: EditCardModalProps) {

	const intl = useIntl();

	const {setRefetchOrders} = useContext(DataContext) as OrdersContextSchema;

	if (!order)
		return <></>;

	return (
		<Modal classNames="flex flex-col items-center gap-y-12"
			opened={Boolean(order)} onExit={() => setOrder(undefined)}>
			<h1 className="font-bold text-2xl text-center">Ordine {order?.business_name}</h1>
			{
				order.items.map(
					item =>
						<div key={item.cod_item}
							className={`flex justify-evenly w-full items-center ${!item.active && 'line-through'}`}>
							<b>{item.des_item}:</b>
							<span>{item.current_val_quantity_purchase_order}{item.um_purchase_order}</span>
							<span>{item.current_val_quantity_multiples}{item.um_multiples}</span>
							{item.active &&
								<TrashIcon
									className="h-6 w-6 rounded-full bg-am-600 p-1 text-white cursor-pointer hover:bg-am-700"
									onClick={() => handleItemRemoval(
										item.cod_item,
										setOrder
									)} />
							}
						</div>
				)
			}
			<span>
				<b>{intl.formatMessage({id: 'delivery_date'})}:</b> {
					new Intl.DateTimeFormat('it-IT').format(new Date(order.current_dat_delivery))
				}
			</span>
			<Button
				onClick={() => {
					safeItemRemoval(order!, setRefetchOrders);
					setOrder(undefined);
				}}>
				{intl.formatMessage({id: 'save'})}
			</Button>
		</Modal>
	)
}