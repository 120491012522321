import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import {getUM} from "../../lib/consts";
import {formatNumber} from "@utils/format";
import {HomeFilterDimensions, StockSchema} from "../../types";
import _ from "lodash";
import {STOCK_IN_COLOR, STOCK_OUT_COLOR} from "@theme/index";

const setupYAxisTooltip = (root: am5.Root, chart: am5xy.XYChart, currentDimension: HomeFilterDimensions) => {
	
	const tooltip = am5.Tooltip.new(root, {
		getFillFromSprite: false,
		getStrokeFromSprite: true,
		autoTextColor: false,
		// the next attribute is going to make the tooltip overlap with the chart, but this is necessary in order to prevent
		// the tooltip to be cut off by the root <canvas> element
		centerX: -am5.p100
	})
	
	// set background as white
	tooltip.get("background")
		?.setAll({
			fill: am5.color("#fff"),
			stroke: am5.color("#000"),
			strokeOpacity: 0.2
		});
	
	// set text color as black
	tooltip.label.setAll({
		fill: am5.color("#000")
	});
	
	// set tooltip template
	tooltip.label.adapters.add("text", (text) => {
		let labelText = "[bold]" + text + "[/]";
		chart.series.each((s) => {
			const name = s.get("name");
			labelText += `\n[bold]${name}:[/] {${s.get("valueXField")}} ${_.capitalize(getUM(currentDimension))}`;
		})
		return labelText;
	});
	
	return tooltip;
}

export const setupYAxis = (root: am5.Root, chart: am5xy.XYChart, label: string, currentDim: HomeFilterDimensions) => {
	
	const renderer = am5xy.AxisRendererY.new(root, {
		inversed: true,
		minGridDistance: 10,
	});
	const yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
		renderer: renderer,
		categoryField: label,
		tooltip: setupYAxisTooltip(root, chart, currentDim),
	}));
	renderer.grid.template.setAll({
		location: 0,
		strokeOpacity: 0.1,
	})
	renderer.labels.template.setAll({
		// @ts-ignore
		wrap: true,
		fontSize: 8,
		maxWidth: 160,
		textAlign: "end",
	})
	
	return yAxis;
}


const setupXAxisTooltip = (root: am5.Root) => {
	return am5.Tooltip.new(root, {
		getFillFromSprite: false,
		getStrokeFromSprite: true,
		autoTextColor: false,
	});
}

export const setupXAxis = (root: am5.Root, chart: am5xy.XYChart, data: StockSchema[]) => {
	
	const renderer = am5xy.AxisRendererX.new(root, {
		minGridDistance: 40,
	})
	
	const findExtreme = (fn: (...args: number[]) => number, target: 'in_' | 'out') => {
		const candidates = data
			.filter((d) => !!d[target])
			.map((d) => d[target]!);
		return !!candidates.length ? fn(...candidates) : 0;
	}
	const min = findExtreme(Math.min, 'out');
	const max = findExtreme(Math.max, 'in_');
	
	const xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
		renderer: renderer,
		minHeight: 20,
		min,
		extraMin: Math.min(0.2, Math.abs(min)),
		max,
		extraMax: Math.min(0.2, max),
		tooltip: setupXAxisTooltip(root),
	}));
	renderer.grid.template.setAll({
		forceHidden: true
	})
	renderer.ticks.template.setAll({
		forceHidden: true,
		strokeOpacity: 0.4
	})
	renderer.labels.template.setAll({
		fontSize: 9
	});
	renderer.labels.template.adapters.add("text", (text) => {
		return text
			? formatNumber(parseFloat(text.toString()), {maximumFractionDigits: 0})
			: undefined;
	});
	
	return xAxis;
}

export const createSeries = (
	root: am5.Root,
	chart: am5xy.XYChart,
	name: string,
	valueField: "in_" | "out",
	categoryField: string,
	xAxis: am5xy.ValueAxis<am5xy.AxisRenderer>,
	yAxis: am5xy.CategoryAxis<am5xy.AxisRenderer>,
) => {
	// add values series
	const series = chart.series.push(am5xy.ColumnSeries.new(root, {
		name: name,
		xAxis: xAxis,
		yAxis: yAxis,
		valueXField: valueField,
		categoryYField: categoryField,
		clustered: false,
		fill: am5.color(valueField === "in_" ? STOCK_IN_COLOR : STOCK_OUT_COLOR),
		stroke: am5.color("#fff"),
	}))
	series.columns.template.setAll({
		cornerRadiusTR: 8,
		cornerRadiusBR: 8,
	});
	
	// add labels series (i.e. the values next to the bars)
	series.bullets.push((root) => am5.Bullet.new(root, {
		locationX: 1,
		sprite: am5.Label.new(root, {
			text: "{valueX}",
			populateText: true,
			centerY: am5.percent(50),
			textAlign: valueField === "in_" ? "left" : "right",
			fontSize: 8,
			// @ts-ignore
			truncate: false,
		})
	}));
}