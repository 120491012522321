import {useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {api} from "@services/apiRequest";
import {NotificationsContext} from "@ui-components/Notifications";
import {useAsyncError} from "@hooks/useAsyncError";
import {MrpLookupType} from "@feature/inventory/replenishment/types";

export function useMrpLookup(idRun?: number) {
	
	const {push} = useContext(NotificationsContext);
	const throwError = useAsyncError();
	const intl = useIntl();
	
	
	const [loading, setLoading] = useState<boolean>(false);
	const [mrpLookup, setMrpLookup] = useState<MrpLookupType>();
	
	useEffect(() => {
		setLoading(true);
		
		if (!idRun)
			return;
		
		api.get<MrpLookupType>(`inventory/${idRun}/mrp_lookup`)
			.then(({data}) => setMrpLookup(data))
			.catch((error) => {
				push({title: intl.formatMessage({id: 'generic_error'}), type: "error"});
				throwError(error);
			});
		setLoading(false);
	}, [intl, push, idRun, throwError]);
	
	return {loading, mrpLookup};
	
}