import React, {useLayoutEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import _ from "lodash";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5locales_it_IT from "@amcharts/amcharts5/locales/it_IT";
import am5locales_en_US from "@amcharts/amcharts5/locales/en_US";
import {InventoryStockChartDataProps} from "./types";
import {
  addCursor,
  addLicense,
  addSeriesBullet,
  getRenderAxisX,
  getRenderAxisY,
  getXYChart
} from "@components/Charts/commons";
import {BlurredPlaceholder, CHARTS_TIME_UNIT, DEFAULT_UM} from "../../commons";
import {DimensionToggle} from "@feature/inventory";
import {getDefaultAwareChartPalette} from "@theme/index";


// Constants
const AMCHARTS_ROOT_ID: string = "amcharts-root";
const DATE_FORMAT: string = "dd/MM/yyyy";
const CHART_COLORS = getDefaultAwareChartPalette();
const LEGEND_TOOLTIP_CLS: string = 'px-1.5 py-1 bg-black bg-opacity-80 text-white text-sm rounded';

// Utilities
type Props = InventoryStockChartDataProps;  // alias


const Component = ({data, loading}: Props) => {

  // hooks
  const intl = useIntl();

  // state
  const [showPrice, setShowPrice] = useState(false);

  // utilities
  const targetField = showPrice ? "price" : "quantity";
  const yAxisLabel = intl.formatMessage({id: targetField})
  const yAxisUm = showPrice ? "€" : _.capitalize(DEFAULT_UM);
  const chartStyle: React.CSSProperties = {
    minHeight: "350px",
    pointerEvents: loading ? "none" : "all",
  };

  useLayoutEffect(() => {

    addLicense();

    const root = am5.Root.new(AMCHARTS_ROOT_ID);
    root.setThemes([am5themes_Animated.new(root)]);
    root.dateFormatter.set("dateFormat", DATE_FORMAT);

    // Set locale
    root.locale = intl.locale === 'it' ? am5locales_it_IT : am5locales_en_US;

    // Create chart
    const chart = getXYChart(root, {layout: root.verticalLayout} as any, CHART_COLORS);
    addCursor(root, chart);

    // Create axes
    const xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
      baseInterval: {timeUnit: CHARTS_TIME_UNIT, count: 1},
      renderer: getRenderAxisX(root, false)
    }));
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: getRenderAxisY(root)
      }));

    if (data.length > 0) {

      // Add series
      const series = Object.keys(data[0])
        .filter((k) => k !== "date")
        .reduce(
          (acc, curr) => {
            const s = chart.series.push(am5xy.LineSeries.new(root, {
              name: intl.formatMessage({id: curr}),
              description: intl.formatMessage({id: `inventory${_.capitalize(curr)}Tooltip`}),
              minBulletDistance: 10,
              xAxis: xAxis,
              yAxis: yAxis,
              valueYField: "value",
              valueXField: "date",
              tooltip: am5.Tooltip.new(root, {
                labelText: `[bold]{date.formatDate()}:[/] {value} ${yAxisUm}`,
              }),
              legendRangeLabelText: "{name}",
            }));
            return {...acc, [curr]: s};
          },
          {} as { [key: string]: am5xy.LineSeries }
        );

      Object.entries(series).forEach(([key, s]) => {

        // Add data
        s.data.processor = am5.DataProcessor.new(root, {
          numericFields: ['value'],
          dateFields: ['date'],
          dateFormat: "yyyy-MM-dd",
        });
        s.data.setAll(data.map((p) => {
          const targetSerie = p[key as 'stock' | 'empty' | 'safety'];
          const value = targetSerie[targetField];
          return {date: p.date, value};
        }));

        // Add bullets
        addSeriesBullet(root, s);

      });

      // Add labels
      const yLabel = am5.Label.new(root, {
        rotation: -90,
        text: `${yAxisLabel} (${yAxisUm})`,
        y: am5.percent(70)
      });
      yAxis.children.unshift(yLabel);

      // Add legend
      const legend = chart.children.push(am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        centerY: am5.percent(100),
        Y: am5.percent(100),
      }));
      legend.data.setAll(chart.series.values);

      legend.itemContainers.template.setAll({
        tooltipHTML: `<div style="max-width: 250px; text-align: center" class='${LEGEND_TOOLTIP_CLS}'>{dataContext.description}</div>`,
      });

      // Make stuff animate on load
      Object.values(series).forEach((s) => {
        s.appear(1000);
      });
    }

    return () => {
      root.dispose()
    }
  }, [data, intl, showPrice, targetField, yAxisLabel, yAxisUm])

  return (
    <>
      <DimensionToggle toggleState={showPrice} setToggleState={setShowPrice}/>
      <BlurredPlaceholder loading={loading}>
        <div
          id={AMCHARTS_ROOT_ID}
          className={!data.length ? 'hidden' : ''}
          style={chartStyle}
        />
        {data.length === 0 && (
          <div
            className="w-full flex items-center justify-center p-20 text-lg bg-gray-100 text-gray-500 rounded-2xl"
            style={chartStyle}
          >
            {!loading && <FormattedMessage id="inventoryChartNoData"/>}
          </div>
        )}
      </BlurredPlaceholder>
    </>
  )
}

export const InventoryStockChart =
  React.memo(Component, (prev: Props, curr: Props) => {
    return _.isEqual(prev, curr);
  });
