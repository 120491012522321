import React, {useContext, useState} from "react";
import {BasicCheckBox, BasicInput, Box, RangeBox, UmChoice} from "@feature/entry/palletization/generic";
import {PalletizationItemSchema} from "@feature/entry";
import {CheckCircleIcon, PencilSquareIcon, XCircleIcon} from "@heroicons/react/24/outline";
import {ENTRY_UM, getFlgDays} from "@feature/entry/lib/constants";
import Button from "@ui-components/Button";
import Checkbox from "@ui-components/Checkbox";
import {useIntl} from "react-intl";
import {NotificationsContext} from "@ui-components/Notifications";
import {saveNewConstraints} from "@feature/entry/palletization/lib/utils";

type T = PalletizationItemSchema;
export const ItemConstraintsPanel = ({rowData, refetch}: { rowData: T, refetch: () => void }) => {
	
	const [newItemConstraints, setNewItemConstraints] = useState<T>(rowData);
	const [enableEdit, setEnableEdit] = useState<boolean>(false);
	
	const intl = useIntl();
	const {push} = useContext(NotificationsContext);
	
	const msg = (id: string) => intl.formatMessage({id});
	
	const validDeliveryDays = newItemConstraints.flg_delivery_days && newItemConstraints.flg_active
		? getFlgDays<T>().some(({value}) => newItemConstraints[value])
		: true;
	
	const updateConstraints = (key: keyof T, newValue: string | number | boolean | undefined) => setNewItemConstraints(prevState => ({
		...prevState,
		[key]: newValue
	}))
	
	return (
		<div className="flex flex-col items-center gap-y-7">
			<PencilSquareIcon
				className={`w-8 h-8 ml-auto mr-5 cursor-pointer rounded-full p-0.5 border ${enableEdit ? 'bg-am-600 text-white border-white' : 'bg-white text-am-600 border-am-600'}`}
				onClick={() => setEnableEdit(prevState => !prevState)}/>
			<div className="grid grid-cols-3 gap-3 gap-y-5">
				<Box title={msg('activate_article')} disabled={!enableEdit}>
					<BasicCheckBox
						value={newItemConstraints.flg_active}
						classNames="my-auto"
						label={msg('active')}
						onChange={newValue => updateConstraints('flg_active', newValue)}/>
				</Box>
				<RangeBox
					title={msg('price_range')}
					constraints={newItemConstraints}
					minValField={'amt_min_price'}
					maxValField={'amt_max_price'}
					customDisabled={!enableEdit}
					updateCallback={updateConstraints}/>
				<RangeBox
					title={msg('weight_range')}
					constraints={newItemConstraints}
					minValField={'num_min_weight'}
					maxValField={'num_max_weight'}
					customDisabled={!enableEdit}
					updateCallback={updateConstraints}/>
				<RangeBox
					title={msg('order_range')}
					constraints={newItemConstraints}
					minValField={'num_min_order'}
					maxValField={'num_max_order'}
					umField={'um_order'}
					customDisabled={!enableEdit}
					updateCallback={updateConstraints}/>
				<Box title={msg('order_multiple')} disabled={!enableEdit}>
					<div className="flex justify-around items-center gap-x-3 my-auto">
						<BasicInput
							value={newItemConstraints.num_multiples}
							onChange={newValue => updateConstraints('num_multiples', newValue)}
							label={msg('multiple')}/>
						<UmChoice
							value={newItemConstraints.um_multiples ?? '-'}
							onChange={newValue => updateConstraints('um_multiples', newValue)}
							options={ENTRY_UM}/>
					</div>
				</Box>
				<Box
					title={msg("custom_lead_time")}
					disabled={!enableEdit}
					enableTooltip
					tooltipPosition="left"
					tooltipMessage={msg('custom_lead_time_tooltip')}>
					<BasicInput
						value={newItemConstraints.num_lead_time}
						onChange={newValue => updateConstraints('num_lead_time', newValue)}
						label="Lead Time"/>
					<BasicInput
						value={newItemConstraints.num_lead_time_custom}
						onChange={newValue => updateConstraints('num_lead_time_custom', newValue)}
						label="Lead Time Custom"/>
				</Box>
				<Box
					title={msg("delivery_days")}
					classNames="col-span-3"
					disabled={!enableEdit}
					enableTooltip
					tooltipMessage={msg('delivery_days_tooltip')}
					tooltipPosition="left"
					enableCheckBox
					checkBoxValue={newItemConstraints.flg_delivery_days}
					checkBoxOnChange={newValue => updateConstraints('flg_delivery_days', newValue)}
					checkBoxLabel={msg("active")}>
					<div className="flex items-center justify-evenly w-full my-auto">
						{
							getFlgDays<T>().map(({value, name}) => (
								<div key={value} className="flex flex-col items-center gap-y-2">
									<Checkbox
										disabled={!newItemConstraints.flg_delivery_days}
										key={value}
										readOnly
										id={value}
										name={name}
										checked={newItemConstraints[value]}
										onChange={() => updateConstraints(value, !newItemConstraints[value])}
										classNames=""/>
									<label
										className={`text-gray-500 ${!newItemConstraints.flg_delivery_days && 'opacity-90 pointer-events-none'}`}>
										{name}
									</label>
								</div>
							))
						}
					</div>
					{
						// if flg_delivery_days is true, at least one day must be selected
						!validDeliveryDays &&
            <span className="text-red-500 font-bold my-3">{msg("delivery_days_error")}</span>
					}
				</Box>
			</div>
			{
				enableEdit &&
        <div className="flex items-center w-full justify-evenly">
          <Button styleType="white" className="border-am-600" onClick={() => {
						setNewItemConstraints(rowData);
						setEnableEdit(false);
					}}>
            <XCircleIcon className="h-5 w-5 mr-2"/>
	          {msg('cancel')}
          </Button>
          <Button
            onClick={() => saveNewConstraints(newItemConstraints, refetch, 'item', push, intl)}
            disabled={!validDeliveryDays}>
            <CheckCircleIcon className="h-5 w-5 mr-2"/>
		        {msg('save')}
          </Button>
        </div>
			}
		</div>
	)
}