import React, {ChangeEvent, Dispatch, SetStateAction, useCallback, useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";

// Services
import {api} from "@services/apiRequest";
import Modal from "@ui-components/Modal";
import DatePicker from "@ui-components/DatePicker";
import Button from "@ui-components/Button";
import Input from "@ui-components/Input";
import {NotificationsContext} from "@ui-components/Notifications";
import {changeMonth, dateToString} from "@utils/index";
import {DataContext} from "@services/DataContext";
import {InventoryContextSchema} from "@feature/inventory";
import {AvailableItemsModifySchema, ModifyRowDialogPropsType,} from "../types";
import CheckModifyOrder from "./check-modify-order";


export function ModifyRowDialog({open, toggleOpen, data, refresh}: ModifyRowDialogPropsType) {
	
	const intl = useIntl();
	
	const {push} = useContext(NotificationsContext);
	
	const {inventoryCurrentRun} = useContext(DataContext) as InventoryContextSchema;
	
	const [newData, setNewData] = useState<Partial<AvailableItemsModifySchema>>();
	
	useEffect(() => {
		setNewData(data);
	}, [data]);
	
	const msg = (id: string) => intl.formatMessage({id});
	
	const handleSave = useCallback(
		async (e) => {
			e.preventDefault();
			
			if (!newData)
				return
			
			if (
				(newData.new_dat_delivery === newData.dat_delivery && data.new_dat_delivery === newData.new_dat_delivery) ||
				(newData.new_val_quantity_multiples === newData.val_quantity_multiples && data.new_val_quantity_multiples === newData.new_val_quantity_multiples)
			) {
				push({type: "warning", title: intl.formatMessage({id: 'delivery_and_multiples_warning'})});
				return;
			}
			
			const payload = {...newData, id_run: inventoryCurrentRun?.id_run};
			
			await api.put("/inventory/update_inventory_row", payload).then(() => {
				toggleOpen();
				refresh();
				push({type: "success", title: intl.formatMessage({id: 'success'})});
			})
				.catch((err) => {
					console.error(err)
					push({type: "error", title: intl.formatMessage({id: 'generic_error'})})
				})
		},
		[newData, data?.new_dat_delivery, data?.new_val_quantity_multiples, inventoryCurrentRun?.id_run, push, intl, toggleOpen, refresh])
	
	if (!open || !newData)
		return <></>;
	
	return (
		<Modal opened={open} maxWidth="sm:max-w-2xl"
		       classNames="flex flex-col gap-y-10 items-center text-center" onExit={toggleOpen}>
			<h1 className="font-bold text-2xl">{msg('edit_dat_order_quantity')}</h1>
			<div className="flex flex-col items-start gap-y-5">
				<p><b>{msg("warehouse")}:</b> {newData.cod_dc} - {newData.des_dc}</p>
				<p><b>{msg("Materiale")}:</b> {newData.cod_item} - {newData.des_item}</p>
				<p><b>{msg("Fornitore")}:</b> {newData.cod_supplier} - {newData.business_name}</p>
				<p><b>{msg("Divisione")}:</b> {newData.des_division}</p>
			</div>
			<form onSubmit={handleSave} className="flex flex-col gap-y-4 items-center">
				<b>{msg("add_row_dat_order")}:</b>
				<div className="w-40">
					<DatePicker
						value={new Date(newData.new_dat_order ?? newData.dat_order!)}
						onChange={(d: Date) => setNewData(prevState => ({...prevState, new_dat_order: dateToString(d.setHours(0, 0, 0, 0))}))}
						minDate={changeMonth(new Date(), -1)}/>
				</div>
				{
					newData.new_dat_order && new Date(newData.new_dat_order).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0) && (
						<span className="text-red-500">{msg("order_date_before_today")}</span>
					)
				}
				<b>{msg("add_row_val_quantity_multiples")} : </b>
				<div className="flex items-center justify-center gap-x-4">
					<Input
						classNames="w-14"
						value={newData.new_val_quantity_multiples ?? newData.val_quantity_multiples}
						name="val_quantity_multiples"
						onChange={(e: ChangeEvent<HTMLInputElement>) => setNewData({
							...newData,
							new_val_quantity_multiples: parseFloat(e.target.value)
						})}
						validation={() => true}
						label={undefined}
						required
						errorMessage={undefined}
						showCalendar={undefined}
						onClear={undefined}/>
					<b>{newData.um_multiples}</b>
				</div>
				<CheckModifyOrder
					data={newData}
					setData={setNewData as Dispatch<SetStateAction<Partial<AvailableItemsModifySchema>>>}/>
				<Button className="mt-5" type="submit" disabled={
					(!newData.new_dat_delivery && !newData.new_val_quantity_multiples) || (!!newData.new_dat_order && new Date(newData.new_dat_order).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0))
				}>
					{intl.formatMessage({id: "save"})}
				</Button>
			</form>
		</Modal>
	);
}