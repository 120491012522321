import React, { useCallback, useContext, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import Input from "../../Input";
import Modal from "../../Modal";
import Toggle from "../../Toggle";
import Button from "../../Button";
import { NotificationsContext } from "../../Notifications";
import Placeholder from "../../Placeholder";
import RadioGroup from "../../RadioGroup";
import {api} from "@services/apiRequest";
import useRoles from "./hooks/useRoles";

function AddUserDialog({ open, toggleOpen, users, setUsers }) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [roleId, setRoleId] = useState("");
  const [disabled, setDisabled] = useState(false);
  const { push } = useContext(NotificationsContext);
  const roles = useRoles();

  const _onSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();

        if (!roleId) {
          push({
            title: intl.formatMessage({ id: "need_to_select_a_role" }),
            type: "error",
          });
          return;
        }

        if (!email || !name) {
          push({
            title: intl.formatMessage({ id: "enter_name_or_email" }),
            type: "error",
          });
          return;
        }

        setLoading(true);
        const {data} = await api.post("/users", {
          email,
          full_name: name,
          role_id: roleId,
          disabled,
        });
        setUsers({ ...users, [data.id]: data });
        toggleOpen();
        push({
          title: intl.formatMessage({ id: "user_created_successfully" }),
          type: "success",
        });
      } catch (error) {
        push({
          title: intl.formatMessage({ id: "server_error" }),
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    [email, name, roleId, disabled, setUsers, toggleOpen, users, push]
  );
  return (
    <div>
      <Modal opened={open} onExit={toggleOpen}>
        <div className="m-8">
          <h3 className="text-center text-2xl font-extrabold text-gray-900">
            <FormattedMessage id="add_user" />
          </h3>
        </div>

        <form id="add-user-form" onSubmit={_onSubmit}>
          <div>
            <Input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              label={intl.formatMessage({
                id: "name_and_surname",
              })}
              type="text"
              errorMessage={intl.formatMessage({
                id: "enter_name_and_surname",
              })}
              required
              data-cy="user-name"
            />
          </div>
          <div>
            <Input
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              label="Email"
              type="email"
              errorMessage={intl.formatMessage({
                id: "enter_email",
              })}
              required
              data-cy="user-email"
            />
          </div>
          <div className="mb-4">
            {roles ? (
              <div>
                <RadioGroup
                  horizontal
                  title={intl.formatMessage({
                    id: "role",
                  })}
                  options={roles.map((role) => ({
                    value: role.id,
                    name:
                      role.name.charAt(0).toUpperCase() + role.name.slice(1),
                  }))}
                  currentValue={roleId}
                  onChange={(value) => {
                    setRoleId(value);
                  }}
                />
              </div>
            ) : (
              <div>
                <Placeholder width="w-4/5" />
                <Placeholder width="w-3/5" />
              </div>
            )}
          </div>

          <div className="mb-6">
            <Toggle
              vertical
              label={intl.formatMessage({
                id: "disabled_user",
              })}
              checked={disabled}
              onChange={() => setDisabled(!disabled)}
            />
          </div>
          <div className="mt-4 flex items-end justify-end">
            <Button
              submitting={loading}
              type="submit"
              data-cy="add-user-button"
            >
              <FormattedMessage id="add" />
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default AddUserDialog;
