import {useContext, useEffect, useState} from "react";
import {api} from "@services/apiRequest";
import {useIntl} from "react-intl";
import {NotificationsContext} from "@ui-components/Notifications";
import {useAsyncError} from "@hooks/useAsyncError";

export function useDtnItemErrors() {

  const [errorCount, setErrorCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);

  const intl = useIntl();
  const {push} = useContext(NotificationsContext);
  const throwError = useAsyncError();

  useEffect(() => {
    setLoading(true);
    api
      .get<number>('entry/item/errors')
      .then((res) => setErrorCount(res.data))
      .catch(err => {
        console.error(err);
        push({title: intl.formatMessage({id: "generic_error"}), type: "error"})
        throwError(err);
      });
    setLoading(false);

  }, [intl, push, refresh, throwError]);

  return {errorCount, loading, setRefresh};
}