const en_demand = {
  demand_chart: "Chart",
  demand_forecasting: "Demand Forecasting",
  demand_loading: "Updating demand data ...",
  demand_runs_tooltip: "The optimizer executes a complete run at night (05:15) and two partial runs at 9:25 and at 15:55. The last available run is set by default",
  demand_table: "Table data",
  demand_table_title: "Demand Forecasting",
  tooltip_demand_filter: "Filters are in warehouse - family - supplier - good groups hierarchy. For example, filtering a supplier allows to select all the goods delivered by it",
  tooltip_demand_chart: "The main line represents the current year; at its left where is the past data, to the right a dotted line of the forecasted data. The lighter line represents data referring at the same period of the previous year",
};


export default en_demand;