import React, {useState} from "react";
import DatePicker from "../../../ui-components/DatePicker";

export const CustomEditDatePicker = ({value, onChange}) => {
  return (
    <DatePicker
      value={value ? new Date(value) : new Date()}
      classNames="w-32"
      minDate={null}
      onChange={(e) => onChange(new Intl.DateTimeFormat('fr-CA').format(e))}
      materialTable
    />
  )
}

export const CustomFilterDatePicker = (props) => {
  const [date, setDate] = useState(null);
  return (
    <DatePicker
      classNames={`w-fit ${props.classNames}`}
      value={date}
      minDate={null}
      onChange={(event) => {
        setDate(event);
        props.onFilterChanged(props.columnDef.tableData.id, event);
      }}
      onClear={() => {
        setDate(null);
        props.onFilterChanged(props.columnDef.tableData.id, null);
      }}
      materialTable
      isFirstColumn={props.isFirstColumn}
    />
  )
}