import React from "react";
import {useIntl} from "react-intl";
import DatePicker from "@ui-components/DatePicker";
import Alert from "@ui-components/Alert";
import {dateToString} from "@utils/index";
import {CustomOrderPropsType} from "../types";
import InputUmQuantity from "./input-um-quantity";

const CustomOrder = ({
                       data,
                       setData,
                       disabled,
                       leadtimeDimension,
                       dimensionName,
                       umDimension,
                       umValDimension
                     }: CustomOrderPropsType) => {
  const intl = useIntl();
  return (
    <>
      <Alert
        title={intl.formatMessage({id: "warning"})}
        text={intl.formatMessage({id: "add_row_warning"})}
        type="warning"
        slim
        onConfirm={undefined}
        onDismiss={undefined}
      />
      <DatePicker
        value={data.dat_delivery ? new Date(data.dat_delivery) : undefined}
        onChange={(d: Date) => setData({...data, dat_delivery: dateToString(d)})}
        label={intl.formatMessage({id: "add_row_dat_delivery"})}
        labelClassNames="font-bold text-sm"
        classNames="w-42"
        placeholder={intl.formatMessage({id: "add_row_dat_delivery"})}
        errorMessage=""
        minDate={undefined}/>
      <Alert
        title="Info"
        text={intl.formatMessage({id: "add_row_info"}, {leadtime: data[leadtimeDimension]!})}
        type="info"
        slim
        onConfirm={undefined}
        onDismiss={undefined}
      />
      <InputUmQuantity data={data}
                       setData={setData}
                       dimensionName={dimensionName}
                       disabled={disabled}
                       umDimension={umDimension}
                       umValDimension={umValDimension}/>
    </>
  );
}

export default CustomOrder;
