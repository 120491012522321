import React, {HTMLProps, useContext, useEffect, useMemo, useRef, useState} from "react";
import {useIntl} from "react-intl";
import MaterialTable, {Filter, Query} from "@material-table/core";
import {CheckIcon, PencilIcon} from "@heroicons/react/24/outline";
import {DataContext} from "@services/DataContext";
import TableStyle from "@ui-components/TableStyle";
import {BlurredPlaceholder, getCommonProps} from "@feature/commons";
import {tableIcons} from "@ui-components/TableIcons";
import {BulkEditType, DataEntryContextSchema, DtnItemResponseSchema, ItemTableProps} from "@feature/entry";
import {getColumns, getDtnItemExportColumnMappings, OPTIONS} from "./params";
import {BulkEditModal} from "../bulk-edit-modal";
import {useDtnItemFetch} from "@feature/entry/item/hooks";
import {NotificationsContext} from "@ui-components/Notifications";
import api from "@services/apiRequest";
import _ from "lodash";

type T = DtnItemResponseSchema;

const SingleEditIcon = ({classNames}: { classNames?: HTMLProps<HTMLElement>["className"] }) => (
  <PencilIcon className={`w-6 h-6 bg-table-100 border-2 border-gray-400 rounded-md text-gray-400 ${classNames}`}/>
)
const MultiEditIcon = () => (
  <span className="relative -top-1 -left-1">
    <SingleEditIcon/>
    <SingleEditIcon classNames="absolute top-1 left-1"/>
    <SingleEditIcon classNames="absolute top-2 left-2"/>
  </span>
)

export const ItemTable = ({notCompleted}: ItemTableProps) => {

  // hooks
  const intl = useIntl();
	
	const {divisions, triggerDtnItemRefresh} = useContext(DataContext) as DataEntryContextSchema;
  const {push} = useContext(NotificationsContext);

  const columns = useMemo(() => getColumns(intl, divisions ?? []), [intl, divisions]);  // avoid filter reset on rerender

  const {
    fetch,
    download,
    loading
  } = useDtnItemFetch<T>(columns, notCompleted);

  // state
  const [refreshTrigger, setRefreshTrigger] = useState<number>(0);
  const [query, setQuery] = useState<Query<T>>();
  const [bulkSelection, setBulkSelection] = useState<BulkEditType[]>([]);
  const [allBulkSelection, setAllBulkSelection] = useState<Filter<T>[]>();
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);

  // refs
  const ref = useRef<MaterialTable<T>>();
  const didMount = useRef(false);

  const commonProps = getCommonProps<T>(intl, OPTIONS);


  // utilities
  const title = `${intl.formatMessage({id: "data_entry"})} ${intl.formatMessage({id: "Materiale"})}`;
  const refresh = () => {
    setRefreshTrigger(prevState => prevState + 1);
    triggerDtnItemRefresh();
  };
  
  // handlers
  const _onRowUpdate = (newData: any, oldData: any) => {
    const modified_values = _.pickBy(newData, (value, key) => !_.isEqual(value, oldData[key]));
    const updatePromise = api.post('entry/item/update', [{cod_dc: newData.cod_dc, cod_item: newData.cod_item, ...modified_values}]);
    updatePromise.then(() => {
      refresh();
      
      push({
        type: "success",
        title: intl.formatMessage({id: "edit_success"})
      });
    });

    return updatePromise;
  };

  // effects
  useEffect(() => {
    if (didMount.current) refresh();
    else didMount.current = true;
  }, [notCompleted]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <BlurredPlaceholder loading={divisions === undefined}>
        <TableStyle>
          <MaterialTable
            {...commonProps}
            key={refreshTrigger}
            tableRef={ref}
            data={fetch}
            columns={columns}
            onFilterChange={() => setQuery((ref.current?.state as any)?.query)}
            title={title}
            isLoading={loading}
            editable={isEditable ? {} : {onRowUpdate: _onRowUpdate}}
            onSelectionChange={rows => setBulkSelection(rows.map(({cod_item, cod_dc}) => ({cod_item, cod_dc})))}
            actions={[
              (rowData: T) => (
                {
                  icon: () => bulkSelection.some(
                    ({cod_dc, cod_item}) => cod_dc === rowData.cod_dc && cod_item === rowData.cod_item
                  )
                    ? <CheckIcon className="h-5 w-5 bg-am-600 border-2 rounded-md text-white stroke-2 border-am-200"/>
                    : <div className="h-5 w-5 border-2 rounded-md border-am-200"/>,
                  onClick: () => setBulkSelection(prev => {
                    if (prev.some(({cod_dc, cod_item}) => cod_dc === rowData.cod_dc && cod_item === rowData.cod_item))
                      return prev.filter(({
                                            cod_dc,
                                            cod_item
                                          }) => cod_dc !== rowData.cod_dc && cod_item !== rowData.cod_item)
                    else
                      return [...prev, {cod_dc: rowData.cod_dc, cod_item: rowData.cod_item}]
                  }),
                  isFreeAction: false,
                  hidden: !isEditable
                }
              ),
              {
                icon: () => <tableIcons.Check/>,
                onClick: () => setOpenEditDialog(true),
                isFreeAction: true,
                hidden: !isEditable || !bulkSelection.length,
              },
              {
                icon: () => isEditable ? <tableIcons.Clear/> : <SingleEditIcon/>,
                onClick: () => {
                  setIsEditable(prevState => !prevState);
                  if (isEditable)
                    setBulkSelection([]);
                },
                isFreeAction: true,
              },
              {
                icon: () => <MultiEditIcon/>,
                onClick: () => setAllBulkSelection(query?.filters.filter(({value}) => value?.length)), // avoiding that lookup columns adds empty values (no filters checked)
                isFreeAction: true,
                hidden: isEditable || !query?.filters?.some(({value}) => value?.length),
              },
              {
                ...commonProps.downloadAction,
                onClick: () => download!("MaterialsConstraintsExport", query, getDtnItemExportColumnMappings(intl)),
                hidden: isEditable,
                isFreeAction: true,
              },
            ]}
          />
        </TableStyle>
      </BlurredPlaceholder>
      <BulkEditModal
        open={openEditDialog || !!allBulkSelection?.length}
        onExit={() => {
          !!allBulkSelection?.length ? setAllBulkSelection(undefined) : setOpenEditDialog(false);
          setIsEditable(false);
          setBulkSelection([]);
        }}
        bulkEditValues={allBulkSelection ?? bulkSelection}
        refresh={() => {
          refresh();
          triggerDtnItemRefresh();
        }}
        isAllBulkEdit={!!allBulkSelection?.length}
        tableColumns={columns}
        query={query!}
        notCompleted={notCompleted}
      />
    </>
  )

}