import {OrderPenaltyType, StockPenaltyType} from "@feature/entry";
import {IntlShape} from "react-intl";

export const getParameterDimensions = (intl: IntlShape) => ({
	'penalty_stock': {
		marks: [
			{value: 0, label: 'Kg'},
			{value: 1, label: intl.formatMessage({id: 'balanced'})},
			{value: 2, label: '€'},
		],
		dimension_enum: StockPenaltyType
	},
	
	'penalty_orders': {
		marks: [
			{value: 0, label: intl.formatMessage({id: 'low'})},
			{value: 1, label: intl.formatMessage({id: 'medium'})},
			{value: 2, label: intl.formatMessage({id: 'high'})},
		],
		dimension_enum: OrderPenaltyType
	},
})