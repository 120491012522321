import React, {useContext} from "react";
import {DataContext} from "@services/DataContext";
import {CommonContextSchema} from "@feature/commons";
import {BuildingLibraryIcon} from "@heroicons/react/24/outline";
import Placeholder from "@ui-components/Placeholder";
import Icon from "@ui-components/Icon";
import {FormattedMessage} from "react-intl";
import Badge from "@ui-components/Badge";
import {getUM} from "../lib/consts";
import {formatNumber} from "@utils/format";
import Checkbox from "@ui-components/Checkbox";

import {HomeFilterDimensions, SummaryProps} from "@feature/home";

export const Summary = ({
	                        division,
	                        in_,
	                        out,
	                        checked,
	                        currentDimension,
	                        loading,
	                        onChange,
	                        ...rest
                        }: SummaryProps) => {
	
	// context
	const {
		divisions
	} = useContext(DataContext) as CommonContextSchema;
	
	// utilities
	const getDivisionProp = (prop: string, d?: string | null) => {
		if (d === null)
			return null;
		const target = divisions?.find(({des_division}) => des_division === d);
		return (target as { [key: string]: any })?.[prop];
	}
	const renderIcon = (d?: string | null) => {
		const icon = getDivisionProp('icon_name', d);
		switch (icon) {
			case null:
				return <BuildingLibraryIcon className="w-6 h-6"/>;
			case undefined:
				return <Placeholder width="w-6" height="h-6" marginCls=""/>;
			default:
				return <Icon iconName={icon} className="w-6 h-6"/>
		}
	}
	const renderName = (d?: string | null) => {
		const description = getDivisionProp('des_division', d);
		switch (description) {
			case null:
				return <FormattedMessage id="inventory"/>;
			case undefined:
				return <Placeholder classNames="flex-1" width="w-full" marginCls=""/>;
			default:
				return description;
		}
	}
	const renderBadge = (label: string, type: string, currentDimension: HomeFilterDimensions, d?: number) => {
		if (d === undefined || loading)
			return <Placeholder width="w-full" marginCls=""/>;
		return (
			<Badge
				text={`${label}: ${formatNumber(d, {maximumFractionDigits: 0})} ${getUM(currentDimension)}`}
				type={type}
				textSizeClass="text-xs"
			/>
		)
	}
	
	return (
		<div className={`flex flex-col gap-3 ${rest.className || ''}`} {...rest}>
			<div className="flex flex-row items-center gap-3 h-10">
				{renderIcon(division)}
				<div className={`uppercase font-semibold text-sm ${division === undefined ? 'flex-1' : ''}`}>
					{renderName(division)}
				</div>
				{!!division && (
					<Checkbox
						readOnly={false}
						id={`summary-${division}`}
						name={`summary-${division}`}
						checked={checked}
						onChange={onChange}
						classNames="cursor-pointer"
					/>
				)}
			</div>
			<div className="flex flex-col gap-2">
				{renderBadge('IN', 'success', currentDimension, in_)}
				{renderBadge('OUT', 'info', currentDimension, out)}
			</div>
		</div>
	)
}