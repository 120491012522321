const errorCodes = {
  delivery_days_error: "Missing delivery days",
  comparison_error: "maximum value less than minimum",
  deliveries_per_month_error: "maximum number of monthly deliveries greater than 30",
  alternative_rule_error: "active alternative rules without base rule",
  international_rule_error: "international rule not fully valued",
  max_order_bigger_than_multiples_error: "multiple reorder greater than maximum orderable value",
  um_validity_error: "unit of measure not valued",
  multiples_error: "multiple reorder or unit of measure not valued",
  specific_delivery_days_error: "specific days selected but without valued days",
  multiples_comparison_error: "material reorder multiple greater than supplier's",
  minimum_range_value_error: "minimum range of material less than supplier's",
  non_positive_multiple_error: "non-positive multiple reorder value",
}

const en_data_entry = {
  materials: "Materials",
  pallet_order: "Order Constraints",
  parameters: "Parameters",
  item_association: "Item Association",
  what_article_substitute: "What articles does it substitute",
  product_name: "Product Name",
  aggregation_description: "Aggregation Description",
  choose_substitute_article: "Choose article to substitute",
  new: "New",
  article: "Article",
  replace: "Replace",
  replaced_by: "Replaced by",
  edit: "Edit",
  unspecified_item: "Associated Item not specified",
  penalty_stock: "Minimize volume or economic value",
  penalty_orders: "Order frequency",
  balanced: "Balanced",
  high: "High",
  low: "Low",
  medium: "Medium",
  grouping: "Grouping",
  active: "Active",
  supplier_constraints: "Supplier Constraints",
  item_constraints: "Item Constraints",
  create_new_grouping: "Create new grouping",
  grouping_tooltip: "A grouping is a set of suppliers (determined by division, company name and purchase address) for which delivery is made by a single subject",
  unassigned_items: "{unassigned_num} unassigned materials",
  unassigned_tooltip: "For these materials the purchase address is not specified among those associated with the supplier: without this data it is not possible to proceed with the reorder",
  wrong_suppliers: "Wrong suppliers",
  assign_purchase_addresses: "Assign purchase addresses",
  edit_purchase_group: "Edit purchase group {purchase_id}",
  weight_range: "Weight Range",
  weight_range_tooltip: "By specifying a minimum and / or maximum value, the proposed reorder will respect these weight limitations (Kg)",
  price_range: "Economic Value Range",
  price_rage_tooltip: "By specifying a minimum and / or maximum value, the proposed reorder will respect these economic value limitations (€)",
  order_range: "Order Range",
  order_range_tooltip: "By specifying a minimum and / or maximum value, and the relative unit of measure, the proposed reorder will respect these limitations",
  round_down_range: "Round down",
  foreign_range: "Constraints in the presence of a foreign supplier",
  foreign_range_tooltip: "This case, often useful for foreign suppliers, imposes to order the base to the most stringent constraint between a weight range and a range of another unit of measure (for example the minimum between 22 thousand Kg and 33 pallets)",
  alternative_round_down_rule: "Alternative round down rule",
  alternative_weight_range: "Alternative weight range",
  alternative_order_range: "Alternative order range",
  alternative_order_range_tooltip: "This constraint is a second option with respect to what is specified as the order range (for example, imposing that you can reorder a maximum of 15 pallets or 33 pallets)",
  supplier_enabled: "Active Supplier",
  supplier_usage: "Supplier Usage",
  supplier_usage_tooltip: "In addition to activating or deactivating the supplier, it is possible to establish how often you want to reorder the supplier from 1 (rarely) to 3 (often)",
  shipment_rules: "Transport Rules",
  max_sunday_truck: "Maximum Holiday Trips",
  transport_days: "Transport Days",
  order_multiple: "Reorder Multiple",
  multiple: "Multiple",
  custom_lead_time: "Custom Lead Time",
  custom_lead_time_tooltip: "If specified, the CUSTOM LEAD TIME will be taken into consideration by the optimizer instead of the default Lead Time",
  pallet_constraints: "Pallet Constraints",
  two_ref_per_pallet: "Max 2 references per pallet",
  max_orders_per_month: "Max monthly orders",
  activate_article: "Activate Article",
  apply_all: "Apply all",
  bulk_edit: "Bulk edit",
  bulk_edit_description: "You are modifying ALL the articles with",
  bulk_edit_choose: "Choose the fields to modify",
  bulk_edit_warning: "Pay attention! Bulk edit is a delicate operation, please check the changes carefully or prefer the single edit mode",
  safety_days_tooltip: "This value is a necessary input for the optimizer. The quantity expected to be output in this number of future days determines the safety stock to be imposed on the warehouse",
  extra_safety_kg_tooltip: "To the determined safety value of the safety days it is possible (not mandatory) to establish an additional safety quantity",
  ...errorCodes
}

export default en_data_entry;