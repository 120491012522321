import {FiltersPayloadSchema, HomeTarget} from "../../types";
import {useCallback, useContext, useState} from "react";
import {api} from "@services/apiRequest";
import {NotificationsContext} from "@ui-components/Notifications";
import {useIntl} from "react-intl";
import {useAsyncError} from "@hooks/useAsyncError";
import {OptionSchema} from "@feature/commons";

export const useFilter = (
  dateStart: string | undefined,
  dateEnd: string | undefined,
  divisions: Set<string> | undefined,
  filters: Record<HomeTarget, string[]>,
) => {

  // context
  const {push} = useContext(NotificationsContext);

  // state
  const [loading, setLoading] = useState(false);

  // hooks
  const intl = useIntl();
  const throwError = useAsyncError();

  const fetch = useCallback(
    async (target: HomeTarget, query: string, limit: number = 10) => {

      // exit if divisions are not loaded yet
      if (!divisions || !dateStart || !dateEnd)
        return;

      // build request payload
      const payload = {
        date_start: dateStart,
        date_end: dateEnd,
        divisions: Array.from(divisions || new Set()),
        filters,
        query,
        limit,
      } as FiltersPayloadSchema;

      // send request
      setLoading(true);
      try {
        const {data} = await api.post<OptionSchema[]>(`/home/filters/${target}`, payload);
        return data;
      }
      catch (e) {
        push({
          type: 'error',
          title: intl.formatMessage({id: 'server_error'}),
          message: `FILTER: ${target}`,
        });
        throwError(e);
      }
      finally {
        setLoading(false);
      }
    },
    [dateStart, dateEnd, divisions, filters, setLoading, push, intl, throwError],
  );

  return { fetch, loading };
}