import React, {useContext, useEffect, useState} from "react";
import {api} from "@services/apiRequest";
import {NotificationsContext} from "../../Notifications";
import {PageHeading, PageSection} from "../../Container";
import {useIntl} from "react-intl";
import moment from "moment";
import DatePicker from "../../DatePicker";
import LogFrontendDetailTable from "../../../components/Tables/LogFrontendDetailTable";
import LogFrontendErrorTable from "../../../components/Tables/LogFrontendErrorTable";
import StackedColumnChart from "../../../components/Charts/StackedColumnChart";
import RadioGroup from "../../RadioGroup";
import Placeholder from "../../Placeholder";
import {FaceSmileIcon, ExclamationCircleIcon} from "@heroicons/react/24/outline";


const AuditFrontend = () => {

  const chartHeight = "450px"

  const intl = useIntl();
  const {push} = useContext(NotificationsContext);

  const [loading, setLoading] = useState(false)
  const [datePicker, setDatePicker] = useState(new Date());
  const [distinctRoute, setDistinctRoute] = useState({});
  const [chartFilter, setChartFilter] = useState("visits")
  const [frontendDetailLog, setFrontendDetailLog] = useState();
  const [frontendErrorLog, setFrontendErrorLog] = useState();
  const [frontendGroupedLog, setFrontendGroupedLog] = useState();
  const [aggregation, setAggregation] = useState("giorno");
  const [fetchResult, setFetchResult] = useState()


  const getColumnsStackedChartFe = (data, sumValue) => {

    if (!data)
      return

    let filtered_results;
    // rimuoviamo da result i campi che non ci servono
    switch (sumValue) {
      case 'unique_visitors':
        filtered_results = data.map(({detail, errors, visits, ...rest}) => rest)
        break;
      case 'visits':
        filtered_results = data.map(({detail, errors, unique_visitors, ...rest}) => rest)
        break;
      default:
        break;
    }
    let uniqueRoutes = []

    let res = filtered_results.reduce((acc, el) => {
      const key = (el['page'] || '//').substring(1)
      const page = key.indexOf('/') > 0 ? key.substring(0, key.indexOf('/')) : key
      if (!uniqueRoutes.includes(page))
        uniqueRoutes.push(page)

      if (Object.keys(acc).includes(el['date'])) {
        let dateObj = acc[el['date']];
        if (Object.keys(dateObj).includes(page.toString())) {
          dateObj[page] += el[sumValue]
        } else {
          dateObj[page] = el[sumValue]
        }
        acc[el['date']] = dateObj;
      } else {
        acc[el['date']] = {[page]: el[sumValue]}
      }
      return acc
    }, {});

    res = Object.keys(res).map((key) => {
      const el = res[key];
      return {
        date: new Date(key).getTime(),
        ...el
      }
    })
    return [res, uniqueRoutes]
  }

  useEffect(() => {

    let {data: fetchFrontendDetail} = api.get(`/audit/navigation/${moment(datePicker).format('YYYY-MM-DD')}`);
    let {data: fetchFrontendError} = api.get(`/audit/error_frontend/${moment(datePicker).format('YYYY-MM-DD')}`);

    // Promise all serve per fare più chiamate API contemporaneamente
    Promise.all([fetchFrontendDetail, fetchFrontendError])
      .then((logs) => {
        setFrontendDetailLog(logs[0]);
        setFrontendErrorLog(logs[1]);

      })
      .catch(() => {
        push({
          title: "server_error",
          type: "error",
        });
      });
  }, [datePicker]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    let groupParams = {
      grouping: aggregation
    }

    let groupParamsURL = new URLSearchParams(groupParams)


    api.get(`/audit/navigation_grouped?${groupParamsURL}`)
      .then(({data: result}) => {
        setFetchResult(result)
      })
      .catch(() => {
        push({
          title: "server_error",
          type: "error",
        });
      })
  }, [datePicker, aggregation]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (!fetchResult)
      return

    const reduceStackedColumnChart = getColumnsStackedChartFe(fetchResult, chartFilter)
    setFrontendGroupedLog(reduceStackedColumnChart[0]);
    setDistinctRoute(reduceStackedColumnChart[1]);
    setLoading(false);
  }, [fetchResult, chartFilter])

  const missingData = () => {
    return <p className="my-10 w-full text-center">
      <ExclamationCircleIcon className="h-6 w-6 text-gray-400 inline-flex mr-2"/>
      Dati non disponibili
    </p>
  }

  return (<>
    <PageHeading
      title={intl.formatMessage({id: "frontend"})}
    />
    <div className="flex flex-col md:flex-row justify-between items-center mx-14 mb-10">
      <RadioGroup
        id="group1"
        title="Aggregazione"
        options={[
          {value: "giorno", name: "giorno"},
          {value: "mese", name: "mese"},
        ]}
        currentValue={aggregation}
        onChange={(value) => {
          setAggregation(value);
        }}
      />
      <div className="mt-10 md:mt-0">
        <RadioGroup
          id="group2"
          title="Visite/Utenti"
          options={[
            {value: "unique_visitors", name: "utenti"},
            {value: "visits", name: "visite"},
          ]}
          currentValue={chartFilter}
          onChange={(value) => {
            setChartFilter(value);
          }}
        />
      </div>
    </div>
    {loading
      ? <Placeholder style={{minHeight: chartHeight}}/>
      :
      <PageSection title={chartFilter === "unique_visitors" ? "Numero Utenti Distinti" : "Numero Visite Totali"}>
        {frontendGroupedLog?.length ?
          <StackedColumnChart
            id="frontend-barchart"
            chartHeight={chartHeight}
            dimensions={distinctRoute}
            data={frontendGroupedLog}
            filter={chartFilter}
            aggregation={aggregation}
          /> :
          missingData()
        }
      </PageSection>
    }
    <div className="w-36 mt-10">
      <DatePicker
        value={datePicker}
        onChange={setDatePicker}
        label="Data Selezionata"
        placeholder="Seleziona data"
        errorMessage="Seleziona data"
        minDate={new Date((new Date().getFullYear() - 1).toString())}
      />
    </div>
    <div className="my-5">
      <PageSection title="Dettaglio navigazioni">
        {frontendDetailLog?.length ?
          <LogFrontendDetailTable data={frontendDetailLog}/> :
          missingData()
        }
      </PageSection>
    </div>
    <PageSection title="Elenco degli errori">
      {frontendErrorLog?.length ?
        <LogFrontendErrorTable data={frontendErrorLog}/> :
        <p className="my-10 w-full text-center">
          <FaceSmileIcon className="h-6 w-6 text-green-700 inline-flex mr-2"/>
          Non sono presenti errori nel periodo selezionato
        </p>
      }
    </PageSection>
  </>)
    ;
}

export default AuditFrontend;
