import React, {useState} from "react";
import {PalletizationHeader, PalletizationTable} from "@feature/entry/palletization";

export function Palletization() {
	
	const [showInvalidConstraints, setShowInvalidConstraints] = useState<boolean>(false);
	const [openCreateGroup, setOpenCreateGroup] = useState<boolean>(false);
	const [refreshTrigger, setRefreshTrigger] = useState<number>(0);

	// utilities
	const refresh = () => setRefreshTrigger(prevState => prevState + 1);
	
	
	return (
		<>
			<PalletizationHeader
				setOpenCreateGroup={setOpenCreateGroup}
				showInvalidConstraints={showInvalidConstraints}
				setShowInvalidConstraints={setShowInvalidConstraints}
				refresh={refresh}
			/>
			<PalletizationTable
				showInvalidConstraints={showInvalidConstraints}
				setOpenCreateGroup={setOpenCreateGroup}
				openCreateGroup={openCreateGroup}
				refresh={refresh}
				refreshTrigger={refreshTrigger}
			/>
		</>
	)
}