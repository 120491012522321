import React, {useContext, useMemo} from "react";
import {useIntl} from "react-intl";
import {getCommonProps} from "@feature/commons";
import {DataEntryContextSchema, PalletizationItemResponseSchema, PalletizationSchema} from "@feature/entry";
import {useItemConstraints} from "@feature/entry/palletization";
import TableStyle from "@ui-components/TableStyle";
import MaterialTable from "@material-table/core";
import {getColumns, OPTIONS} from "./params";
import {ItemConstraintsPanel} from "@feature/entry/palletization/item-constraints";
import {DataContext} from "@services/DataContext";
import {ApplyAllAccordion} from "@feature/entry/palletization/item-constraints/apply-all-accordion/apply-all-accordion";
import { NotificationsContext } from "@ui-components/Notifications";

type T = PalletizationItemResponseSchema;
export const ItemConstraintsTable = ({itemConstraints, refreshPalletizationTableTrigger}: { itemConstraints: PalletizationSchema, refreshPalletizationTableTrigger: () => void }) => {
	
	// enable translations
	const intl = useIntl();
	
	const {push} = useContext(NotificationsContext);
	
	const {triggerPalletizationRefresh, setUnassignedItemsTrigger} = useContext(DataContext) as DataEntryContextSchema;
	
	const {loading, data, refresh} = useItemConstraints(itemConstraints);
	
	const refetchAndTriggerErrorCount = () => {
		refresh();
		refreshPalletizationTableTrigger();
		triggerPalletizationRefresh();
		setUnassignedItemsTrigger();
	}
	
	const columns = useMemo(() => getColumns(intl, push, refetchAndTriggerErrorCount), [intl, push]); // eslint-disable-line react-hooks/exhaustive-deps
	
	const commonProps = getCommonProps<T>(intl, OPTIONS);
	
	return (
		<>
			<ApplyAllAccordion refetch={refetchAndTriggerErrorCount} itemConstraints={itemConstraints}/>
			<TableStyle>
				<MaterialTable
					{...commonProps}
					data={data}
					title=""
					columns={columns}
					isLoading={loading}
					detailPanel={
						({rowData}) => (
							<ItemConstraintsPanel
								rowData={rowData}
								refetch={refetchAndTriggerErrorCount}/>
						)
					}
				/>
			</TableStyle>
		</>
	)
}