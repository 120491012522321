import {Dispatch, SetStateAction} from "react";
import {api} from "@services/apiRequest";
import {GroupedOrderType} from "@feature/orders";

export const handleItemRemoval = (cod_item: string, setOrder: Dispatch<SetStateAction<GroupedOrderType | undefined>>) =>
	setOrder(
		prevState => {
			if (!prevState)
				return prevState;

			const { items, ...rest } = prevState;

			const newItems = items.map(el => ({ ...el, active: el.cod_item !== cod_item }));
			return { ...rest, items: newItems }
		}
	)

export const safeItemRemoval = (orderToDelete: GroupedOrderType, setRefetchOrders: Dispatch<SetStateAction<boolean>>) => {

	const {cod_supplier, cod_purchase_address, dat_delivery} = orderToDelete;

	const deletePromises = orderToDelete.items
		.filter(it => !it.active)
		.map(item => {
			const queryParams = new URLSearchParams({
				cod_supplier,
				cod_purchase_address,
				dat_delivery,
				cod_item: item.cod_item,
				cod_dc: item.cod_dc
			})
			return api.delete(`/orders?${queryParams}`)
		})

	Promise.all(deletePromises).then(
		() => setRefetchOrders(p => !p)
	).catch(e => console.error(e))
}