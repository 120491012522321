import SearchSelect from "@ui-components/SearchSelect";
import React from "react";
import {MAX_SELECTABLE_FILTERS} from "./lib/consts";
import {FormattedMessage} from "react-intl";
import {OptionSchema} from "@feature/commons";
import {FilterProps} from "@feature/home";

type CustomOptionPropsType = {
  data: OptionSchema,
  innerProps: React.ComponentProps<any>,
  innerRef: React.Ref<any>,
}

export const Filter = ({
   label,
   fetch,
   value,
   onChange,
   ...rest
 }: FilterProps) => (
  <SearchSelect
    label={label}
    options={undefined}
    loadOptions={async (text: string, callback: (options: OptionSchema[]) => void) => {
      const options = await fetch(text);
      if (options === undefined) return;

      callback(options);
    }}
    onChange={onChange}
    value={value}
    CustomOption={(props: CustomOptionPropsType) => {
      const {data, innerProps, innerRef} = props;
      const cls = `px-4 py-2 text-sm ${value.length < MAX_SELECTABLE_FILTERS ?
        "cursor-pointer hover:bg-blue-100" :
        "pointer-events-none opacity-30"
      }`
      return (
        <article ref={innerRef} {...innerProps} className={cls}>
          <span className="font-bold">{data.value}</span> - {data.label}
        </article>
      );
    }}
    noOptionsMessage={() => <FormattedMessage id="type_something" />}
    isMulti
    {...rest}
  />
)