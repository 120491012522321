import {CurrencyEuroIcon, ScaleIcon} from "@heroicons/react/24/outline";
import Toggle from "@ui-components/Toggle";
import {DimensionTogglePropsType} from "../types";
import {GetUserScopes} from "@utils/index";

export function DimensionToggle({toggleState, setToggleState}: DimensionTogglePropsType) {

  const scopes: string[] | null = GetUserScopes();

  return scopes?.includes('price:read')
    ? (
      <div className="flex h-10 my-10 justify-center gap-x-2">
        <ScaleIcon className={`text-am-600 py-1 ${toggleState ? "opacity-25" : "opacity-100"}`}/>
        <Toggle checked={toggleState} onChange={() => setToggleState(!toggleState)}/>
        <CurrencyEuroIcon className={`text-am-600 py-1 ${toggleState ? "opacity-100" : "opacity-25"}`}/>
      </div>
    )
    : null
}