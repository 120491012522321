import * as am5xy from "@amcharts/amcharts5/xy";
import {LineSeries} from "@amcharts/amcharts5/xy";
import * as am5 from "@amcharts/amcharts5";
import {Color, Container, ISpritePointerEvent} from "@amcharts/amcharts5";
import {addSeriesBullet} from "@components/Charts/commons";
import {ERROR_COLOR} from "@theme/index";

export const getDateOfPreviousYear = (d: number | string): Date => {

  const inputDate = new Date(d);
  // Get the year of the input date
  const inputYear = inputDate.getFullYear();

  // Subtract 1 from the year to get the previous year
  const previousYear = inputYear - 1;

  // Create a new date object with the same month and day, but in the previous year
  const previousYearDate = new Date(inputDate);
  previousYearDate.setFullYear(previousYear);

  return previousYearDate;
}

export const makeDemandSeries = <T extends { [key: string]: any }>(
  root: am5.Root,
  legend: am5.Legend,
  xAxis: am5xy.Axis<any>,
  yAxis: am5xy.Axis<any>,
  chart: am5xy.XYChart,
  field: keyof T,
  chartData: T[],
  seriesName: string,
  color: am5.Color | string
) => {

  const series = chart.series.push(am5xy.LineSeries.new(root, {
    name: seriesName,
    minBulletDistance: 10,
    xAxis: xAxis,
    yAxis: yAxis,
    valueYField: field.toString(),
    valueXField: "dat_forecast",
    stroke: color as Color,
    fill: color as Color,
    tooltip: am5.Tooltip.new(
      root,
      {labelText: `[bold]{${field === 'val_quantity_py' ? 'dat_forecast_py' : 'dat_forecast'}.formatDate()}:[/] {${field.toString()}}`}
    )
  }))

  series.data.processor = am5.DataProcessor.new(root, {
    numericFields: [seriesName],
    dateFields: ["dat_forecast"],
    dateFormat: "yyyy-MM-dd"
  })

  series.strokes.template.setAll({
    strokeWidth: 3,
    templateField: "strokeSettings"
  });

  series.bullets.push(() => am5.Bullet.new(root, {
    sprite: am5.Circle.new(root, {
      radius: 3,
      fill: series.get("fill")
    })
  }));
  
  series.data.setAll(chartData)
  
  legend.data.push(series)
  
  addSeriesBullet(root, series);

  series.appear(1000).then(() => {
  })


  return series
}

export const toggleDataItem = (event: ISpritePointerEvent & {
  type: "click",
  target: Container
}, chartSeries: LineSeries[], dummySerieName: string) => {

  // @ts-ignore
  const legendItem = event.target._dataItem.dataContext._settings.name
  if (legendItem === dummySerieName)
    chartSeries.forEach(s => {
      if (s._settings.valueYField!.endsWith("_py"))
        if (s.isHidden())
          s.show().then(() => {
          })
        else
          s.hide().then(() => {
          })
    })
}

export const createDateLine = (xAxis: am5xy.DateAxis<any>, d: Date | string) => {

  const dateConverted = typeof d === 'string' ? new Date(d) : d;

  const currentDateLine = xAxis.makeDataItem({
    // if hours is not set to 12 the red line is slightly displayed on the left of the desired date
    value: dateConverted.setHours(12),
    endValue: dateConverted.setHours(12),
  })

  const range = xAxis.createAxisRange(currentDateLine);

  range.get("axisFill")?.setAll({
    visible: true,
    strokeWidth: 3,
    stroke: am5.color(ERROR_COLOR),
  });

}