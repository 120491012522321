import React, {ChangeEvent} from "react";
import {useIntl} from "react-intl";
import Input from "@ui-components/Input";
import {UmQuantityPropsType} from "../types";

function InputUmQuantity({
                           data,
                           setData,
                           disabled,
                           dimensionName,
                           umDimension,
                           umValDimension
                         }: UmQuantityPropsType) {

  const intl = useIntl();

  return (
    <div className="flex gap-x-4 items-center md:mx-4">
      <Input
        value={data[dimensionName]}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setData(prevState => ({...prevState, [dimensionName]: parseInt(e.target.value)}));
        }}
        disabled={disabled}
        label={intl.formatMessage({id: "add_row_val_quantity_multiples"})}
        placeholder={intl.formatMessage({id: "add_row_val_quantity_multiples"})}
        type="number"
        numberOptions={{
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }}
        name={undefined}
        validation={false}
        required
        errorMessage={undefined}
        showCalendar={false}
        onClear={undefined}/>
      <p className="font-semibold text-lg">{data[umDimension]}</p>
      <p className="text-xs text-gray-400">
        {intl.formatMessage({id: "choose_multiple"})} {data[umValDimension]}
      </p>
    </div>
  )
}

export default InputUmQuantity;
