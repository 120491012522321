import React, {useContext} from "react";
import {useIntl} from "react-intl";
import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/24/outline";
import Modal from "@ui-components/Modal";
import Button from "@ui-components/Button";
import {NotificationsContext} from "@ui-components/Notifications";
import {EditDeliveryGroupProps} from "@feature/entry";
import {useDeliveryGroup} from "@feature/entry/palletization/hooks";
import {ConstraintsMap} from "@feature/entry/palletization/delivery-group";
import {deletePurchaseGroup, editPurchaseGroup} from "@feature/entry/palletization/lib/utils";

export function EditDeliveryGroupModal({opened, onExit, deliveryGroupId, refetch}: EditDeliveryGroupProps) {
	
	const intl = useIntl();
	const {push} = useContext(NotificationsContext);
	
	const refetchAndExit = () => {
		refetch();
		onExit();
	}
	
	const {deliveryGroupData, setDeliveryGroupData, loading} = useDeliveryGroup(deliveryGroupId);
	
	if (!opened)
		return <></>;
	
	return (
		<Modal maxWidth="md:max-w-2xl" opened={opened} onExit={onExit}
		       classNames="flex flex-col items-center gap-y-10">
			<h1
				className="text-center text-2xl text-am-600 font-bold">{intl.formatMessage({id: "edit_purchase_group"}, {purchase_id: deliveryGroupId})}</h1>
			{!loading && <ConstraintsMap deliveryGroups={deliveryGroupData} setDeliveryGroups={setDeliveryGroupData}/>}
			<div className="flex items-center w-full justify-around">
				<Button
					onClick={() => editPurchaseGroup(deliveryGroupData, deliveryGroupId, intl, push, refetchAndExit)}>
					<CheckCircleIcon className="shrink w-6 h-6 mr-2"/>
					{intl.formatMessage({id: 'save'})}
				</Button>
				<Button
					styleType="white"
					onClick={() => deletePurchaseGroup(deliveryGroupId, intl, push, refetchAndExit)}>
					<XCircleIcon className="shrink w-6 h-6 mr-2"/>
					{intl.formatMessage({id: 'delete'})}
				</Button>
			</div>
		</Modal>
	)
}