import {dateToString} from "@utils/index";
import {castFiltersToArrays} from "@feature/commons";
import {
   DemandFiltersSchema,
 } from "@feature/demand";

// given a date, add x number of weeks
export const addDays = (d: Date | string, days: number, toString: boolean = false): Date | string => {
   const newDate = typeof d === 'string' ? new Date(d) : d;
   newDate.setDate(newDate.getDate() + days);
   return toString ? dateToString(newDate) : newDate;
 }
 
 export const convertDemandFilters = (demandFilters: DemandFiltersSchema) => {
   const {detail_level, ...defaultFilters} = demandFilters;
 
   return {
     ...castFiltersToArrays(defaultFilters),
     detail_level: detail_level,
   }
 }