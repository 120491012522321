import {api} from "@services/apiRequest";
import {
  InventoryReplenishmentExtendedWithCodReorderAccountantSchema
} from "../../types";

export const addOrder = (
  rowData: InventoryReplenishmentExtendedWithCodReorderAccountantSchema,
  inventoryIdRun: number,
  refetch: () => void
) => {

  const newOrder = {
    ...rowData,
    id_run: inventoryIdRun,
  }

  api.put('/orders/add_order', newOrder).then(() => refetch())
    .catch(e => console.error(e))
};