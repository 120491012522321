import React from "react";
import {useIntl} from "react-intl";
import {InventoryAlertsTableProps} from "../types";
import TableStyle from "@ui-components/TableStyle";
import {getCommonProps} from "@feature/commons";
import {AlertsWasteTable} from "./waste-table/alert-waste-table";
import {AlertOverStockTable} from "./over-stock-table/alert-over-stock-table";
import {AlertModelErrorsTable} from "./model-errors-table/alert-model-errors-table";

export const AlertsTable = ({
                              runId,
                              divisions,
                              name,
                            }: InventoryAlertsTableProps) => {

  const intl = useIntl();

  const commonProps = getCommonProps(intl)

  const getContent = () => {
    switch (name) {
      case 'waste_food':
        return <AlertsWasteTable runId={runId} divisions={divisions} {...commonProps} />
      case "over_stock":
        return <AlertOverStockTable runId={runId} divisions={divisions} {...commonProps} />
      case "model_errors":
        return <AlertModelErrorsTable runId={runId} divisions={divisions} {...commonProps} />
      default:
        throw Error(`Unhandled table: '${name}'`);
    }
  }

  return (
    <TableStyle>
      {getContent()}
    </TableStyle>
  )
}