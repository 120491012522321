import React, {useContext, useMemo, useRef} from "react";
import MaterialTable from "@material-table/core";
import {useIntl} from "react-intl";

import TableStyle from "@ui-components/TableStyle";
import {getCommonProps} from "@feature/commons";
import {downloadExcel} from "@utils/downloadExcel";
import {DataContext} from "@services/DataContext";

import {getColumns} from "./params";
import {DemandContextSchema, DemandForecastingResponseSchema, DemandTableProps} from "../types";
import {dateToString} from "@utils/index";

type T = DemandForecastingResponseSchema;

export function DemandTable({data, loading}: DemandTableProps) {

  // enable translations
  const intl = useIntl();

  const ref = useRef<any>(); // TODO: find better typing

  const {
    divisions,
    demandFilters,
    demandGroupKey,
  } = useContext(DataContext) as DemandContextSchema;

  const columns = useMemo(() => getColumns(
    demandFilters.detail_level,
    demandGroupKey.dimension,
    divisions ?? [],
    intl
  ), [demandFilters.detail_level, demandGroupKey.dimension, divisions, intl]);

  const commonProps = getCommonProps<T>(intl);


  const msg = (id: string) => intl.formatMessage({id});


  return (
    <div className="w-full">
      <TableStyle>
        <MaterialTable
          {...commonProps}
          tableRef={ref}
          data={data}
          title={msg("demand_table_title")}
          columns={columns}
          isLoading={loading}
          actions={[
            {
              ...commonProps.downloadAction,
              onClick: () => downloadExcel<T>({
                exportData: (ref.current?.state.data as T[]).map(el => ({
                  ...el,
                  dat_forecast: dateToString(new Date(el.dat_forecast))
                })),
                columns,
                name: msg("demand_table_title")
              }),
            },
          ]}
        />
      </TableStyle>
    </div>
  );
}
