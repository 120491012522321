import {useContext, useEffect, useState} from "react";
import {api} from "@services/apiRequest";
import {useIntl} from "react-intl";
import {NotificationsContext} from "@ui-components/Notifications";
import {useAsyncError} from "@hooks/useAsyncError";
import {ConstraintsPayloadSchema, PalletizationItemResponseSchema} from "@feature/entry";

export function useItemConstraints(
	payload: ConstraintsPayloadSchema | undefined
) {
	
	const [data, setData] = useState<PalletizationItemResponseSchema[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [trigger, setTrigger] = useState<boolean>(false);
	
	const intl = useIntl();
	const {push} = useContext(NotificationsContext);
	const throwError = useAsyncError();
	
	useEffect(() => {
		
		if (!payload)
			return;
		
		setLoading(true);
		api
			.post<PalletizationItemResponseSchema[]>('entry/palletization/item', payload)
			.then((res) => setData(res.data))
			.catch(err => {
				console.error(err);
				push({title: intl.formatMessage({id: "generic_error"}), type: "error"})
				throwError(err);
			});
		setLoading(false);
		
	}, [intl, JSON.stringify(payload), push, setData, throwError, trigger]); // eslint-disable-line react-hooks/exhaustive-deps
	
	return {data, loading, refresh: () => setTrigger(prevState => !prevState)};
}