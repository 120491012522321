import {useNavigationContext} from "@services/NavigationContext";

export const useNavigation = () => {
  const {navigate} = useNavigationContext();

  if (navigate == null) {
    throw Error(
      'Navigation context unavailable – this component needs to be a child of <Navigation>.',
    );
  }

  return navigate;
};
