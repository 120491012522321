import React from "react";
import {IntlShape} from "react-intl";
import {MTColumnsEditType, PalletizationItemResponseSchema} from "@feature/entry/types";
import {Options} from "@material-table/core";
import {ERROR_COLOR} from "@theme/index";
import { TrashIcon } from "@heroicons/react/24/outline";
import { saveUnassignedItems } from "../../lib/utils";

type T = PalletizationItemResponseSchema;
export const getColumns = (intl: IntlShape, push: any, refreshData: () => void ): MTColumnsEditType<T> => [
	{
		title: intl.formatMessage({id: "Materiale"}),
		field: "cod_item",
		cellStyle: {fontWeight: "bold"},
		
	},
	{
		title: intl.formatMessage({id: "description"}),
		field: "des_item",
		render: ({des_item}) => <small>{des_item}</small>
	},
	{
		title: intl.formatMessage({id: "active"}),
		field: "flg_active",
		type: "boolean"
	},
	{
		title: "",
		width: 50,
		render: (item) => <TrashIcon onClick={()=> {
			saveUnassignedItems([item], push, intl, refreshData);
		}} className="shrink-0 h-6 w-6 cursor-pointer" />
	},
]


export const OPTIONS: Options<T> = {
	filtering: false,
	emptyRowsWhenPaging: false,
	rowStyle: (rowData: T) => ({
		backgroundColor: !rowData.is_valid_item ? ERROR_COLOR : ''
	})
}