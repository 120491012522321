import {Dispatch, SetStateAction} from "react";
import {api} from "@services/apiRequest";
import {GroupedOrderType} from "@feature/orders";

export const handleOrderRemoval = (
	params: Pick<GroupedOrderType, 'cod_supplier' | 'cod_purchase_address' | 'dat_delivery' | 'items'>,
	setRefetchOrders: Dispatch<SetStateAction<boolean>>
) => {
	const {cod_supplier, cod_purchase_address, dat_delivery} = params
	
	const deletePromises = params.items.map(it => {
		const queryParams = new URLSearchParams({
			cod_supplier,
			cod_purchase_address,
			dat_delivery,
			cod_item: it.cod_item,
			cod_dc: it.cod_dc
		})
		return api.delete(`/orders?${queryParams}`)
	})
	
	Promise.all(deletePromises).then(
		() => setRefetchOrders(p => !p)
	).catch(e => console.error(e))
}