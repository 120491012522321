import {FiltersSchema} from "../../types";

export const castFiltersToArrays = (filters: FiltersSchema) => {

  const defaultFilters = {
    area: [],
    division: [],
    product: [],
    supplier: [],
    warehouse: []
  }

  if (Object.values(filters).every(item => item)) // apply "reduce" only if all filters are defined
    return Object.keys(filters).reduce<Record<keyof FiltersSchema, string[]>>(
      (result, key) => {
        result[key as keyof FiltersSchema] = filters[key as keyof FiltersSchema]!.map(
          item => typeof item === 'string' ? item : item.value
        );
        return result;
      }, defaultFilters
    )
  else
    return defaultFilters
};