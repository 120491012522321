import {GroupedOrderType, OrderFilterType} from "@feature/orders";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import DatePicker from "@ui-components/DatePicker";
import Label from "@ui-components/Label";
import {getFilterLocalization} from "@feature/orders/lib/utils";
import {CustomFilter} from "./custom-filter";

type T = GroupedOrderType;

export function OrderFilters({orders, setFilteredOrders}: {
	orders: T[],
	setFilteredOrders: Dispatch<SetStateAction<T[]>>
}) {
	
	const intl = useIntl();
	
	const [filters, setFilters] = useState<OrderFilterType>({});
	
	useEffect(() => {
		setFilteredOrders(
			orders.filter(
				({cod_supplier, cod_purchase_address, items, current_dat_delivery}) => {
					const supplier = filters.supplier?.length
						? filters.supplier.some(({value}) => value === cod_supplier)
						: true;
					const purchase_address = filters.purchase_address?.length
						? filters.purchase_address.some(({value}) => value === cod_purchase_address)
						: true;
					const item = filters.item?.length
						? filters.item.some(({value}) => items.some(({cod_item}) => cod_item === value))
						: true;
					const division = filters.division?.length
						? filters.division.some(({value}) => items.some(({cod_division}) => cod_division === value))
						: true;
					const reorder_accountant = filters.reorder_accountant?.length
						? filters.reorder_accountant.some(({value}) => items.some(({cod_reorder_accountant}) => cod_reorder_accountant === value))
						: true;
					const dat_delivery = filters.dat_delivery
						? new Date(current_dat_delivery).toDateString() === filters.dat_delivery.toDateString()
						: true;
					return supplier && purchase_address && item && division && reorder_accountant && dat_delivery;
				}
			)
		)
	}, [filters.dat_delivery, filters.division, filters.item, filters.purchase_address, filters.reorder_accountant, filters.supplier, orders, setFilteredOrders]);
	
	return (
		<div className="grid grid-cols-[repeat(3,_minmax(0,_20rem))] justify-center gap-2 w-full place-items-center">
			<CustomFilter orders={orders} filters={filters} setFilters={setFilters} dimension="supplier"/>
			<CustomFilter orders={orders} filters={filters} setFilters={setFilters} dimension="purchase_address"/>
			<CustomFilter orders={orders} filters={filters} setFilters={setFilters} dimension="item"/>
			<CustomFilter orders={orders} filters={filters} setFilters={setFilters} dimension="division"/>
			<CustomFilter orders={orders} filters={filters} setFilters={setFilters} dimension="reorder_accountant"/>
			<span>
				<Label>{getFilterLocalization('delivery_date', intl)}</Label>
				<DatePicker
					value={filters.dat_delivery}
					classNames="w-2/3 mx-auto mt-1 mb-2"
					onClear={() => setFilters(
						prevState => ({
							...prevState,
							dat_delivery: undefined
						})
					)}
					onChange={(d: Date) => setFilters(
						prevState => ({
							...prevState,
							dat_delivery: d
						})
					)}
					minDate={new Date()}/>
			</span>
		</div>
	)
}