import React from "react";
import Spinner from "@ui-components/Spinner";
import {BlurredPlaceholderProps} from "../types";

export function BlurredPlaceholder({loading, children}: BlurredPlaceholderProps) {
  return (
    <div className="w-full h-auto relative">
      {
        loading && (
          <div className="absolute inset-0 backdrop-blur z-10">
            <Spinner classes="relative" styleType="white"/>
          </div>
        )
      }
      {children}
    </div>
  )
}