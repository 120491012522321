import {useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {api} from "@services/apiRequest";
import {NotificationsContext} from "@ui-components/Notifications";
import {InventoryChartDataReturnType, InventoryChartPointSchema} from "../types";

export const useInventoryStockChartData = (
  runId: number | null,
  payload: { [p: string]: string[] } | null
): InventoryChartDataReturnType => {

  // hooks
  const intl = useIntl();

  // context
  const {push} = useContext(NotificationsContext);

  // state
  const [data, setRawData] = useState<InventoryChartPointSchema[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!runId || !payload)
      return;

    (async () => {
      setLoading(true);
      try {
        const {data} =
          await api.post<InventoryChartPointSchema[]>(`/inventory/${runId}/stock`, payload);
        setRawData(data);
      } catch (e) {
        push({type: 'error', title: intl.formatMessage({id: 'server_error'})})
        throw e;
      } finally {
        setLoading(false);
      }
    })();
  }, [intl, push, runId, JSON.stringify(payload)]) // eslint-disable-line react-hooks/exhaustive-deps

  return {data, loading};
}