import React from "react";
import {useIntl} from "react-intl";
import Alert from "@ui-components/Alert";
import Placeholder from "@ui-components/Placeholder";
import {CheckCustomOrderPropsType} from "../types";
import CustomOrder from "./custom-order";
import InputUmQuantity from "./input-um-quantity";
import {useInventoryGetCustomSelection} from "@feature/inventory/replenishment/hooks";

const CheckCustomOrder = ({
	                          data,
	                          setData,
	                          disabled,
                          }: CheckCustomOrderPropsType) => {
	
	const intl = useIntl();
	
	const {customSelection, loading} = useInventoryGetCustomSelection(
		{
			cod_item: data.cod_item!,
			dat_order: data.dat_order!,
			cod_dc: data.cod_dc!
		},
		setData
	);
	
	if (!data.cod_item || !data.dat_order || !data.cod_dc)
		return null;
	
	const umDimension = "um_multiples";
	const umValDimension = "num_multiples";
	const leadtimeDimension = "num_lead_time";
	const dimensionName = "val_quantity_multiples";
	
	let selectionRender: React.ReactElement | null;
	
	switch (customSelection) {
		case 1 :
			selectionRender = (
				<CustomOrder
					data={data}
					setData={setData}
					dimensionName={dimensionName}
					disabled={disabled}
					umDimension={umDimension}
					umValDimension={umValDimension}
					leadtimeDimension={leadtimeDimension}
				/>
			)
			break;
		case 2:
			selectionRender = (
				<InputUmQuantity data={data}
				                 setData={setData}
				                 dimensionName={dimensionName}
				                 disabled={disabled}
				                 umDimension={umDimension}
				                 umValDimension={umValDimension}/>
			)
			break;
		case 3:
			selectionRender = (
				<Alert
					title={intl.formatMessage({id: "error"})}
					text={intl.formatMessage({id: "add_row_alert"})}
					type="error"
					slim
					onConfirm={undefined}
					onDismiss={undefined}/>
			)
			break;
		default:
			selectionRender = null
	}
	
	return loading ? <Placeholder classNames="h-20 w-full"/> : selectionRender
};

export default CheckCustomOrder;
