import {useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {api} from "@services/apiRequest";
import {NotificationsContext} from "@ui-components/Notifications";
import {DemandForecastingRequestSchema, DemandForecastingResponseSchema} from "../types";
import {useAsyncError} from "@hooks/useAsyncError";

export const useDemandChartData = (
  runId: number,
  payload: DemandForecastingRequestSchema | null
) => {

  // hooks
  const intl = useIntl();

  // context
  const {push} = useContext(NotificationsContext);
  const throwError = useAsyncError();

  // state
  const [data, setRawData] = useState<DemandForecastingResponseSchema[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!payload || !payload.division?.length)
      return;

    (
      async () => {
        setLoading(true);
        try {
          const {data} = await api.post<DemandForecastingResponseSchema[]>(`/demand/${runId}`, payload);
          setRawData(data);
        } catch (e) {
          push({type: 'error', title: intl.formatMessage({id: 'server_error'})})
          throwError(e);
        } finally {
          setLoading(false);
        }
      }
    )();
  }, [intl, JSON.stringify(payload), push, runId, throwError]) // eslint-disable-line react-hooks/exhaustive-deps

  return {data, loading};
}