import {useContext, useState} from "react";
import {NotificationsContext} from "@ui-components/Notifications";
import type {Query} from "@material-table/core";
import {api} from "@services/apiRequest";
import {MTColumnsType} from "@utils/pagination";
import {useAsyncError} from "@hooks/useAsyncError";
import {fetchPagination} from "../../../commons/utils";
import {useIntl} from "react-intl";
import {ItemAssociationPath, ItemAssociationReturnType, ItemAssociationSchema} from "@feature/entry/types";


export const useEntryProducts = <T extends ItemAssociationSchema>(
  path: ItemAssociationPath,
  columns: MTColumnsType<T>,
  flgItemToAssociate: boolean,
): ItemAssociationReturnType<T> => {

  // hooks
  const {push} = useContext(NotificationsContext);
  const throwError = useAsyncError();
  const intl = useIntl();

  // state
  const [loading, setLoading] = useState<boolean>(false);

  // utilities
  const baseUrl = `/entry/${path}`;

  const fetch = async (query: Query<T>) => fetchPagination(baseUrl, query, columns, undefined, {flg_item_to_associate: flgItemToAssociate}, push, setLoading, throwError)

  const rowEdit = (newData: T, oldData: T | undefined) =>
    new Promise((resolve, reject) => {
      // check if row content is equal - if so, don't do anything
      const isEqual = !oldData
        ? false
        : Object.keys(newData).every((k) => {
          const key = k as keyof T;
          return oldData[key] === newData[key];
        });

      setImmediate(async () => {
        if (isEqual) {
          resolve(newData);
        } else {
          try {
            await api.put(baseUrl + '/update_association', newData);
            push({type: "success", title: intl.formatMessage({id: 'success'})});
            resolve(newData);
          } catch (e) {
            push({type: "error", title: intl.formatMessage({id: 'generic_error'}), text: ''});
            reject();
            throwError(e);
          }
        }
      });
    });

  return {fetch, rowEdit, loading};
}

