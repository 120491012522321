import {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {api} from "@services/apiRequest";
import {NotificationsContext} from "@ui-components/Notifications";
import {useAsyncError} from "@hooks/useAsyncError";
import {
  AvailableItemsAdditionalInfo,
  CustomSelection,
  CustomSelectionSchema,
  InventoryReplenishmentExtendedSchema
} from "../types";
import {DataContext} from "@services/DataContext";
import {InventoryContextSchema} from "@feature/inventory";

export const useInventoryGetCustomSelection = (
  partialInfo: Pick<InventoryReplenishmentExtendedSchema, 'dat_order' | 'cod_dc' | 'cod_item'>,
  setData: Dispatch<SetStateAction<Partial<InventoryReplenishmentExtendedSchema>>>
) => {
  const [customSelection, setCustomSelection] = useState<0 | CustomSelection>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const {inventoryCurrentRun} = useContext(DataContext) as InventoryContextSchema;

  const {push} = useContext(NotificationsContext);
  const throwError = useAsyncError();
  const intl = useIntl();

  useEffect(() => {

    // gate to prevent useless requests
    if (!Object.values(partialInfo).every(value => value))
      return;

    const params = new URLSearchParams(partialInfo);

    (async () => {
      setLoading(true);
      try {
        const {data} = await api.get<CustomSelectionSchema>(`/inventory/custom-selection/${inventoryCurrentRun?.id_run}?${params.toString()}`);
        setCustomSelection(data.selection);
        setData(prev => {

          const additionalInfo: AvailableItemsAdditionalInfo = data.items_info ?? {}

          if (additionalInfo?.dat_delivery) {
            additionalInfo["new_dat_delivery"] = additionalInfo.dat_delivery;
            delete additionalInfo.dat_delivery;
          } else
            additionalInfo['dat_delivery'] = prev.dat_delivery;

          return {...prev, ...additionalInfo}
        });
      } catch (e) {
        push({title: intl.formatMessage({id: 'generic_error'}), type: "error"});
        throwError(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [JSON.stringify(partialInfo), push, throwError, intl]); // eslint-disable-line react-hooks/exhaustive-deps

  return {customSelection, loading};

}