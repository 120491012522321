import {Dispatch, SetStateAction} from "react";
import {api} from "@services/apiRequest";
import {GroupedOrderType} from "@feature/orders";
import {IntlShape} from "react-intl";

export const handleSendOrders = (
	grouped_orders: GroupedOrderType[],
	setRefetchOrders: Dispatch<SetStateAction<boolean>>,
	setLoading: Dispatch<SetStateAction<boolean>>,
	notificationPush:  (notification: { type: "success" | "error", title: string }) => void,
	intl: IntlShape
) => {
	setLoading(true);
	api.post('orders/sendSAP').then(() => setRefetchOrders(p => !p))
		.catch(e =>{
			 console.error(e);
			 notificationPush({type: 'error', title: intl.formatMessage({id: 'generic_error'})});
		})
		.finally(() => setLoading(false));
}

export const getFilterLocalization = (translation_key: string, intl: IntlShape) => intl.formatMessage(
	{id: 'filter_by'},
	{
		target: intl.formatMessage({id: translation_key})
	}
);