import React from "react";
import {IntlShape} from "react-intl";
import {OrderStatus, OrderType} from "@feature/orders";
import {StatusBadge} from "@feature/orders/status-badge";
import {CustomEditDatePicker, CustomFilterDatePicker} from "@components/Tables/Params/CustomDatePicker";
import {MTColumnsEditType} from "@feature/entry";
import MaterialTableCustomCell from "@ui-components/MaterialTableCustomCell";
import {valuesInclude} from "@utils/index";
import Tooltip from "@ui-components/Tooltip";

const getStatusLocalization = (intl: IntlShape) =>
	Object.values(OrderStatus).reduce((acc, curr) => {
		// add only allowed status: HOLD, WIP, ERROR, SENT
		if (![OrderStatus.WIP, OrderStatus.ERROR, OrderStatus.SENT].includes(curr))
			return acc
		acc[curr] = intl.formatMessage({id: curr})
		return acc
	}, {} as Record<OrderStatus, string>)

type T = OrderType;
export const getColumns = (intl: IntlShape): MTColumnsEditType<T> => [
	{
		title: intl.formatMessage({id: 'status'}),
		headerStyle: {textAlign: 'center'},
		lookup: getStatusLocalization(intl),
		field: 'status',
		render: rowData => <StatusBadge status={rowData.status}/>,
		cellStyle: {minWidth: 180}
	},
	{
		title: intl.formatMessage({id: 'cod_order'}),
		headerStyle: {textAlign: 'center'},
		field: 'cod_order',
		cellStyle: {textAlign: 'center'}
	},
	{
		title: intl.formatMessage({id: 'Divisione'}),
		headerStyle: {textAlign: 'center'},
		field: 'cod_division',
		cellStyle: {textAlign: 'center'}
	},
	{
		title: 'MRP',
		headerStyle: {textAlign: 'center'},
		field: 'cod_reorder_accountant',
		cellStyle: {textAlign: 'center'}
	},
	{
		title: intl.formatMessage({id: 'Fornitore'}),
		field: 'business_name',
		cellStyle: {textAlign: 'center'},
		render: rowData => <MaterialTableCustomCell vertical data1={rowData.cod_supplier} data2={rowData.business_name}/>,
		customFilterAndSearch: (term, rowData) => valuesInclude(rowData.business_name, rowData.cod_supplier, term)
	},
	{
		title: intl.formatMessage({id: 'address'}),
		field: 'des_purchase_address',
		cellStyle: {textAlign: 'center'},
		render: rowData => <MaterialTableCustomCell vertical data1={rowData.cod_purchase_address}
		                                            data2={rowData.des_purchase_address}/>,
		customFilterAndSearch: (term, rowData) => valuesInclude(rowData.cod_purchase_address, rowData.des_purchase_address, term)
	},
	{
		title: intl.formatMessage({id: 'Materiale'}),
		field: 'des_item',
		cellStyle: {textAlign: 'center'},
		render: rowData => <MaterialTableCustomCell vertical data1={rowData.cod_item} data2={rowData.des_item}/>,
		customFilterAndSearch: (term, rowData) => valuesInclude(rowData.des_item, rowData.cod_item, term)
	},
	{
		title: intl.formatMessage({id: 'quantity_reordered'}),
		field: 'current_val_quantity_multiples',
		type: 'numeric',
		cellStyle: {textAlign: 'center'},
		render: rowData => `${rowData.current_val_quantity_multiples} ${rowData.um_multiples}`
	},
	{
		title: intl.formatMessage({id: 'current_val_quantity_purchase_order'}),
		field: 'current_val_quantity_purchase_order',
		type: 'numeric',
		cellStyle: {textAlign: 'center'},
		render: rowData => `${rowData.current_val_quantity_purchase_order} ${rowData.um_purchase_order}`
	},
	{
		title: intl.formatMessage({id: 'on_sale'}),
		field: 'flg_order_on_sale',
		type: 'boolean',
	},
	{
		title: intl.formatMessage({id: 'dat_order'}),
		field: 'dat_order',
		headerStyle: {textAlign: 'center'},
		type: 'date',
		hidden: true, // hidden for CAMST
		editComponent: props => CustomEditDatePicker(props),
		filterComponent: props => CustomFilterDatePicker(props),
		render: rowData => new Intl.DateTimeFormat('it-IT').format(new Date(rowData.dat_order))
	},
  /* specific for CAMST */
  {
	 title: <>{intl.formatMessage({id: 'dat_order_send'})} <Tooltip placement="bottom" text={intl.formatMessage({id: "order_last_status_update_tooltip"})}/></>,
    field: "tms_last_status_update",
    headerStyle: { textAlign: "center" },
    type: "datetime",
    defaultSort: "desc",
    cellStyle: {
      minWidth: 200,
      maxWidth: 200,
    },
    filterComponent: (props) => CustomFilterDatePicker(props),
    customFilterAndSearch: (term, rowData) => {
      return term
        ? new Date(term).toLocaleDateString() ===
            new Date(rowData.tms_last_status_update).toLocaleDateString()
        : true;
    },
    render: (rowData) =>
      rowData.tms_last_status_update
        ? new Intl.DateTimeFormat("it-IT", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }).format(new Date(rowData.tms_last_status_update))
        : "",
  },
	{
		title: intl.formatMessage({id: 'delivery_date'}),
		headerStyle: {textAlign: 'center'},
		field: 'dat_delivery',
		type: 'date',
		render: rowData => new Intl.DateTimeFormat('it-IT').format(new Date(rowData.current_dat_delivery)),
		editComponent: props => CustomEditDatePicker(props),
		filterComponent: props => CustomFilterDatePicker(props),
	},
]