import {api} from "@services/apiRequest";
import {OptionSchema} from "@feature/commons";
import {TypeEnum} from "../../types";

export const loadOptionsAsync = (inputValue: string, type_: TypeEnum, cod_dc?: string) => new Promise<OptionSchema[]>((resolve) => {
	// adding timeout to avoid firing too many requests
	setTimeout(
		() => api.post<OptionSchema[]>("/inventory/available_items_options", {
			search_keyword: inputValue,
			type_: type_,
			cod_dc: cod_dc
		})
			.then(r => resolve(r.data))
			.catch((e) => {
				console.error(e);
				resolve([]);
			}), 1500
	)
})