import {Dispatch, SetStateAction} from "react";
import {CommonContextSchema, OptionSchema} from "@feature/commons";

export enum OrderStatus {
	CART = 'CART',
	SENT = 'SENT',
	CONFIRMED = 'CONFIRMED',
	COMPLETED = 'COMPLETED',
	LATE = 'LATE',
	ERROR = 'ERROR',
	WIP = 'WIP',
	HOLD = 'HOLD',
}

interface OrderBase {
	/** Cod Supplier */
	cod_supplier: string
	/** Business Name */
	business_name: string
	/** Cod Purchase Address */
	cod_purchase_address: string
	/** Des Purchase Address */
	des_purchase_address?: string
	/**
	 * Current Dat Order
	 * @format date
	 */
	current_dat_order: string
	/**
	 * Current Dat Delivery
	 * @format date
	 */
	current_dat_delivery: string
	/**
	 * Dat Delivery
	 * @format date
	 */
	dat_delivery: string
}

interface CamstFieldsType {
	cod_division: string
	des_division: string
	cod_reorder_accountant?: string
	flg_order_on_sale: boolean
}

interface ItemSpecsType extends CamstFieldsType {
	/** Cod DC */
	cod_dc: string
	/** Cod Item */
	cod_item: string
	/** Des Item */
	des_item: string
	/** Um Multiples */
	um_multiples: string
	/** Um Purchase Order */
	um_purchase_order: string
	/** Current Val Quantity Multiples */
	current_val_quantity_multiples: number
	/** Current Val Quantity Purchase Order */
	current_val_quantity_purchase_order: number
	/** Active */
	active: boolean
}

export interface OrderType extends OrderBase, ItemSpecsType, CamstFieldsType {
	/**
	 * Dat Order
	 * @format date
	 */
	dat_order: string
	/**
	 * Dat Delivery
	 * @format date
	 */
	dat_delivery: string
	/** Val Quantity Multiples */
	val_quantity_multiples: number
	/** Val Quantity Purchase Order */
	val_quantity_purchase_order: number
	/** Des Division */
	des_division: string
	/** Status */
	status: OrderStatus
	/**
	 * Tms Sent
	 * @format date
	 */
	tms_sent: string
	/**
	 * Tms Confirmation
	 * @format date
	 */
	tms_confirmation?: string
	/**
	 * Tms Completion
	 * @format date
	 */
	tms_completion?: string
	/**
	 * Tms Last Status Update
	 * @format date
	 */
	tms_last_status_update: string
	/** Cod Order */
	cod_order?: string
}

export interface GroupedOrderType extends OrderBase {
	items: Array<ItemSpecsType>
}

export interface EditCardModalProps {
	order?: GroupedOrderType
	setOrder: Dispatch<SetStateAction<GroupedOrderType | undefined>>
}

export interface OrdersContextSchema extends CommonContextSchema {
	setRefetchOrders: Dispatch<SetStateAction<boolean>>
}

export interface OrderFilterType {
	item?: OptionSchema[];
	supplier?: OptionSchema[];
	purchase_address?: OptionSchema[];
	division?: OptionSchema[];
	reorder_accountant?: OptionSchema[];
	dat_delivery?: Date;
}