import { useContext, useEffect, useState } from "react";
import { HomeDatesSchema } from "../types";
import { api } from "@services/apiRequest";
import { useIntl } from "react-intl";
import { useAsyncError } from "@hooks/useAsyncError";
import { NotificationsContext } from "@ui-components/Notifications";

export const useDates = () => {

    // context
    const { push } = useContext(NotificationsContext);

    // hooks
    const intl = useIntl();
    const throwError = useAsyncError();

    // state
    const [dateRange, setDateRange] = useState<HomeDatesSchema>();
    const [dateRangeLoading, setDateRangeLoading] = useState(false);

    useEffect(() => {
        (async () => {
            setDateRangeLoading(true);
            try {
                const { data } = await api.get<HomeDatesSchema>('/home/dates_filter');
                setDateRange(data);
            } catch (e) {
                push({ type: 'error', title: intl.formatMessage({ id: 'generic_error' }) });
                throwError(e);
            } finally {
                setDateRangeLoading(false);
            }
        })();
    }, [intl, push, throwError]);

    return { dateRange, dateRangeLoading };
}