import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5percent from "@amcharts/amcharts5/percent";
import {DEFAULT_CHART_COLORS} from "../../theme";

export const addLicense = () => {
  am5.addLicense("AM5C328990290");
}


export const setChartColor = (item, colors) => {
  item.get("colors").set("colors", colors);
}

const defaultZoomSettings = {
  panX: true,
  panY: true,
  wheelX: "panX",
  wheelY: "zoomX"
}
export const getXYChart = (root, chartSettings, colors = DEFAULT_CHART_COLORS) => {

  let chart = root.container.children.push(am5xy.XYChart.new(
      root,
      chartSettings ?? defaultZoomSettings
    )
  );

  setChartColor(chart, colors);
  return chart
}

export const getPieChart = (root) => {
  return root.container.children.push(am5percent.PieChart.new(root, {
    layout: root.horizontalLayout,
    innerRadius: am5.percent(50)
  }))
}


export const getRenderAxisX = (root, grid = true) => {
  let xRenderer = am5xy.AxisRendererX.new(root, {});
  if (!grid) {
    xRenderer.grid.template.set("forceHidden", true);
  }
  xRenderer.labels.template.setAll({
    minPosition: 0.05,
    maxPosition: 0.95
  })
  return xRenderer
}

export const getRenderAxisY = (root, grid = true, inversed = false) => {
  let yRenderer = am5xy.AxisRendererY.new(root, {
    inversed: inversed
  })
  if (!grid) {
    yRenderer.grid.template.set("forceHidden", true);
  }
  yRenderer.labels.template.setAll({
    minPosition: 0.05
  })
  return yRenderer
}

export const addCursor = (root, chart, x = true, y = false) => {
  let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
  cursor.lineX.set("visible", x);
  cursor.lineY.set("visible", y);
}

export const getTooltip = (root, text) => {
  let tooltip = am5.Tooltip.new(root, {
    getFillFromSprite: false,
    autoTextColor: false,
    labelText: text
  });

  tooltip.get("background").setAll({
    fill: am5.color("#bfbfc1"),
    fillOpacity: 0.8
  });

  tooltip.label.setAll({
    fill: am5.color("rgb(0, 60, 80)")
  });

  return tooltip
}

export const addSeriesBullet = (root, series, radius = 4, strokeWidth = 2) =>
  series.bullets.push(function () {
    const circle = am5.Circle.new(root, {
      radius: radius,
      fill: series.get("fill"),
      stroke: root.interfaceColors.get("background"),
      strokeWidth: strokeWidth
    });

    return am5.Bullet.new(root, {
      sprite: circle
    });
  })