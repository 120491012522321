import React from "react";
import {useInventoryAlertCounts} from "@feature/inventory";
import {AlertCountsSchema, InventoryAlertsDashboardProps} from "../types";
import {ALERTS_INFO} from "./lib/constants";
import {AlertButton} from "../alert-button";

export const AlertsDashboard = ({runId, divisions}: InventoryAlertsDashboardProps) => {

  // hooks
  const { counts, loading } = useInventoryAlertCounts(runId);

  return (
    <div className="flex flex-col items-center justify-center gap-y-4">
      <h3>Alert</h3>
      <div className="flex justify-evenly">
        {Object.entries(ALERTS_INFO).map(([name, icon]) => {
          const c = counts?.[name as keyof AlertCountsSchema] ?? null;
          return <AlertButton
            key={name}
            runId={runId}
            divisions={divisions}
            name={name}
            counts={c}
            loading={loading}
            icon={icon}
          />
        })}
      </div>
    </div>
  )
}