import {
  BuildingStorefrontIcon,
  CheckBadgeIcon,
  CubeIcon,
  CubeTransparentIcon,
  CurrencyEuroIcon,
  ExclamationCircleIcon,
  FaceFrownIcon,
  InboxStackIcon,
  ScaleIcon,
  TrashIcon
} from "@heroicons/react/24/outline";

const Icon = ({iconName, className = "", ...rest}) => {

  const defaultClassName = className || "h-5 w-5 text-am-600"

  switch (iconName) {
    case 'CubeTransparentIcon':
      return <CubeTransparentIcon className={defaultClassName} {...rest}/>;
    case 'BuildingStorefrontIcon':
      return <BuildingStorefrontIcon className={defaultClassName} {...rest}/>;
    case 'CheckBadgeIcon':
      return <CheckBadgeIcon className={defaultClassName} {...rest}/>;
    case  'TrashIcon':
      return <TrashIcon className="h-5 w-5 text-white" {...rest}/>;
    case 'ExclamationCircleIcon':
      return <ExclamationCircleIcon className="h-5 w-5 text-white" {...rest}/>
    case 'InboxStackIcon':
      return <InboxStackIcon className="h-5 w-5 text-white p-0.5" {...rest}/>
    case 'FaceFrownIcon':
      return <FaceFrownIcon className="h-5 w-5 text-white p-0.5" {...rest}/>
    case 'CubeIcon':
      return <CubeIcon className={defaultClassName} {...rest}/>
    case 'ScaleIcon':
      return <ScaleIcon className={defaultClassName} {...rest}/>
    case 'CurrencyEuroIcon':
      return <CurrencyEuroIcon className={defaultClassName} {...rest}/>
    default:
      throw new Error(`${iconName} not included in Icon.js`)
  }
};

export default Icon;
