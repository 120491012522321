import React from "react";
import {BoxTitleProps} from "@feature/entry";
import Tooltip from "@ui-components/Tooltip";
import {BasicCheckBox} from "@feature/entry/palletization/generic";

export const Box = ({
	                    title,
	                    enableTooltip = false,
	                    enableCheckBox = false,
	                    checkBoxValue = false,
	                    checkBoxOnChange,
	                    checkBoxLabel = "",
	                    tooltipMessage = "",
	                    tooltipPosition = "bottom",
	                    classNames = "",
	                    disabled = false,
	                    children,
                    }: BoxTitleProps) => (
	<div className={`flex flex-col items-center justify-evenly w-full h-auto relative ${classNames}`}>
		<div className="flex items-center justify-center gap-2 mb-2">
			<h2 className="font-bold">{title}</h2>
			{enableTooltip && <Tooltip text={tooltipMessage} placement={tooltipPosition} customStyle="mt-1"/>}
			{
				enableCheckBox && (
					<BasicCheckBox
						label={checkBoxLabel}
						value={checkBoxValue}
						onChange={newValue => {
							if (checkBoxOnChange)
								checkBoxOnChange(newValue)
						}}/>
				)
			}
		</div>
		{/* mask children when disabled */}
		{disabled && <div className="absolute inset-0 bg-gray-500 bg-opacity-10 rounded-md z-10"/>}
		{children}
	</div>
)