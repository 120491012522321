import React from "react";

const Placeholder = ({ classNames = "", width = "w-full", height = "h-5", marginCls = "mb-2", ...rest }) => {
  return (
    <div
      data-placeholder
      className={`${marginCls} rounded-3xl overflow-hidden relative bg-gray-200 ${height} ${width} ${classNames}`}
      {...rest}
    />
  );
};

export default Placeholder;
