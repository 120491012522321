import React, {useEffect, useMemo, useRef, useState} from "react";
import {useIntl} from "react-intl";
import MaterialTable from "@material-table/core";
import {PencilIcon} from "@heroicons/react/24/outline";
import {tableIcons} from "@ui-components/TableIcons";
import TableStyle from "@ui-components/TableStyle";
import {ItemAssociationDetailSchema, ItemAssociationDetailTableProps, useEntryProducts} from "@feature/entry";
import {ItemAssociationDialog} from "../item-association-dialog/products-edit-dialog";
import {getColumns, OPTIONS} from "./params";
import {ICONSTYLE} from "@feature/entry/lib/constants";
import {getCommonProps} from "@feature/commons";

type T = ItemAssociationDetailSchema;

export const ItemAssociationTable = (props: ItemAssociationDetailTableProps) => {
	
	// hooks
	const intl = useIntl();
	const {
		fetch,
		loading
	} = useEntryProducts<T>('item_association', getColumns(intl), props.flgItemToAssociate);
	
	// state
	const [refreshTrigger, setRefreshTrigger] = useState<number>(0);
	const [showEditTableModal, setShowEditTableModal] = useState<boolean>(false);
	const [rowToEdit, setRowToEdit] = useState<ItemAssociationDetailSchema | null>(null);
	// const [showEditTableModal, setShowEditTableModal] = useState<ItemAssociationDetailSchema | null>(null);
	
	// refs
	const ref = useRef<MaterialTable<T>>();
	const didMount = useRef(false);
	
	
	// utilities
	const title = intl.formatMessage({id: "item_association"});
	const columns = useMemo(() => getColumns(intl), [intl]);  // avoid filter reset on rerender
	const refresh = () => {
		setRefreshTrigger((oldTrigger: number) => oldTrigger + 1);
	};
	
	const commonProps = getCommonProps<T>(intl, OPTIONS);
	
	useEffect(() => {
		if (didMount.current) refresh();
		else didMount.current = true;
	}, [props.flgItemToAssociate]);
	
	return <>
		<TableStyle>
			<MaterialTable
				key={refreshTrigger}
				{...commonProps}
				tableRef={ref}
				data={fetch}
				columns={columns}
				title={title}
				isLoading={loading}
				actions={[
					rowData => ({
						icon: () => {
							if (rowData['replaces'].length === 0 && !rowData['replaced_by'])
								return <PencilIcon className={ICONSTYLE}/>
							else
								return <tableIcons.Edit/>
						},
						tooltip: 'Modifica',
						onClick: (_, data: (ItemAssociationDetailSchema | ItemAssociationDetailSchema[])) => {
							setShowEditTableModal(true);
							setRowToEdit(Array.isArray(data) ? data[0] : data);
						},
						isFreeAction: false,
					}),
				]}
				{...props}
			/>
		</TableStyle>
		{
			showEditTableModal &&
      <ItemAssociationDialog
        open={showEditTableModal}
        toggleOpen={() => setShowEditTableModal(false)}
        rowData={rowToEdit}
        refresh={refresh}
      />
		}
	</>
}