import {DemandTarget, DimensionRadiosType} from "@feature/demand";
import {IntlShape} from "react-intl";

export const DEMAND_TARGETS: DemandTarget[] = ['division', 'detail_level', 'area', 'supplier', 'product', 'warehouse'];
export const DELTA_WEEKS = 4;
export const getDimensionRadios = (intl: IntlShape): DimensionRadiosType[] => [
	{
		name: intl.formatMessage({id: 'Cumulato'}),
		min_value: 9999,
		dimension: 'cumulative',
	},
	{
		name: intl.formatMessage({id: 'Divisione'}),
		min_value: 9999,
		dimension: 'division',
	},
	{
		name: intl.formatMessage({id: 'warehouse'}),
		min_value: 5,
		dimension: 'warehouse',
	},
	{
		name: intl.formatMessage({id: 'Famiglia'}),
		min_value: 5,
		dimension: 'area',
	},
	{
		name: intl.formatMessage({id: 'Fornitore'}),
		min_value: 5,
		dimension: 'supplier',
	},
	{
		name: intl.formatMessage({id: 'Gruppo_merce'}),
		min_value: 5,
		dimension: 'product',
	},
];
export const DETAIL_LEVELS = [
	{value: 'daily', label: "Giornaliero"},
	{value: 'weekly', label: "Settimanale"}
]