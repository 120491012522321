import {BasicInput, Box, UmChoice} from "@feature/entry/palletization/generic";
import {ENTRY_UM} from "@feature/entry/lib/constants";
import React from "react";
import {PalletizationItemSchema, PalletizationOrderSchema, RangeBoxProps} from "@feature/entry";
import {useIntl} from "react-intl";

const isNotEmpty = (value: any) => {
  return ![undefined, null].includes(value);
}

export function RangeBox<T extends PalletizationOrderSchema | PalletizationItemSchema>(
  {
    title,
    tooltipMessage,
    tooltipPosition,
    constraints,
    minValField,
    maxValField,
    umField,
    enableConstraintField,
    customDisabled,
    updateCallback,
    classNames,
  }: RangeBoxProps<T>) {

  const intl = useIntl();

  const showRange = enableConstraintField !== undefined ? !!enableConstraintField && !!constraints[enableConstraintField] : true;

  return (
    <Box
      classNames={classNames}
      title={title}
      enableTooltip={!!tooltipMessage}
      tooltipMessage={tooltipMessage}
      tooltipPosition={tooltipPosition}
      checkBoxLabel={intl.formatMessage({id: 'active'})}
      enableCheckBox={!!enableConstraintField}
      disabled={customDisabled}
      checkBoxValue={enableConstraintField && constraints[enableConstraintField] as boolean}
      checkBoxOnChange={newValue => updateCallback(enableConstraintField!, newValue)}>
      {
        showRange && (
          <div className="flex justify-around items-center gap-x-5">
            <BasicInput
              value={constraints[minValField] as number | undefined}
              onChange={newValue => updateCallback(minValField, newValue)}
              label="Min"
              isValid={isNotEmpty(constraints[minValField]) && isNotEmpty(constraints[maxValField]) ? constraints[minValField] <= constraints[maxValField] : true}
              errorMessage={intl.formatMessage({id: 'value_range_error'})}/>
            <BasicInput
              value={constraints[maxValField] as number | undefined}
              onChange={newValue => updateCallback(maxValField, newValue)}
              label="Max"
              isValid={umField && (isNotEmpty(constraints[minValField]) || isNotEmpty(constraints[maxValField])) ? !!constraints[umField] : true}
              errorMessage={intl.formatMessage({id: 'um_error'})}/>
            {
              umField && (
                <UmChoice
                  value={constraints[umField] as string | undefined ?? '-'}
                  onChange={newValue => updateCallback(umField, newValue)}
                  options={ENTRY_UM}/>
              )
            }
          </div>
        )
      }
    </Box>
  )

}