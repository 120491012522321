import React, { useCallback, useContext, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
// Services

import Input from "../../Input";
import Modal from "../../Modal";

import Button from "../../Button";
import { NotificationsContext } from "../../Notifications";
import Placeholder from "../../Placeholder";
import {api} from "@services/apiRequest";
import usePolicies from "./Hooks/usePolicies";
import SelectPolicies from "./SelectPolicies";

function AddRoleDialog({ open, toggleOpen, roles, setRoles }) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const policies = usePolicies();
  const [scopes, setScopes] = useState([]);
  const { push } = useContext(NotificationsContext);

  const _onSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();

        if (!name) {
          return;
        }

        setLoading(true);
        const {data} = await api.post("/roles", {
          name: name.toLocaleLowerCase(),
          description,
          scopes: scopes.join(";"),
        });
        setRoles({ ...roles, [data.id]: data });
        toggleOpen();
        push({
          title: intl.formatMessage({ id: "role_created_successfully" }),
          type: "success",
        });
      } catch (error) {
        push({
          title: intl.formatMessage({ id: "role_already_used" }),
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [name, setRoles, toggleOpen, roles, description, scopes, push, intl]
  );

  return (
    <div>
      <Modal opened={open} onExit={toggleOpen}>
        <div className="m-8">
          <h3 className="text-center text-2xl font-extrabold text-gray-900">
            <FormattedMessage id="add_role" />
          </h3>
        </div>

        <form id="add-role-form" onSubmit={_onSubmit}>
          <div>
            <Input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              label={intl.formatMessage({ id: "role_name" })}
              type="text"
              errorMessage={intl.formatMessage({ id: "add_role_name" })}
              required
              data-cy="role_name"
            />
          </div>
          <div>
            <Input
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              label={intl.formatMessage({ id: "description" })}
              type="text"
              data-cy="role_description"
            />
          </div>

          <div className="mb-6">
            {policies ? (
              <SelectPolicies
                policies={policies}
                scopes={scopes}
                setScopes={setScopes}
              />
            ) : (
              <div>
                <Placeholder width="w-4/5" />
                <Placeholder width="w-3/5" />
              </div>
            )}
          </div>

          <div className="mt-4 flex items-end justify-end">
            <Button
              submitting={loading}
              type="submit"
              data-cy="add_role_modal_button"
            >
              <FormattedMessage id="add" />
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default AddRoleDialog;
