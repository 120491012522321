import Icon from "@ui-components/Icon";
import {FILTERICONS} from "../lib/consts";
import {HomeFilterDimensions, MultiDimensionFilterPropsType} from "@feature/home";

export const MultiDimensionFilter = ({toggledIndex, setToggledIndex}: MultiDimensionFilterPropsType) => {
	
	return (
		<div className="flex justify-center gap-x-6 p-1.5 mx-auto rounded-3xl border border-am-600">
			{FILTERICONS.map(({icon, value}: { icon: string, value: HomeFilterDimensions }) =>
				<Icon key={icon} iconName={icon}
				      className={`h-7 w-7 p-0.5 transform transition border-2 border-am-200 rounded-full duration-500
              cursor-pointer ${value === toggledIndex ? 'bg-am-600 text-white scale-125' : 'text-am-600'}`}
				      onClick={() => setToggledIndex(value)}
				/>)}
		</div>
	)
}