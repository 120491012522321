import DatePicker from "../../../ui-components/DatePicker";
import {CALENDAR_MODE} from "../../../utils/constants";
import {useState} from "react";

const TableDatePicker = ({
  tableProps: {onFilterChanged, columnDef: {tableData}},
  mode = CALENDAR_MODE.DAILY,
  ...rest
}) => {

  // state
  const [state, setState] = useState(rest.value);

  // utilities
  const changeState = (value) => {
    onFilterChanged(tableData.id, value);
    setState(value);
  }

  return (
    <DatePicker
      mode={mode}
      onChange={changeState}
      onClear={() => { changeState(null); }}
      value={state}
      materialTable
      {...rest}
    />
  )
}

export default TableDatePicker;