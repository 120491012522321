import {useIntl} from "react-intl";
import Alert from "@ui-components/Alert";
import DatePicker from "@ui-components/DatePicker";
import {dateToString} from "@utils/index";
import {ModifyOrderPropsType} from "../types";


const ModifyOrder = ({data, setData}: ModifyOrderPropsType) => {

  const intl = useIntl();

  return (

    <div className="flex flex-col items-center gap-y-5">
      <Alert
        title={intl.formatMessage({id: "warning"})}
        text={intl.formatMessage({id: "add_row_warning"})}
        type="warning"
        onConfirm={undefined}
        onDismiss={undefined}
        slim={false}
      />
      <DatePicker
        classNames="w-36"
        labelClassNames="font-bold text-sm"
        value={data.new_dat_delivery ? new Date(data.new_dat_delivery) : new Date()}
        onChange={(d: Date) => setData({...data, new_dat_delivery: dateToString(d)})}
        label={intl.formatMessage({id: "add_row_dat_delivery"})}
        placeholder={intl.formatMessage({id: "add_row_dat_delivery"})}
        minDate={undefined}/>
      <Alert
        title="Info"
        text={intl.formatMessage({id: "add_row_info"}, {leadtime: data.num_lead_time!})}
        type="info"
        onConfirm={undefined}
        onDismiss={undefined}
        slim={false}
      />
    </div>

  );
}


export default ModifyOrder;