import React, {useLayoutEffect} from "react";
import {addCursor, addLicense, getXYChart} from "@components/Charts/commons";
import * as am5 from "@amcharts/amcharts5";
import am5locales_it_IT from "@amcharts/amcharts5/locales/it_IT";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import Spinner from "@ui-components/Spinner";
import {createSeries, setupXAxis, setupYAxis} from "./lib/utilities";
import Placeholder from "@ui-components/Placeholder";
import {FormattedMessage} from "react-intl";

import {StockBarchartProps} from "@feature/home";

export const StockBarchart = ({
  id,
  data,
  loading,
  skipSeries,
  currentDimension,
}: StockBarchartProps) => {
  
  useLayoutEffect(() => {

    if (!data?.length) return;

    addLicense();

    // init amcharts root
    const root = am5.Root.new(id);
    root.locale = am5locales_it_IT;
    root.setThemes([am5themes_Animated.new(root)])

    // create chart
    const chart = getXYChart(root, {
      // @ts-ignore
      layout: root.verticalLayout,
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 16,  // needed in order to fully show x-axis tooltip
      paddingLeft: 20,  // needed in order to fully show y-axis tooltip
    }) as am5xy.XYChart;
    addCursor(root, chart);

    // add axes
    // -- y axis (category)
    const yAxis = setupYAxis(root, chart, 'description', currentDimension);
    yAxis.data.setAll(data);
    // -- x axis (value)
    const xAxis = setupXAxis(root, chart, data);

    // create series
    (["in_", "out"] as ("in_" | "out")[]).forEach((target) => {
      if (skipSeries && skipSeries.includes(target))
        return;

      const name: string = target === "in_" ? "In" : "Out";
      createSeries(root, chart, name, target, "description", xAxis, yAxis);
    });

    // add series data and show
    chart.series.each((s) => {
      s.data.setAll(data);
      s.appear(1500).then();
    })

    // add cursor's zoom behavior
    chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "zoomX"
    }));

    return () => {
      root.dispose();
    };
  }, [id, data]); // eslint-disable-line react-hooks/exhaustive-deps
  
  if (!data)
    return <Placeholder width="w-full" height="h-full" />

  return (
    <div className="relative w-full h-full pt-2">
      {loading && (
        <div className="absolute inset-0 backdrop-blur z-10">
          <Spinner
            classes="relative"
            styleType="loader"
            description={undefined}
          />
        </div>
      )}
      {data.length > 0
        ? <div id={id} className="h-full" />
        : (
          <div className="w-full h-full flex items-center justify-center text-gray-400 text-xs">
            <FormattedMessage id="no_data" />
          </div>
        )
      }
    </div>
  );
}