import React, {useState} from "react";
import Toggle from "@ui-components/Toggle";
import {ItemTable} from "../item-table"
import {useIntl} from "react-intl";

export function EntryItem() {
  const [filterIncomplete, setFilterIncomplete] = useState<boolean>(false);

  const intl = useIntl();

  return (
    <>
      <Toggle
        classNames="my-10 md:my-5 w-full md:w-1/6 mx-auto md:mx-10"
        label={intl.formatMessage({id: "to_be_compiled"})}
        checked={filterIncomplete}
        onChange={() => setFilterIncomplete(prevState => !prevState)}/>
      <ItemTable notCompleted={filterIncomplete}/>
    </>
  )
}