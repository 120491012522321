import React from "react";
import {IntlShape} from "react-intl";
import {MTColumnsType} from "@utils/pagination";
import TableDatePicker from "@components/Tables/Controls/TableDatePicker";
import {IS_DC_ENABLED} from "@feature/commons";
import {AlertOverStockSchema} from "../../types";
import {formatValue} from "../lib/format";

type T = AlertOverStockSchema;

export const getColumns = (divisions: string[], intl: IntlShape): MTColumnsType<T> => [
  {
    title: intl.formatMessage({id: "warehouse"}),
    field: "warehouse.des_dc",
    type: "string",
    hidden: !IS_DC_ENABLED,
    render: ({cod_dc, warehouse: {des_dc}}) => <>
      <p className="font-bold">{cod_dc}</p>
      <p>{des_dc}</p>
    </>,
    pagination: {
      spech: {
        column_name: ["cod_dc", "warehouse.des_dc"],
      }
    },
  },
  {
    title: intl.formatMessage({id: "reference_date"}),
    field: "dat_stock",
    type: "date",
    dateSetting: {format: "dd/MM/yyyy"},
    cellStyle: {textAlign: "center"},
    filterComponent: (props: any) => <TableDatePicker tableProps={props} minDate={null} classNames="ml-16"/>,
  },
  {
    title: intl.formatMessage({id: "Divisione"}),
    field: "des_division",
    lookup: divisions.reduce((acc, curr) => ({...acc, [curr]: curr}), {}),
    cellStyle: {fontSize: "smaller"},
    headerStyle: {maxWidth: 95},
  },
  {
    title: intl.formatMessage({id: "Materiale"}),
    field: "des_product",
    type: "string",
    render: ({cod_product, des_product}) => <>
      <p className="font-bold">{cod_product}</p>
      <p>{des_product}</p>
    </>,
    headerStyle: {minWidth: 200},
    pagination: {
      spech: {
        column_name: ["cod_product", "des_product"],
      }
    },
  },
  {
    title: intl.formatMessage({id: "weight"}),
    field: "val_over_stock_kg",
    type: "numeric",
    render: ({val_over_stock_kg}) => formatValue(val_over_stock_kg, "KG"),
  },
];

export const getExportColumnMappings = (intl: IntlShape) => {
  const msg = (msgId: string) => intl.formatMessage({id: msgId});
  return {
    ...(IS_DC_ENABLED ? {[`${msg("code")} ${msg("warehouse")}`]: "cod_dc", [msg("warehouse")]: "des_dc"} : {}),
    [msg("reference_date")]: "dat_stock",
    [msg("Divisione")]: "des_division",
    [`${msg("code")} ${msg("Materiale")}`]: "cod_product",
    [msg("Materiale")]: "des_product",
    [msg("weight")]: "val_over_stock_kg",
  }
};