import italianRegionsGeodata from "@amcharts/amcharts5-geodata/italyLow";
import italianProvincesGeodata from "@amcharts/amcharts5-geodata/italyProvincesLow";
import {FeatureCollection} from "geojson";

export type MapPrecision = 'region' | 'province';
type CountryData = {
  [key: string]: {
    geoData: Record<MapPrecision, FeatureCollection>,
    defaultPrecision: MapPrecision,
    exclude?: string[],
    prefix: string,
  }
};

export const COUNTRY_DATA: CountryData = {
  italy: {
    geoData: {
      region: italianRegionsGeodata,
      province: italianProvincesGeodata,
    },
    defaultPrecision: 'province',
    exclude: ["FR-H", "MT"],  // Corsica and Malta
    prefix: 'IT-',
  },
};