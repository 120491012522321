import {useContext, useEffect, useState} from "react";
import {useAsyncError} from "@hooks/useAsyncError";
import {NotificationsContext} from "@ui-components/Notifications";
import {api} from "@services/apiRequest";
import {AlertCountsReturnType, AlertCountsSchema} from "../types";
import {useIntl} from "react-intl";

export const useInventoryAlertCounts = (runId: number | null): AlertCountsReturnType => {

  // hooks
  const {push} = useContext(NotificationsContext);
  const throwError = useAsyncError();
  const intl = useIntl();

  // state
  const [data, setData] = useState<AlertCountsSchema | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {

    // skip if the run id is yet to be defined
    if (runId === null)
      return;

    // fetch alert counts
    (async () => {
      setLoading(true);
      try {
        const {data} = await api.get<AlertCountsSchema>(`/inventory/${runId}/alert-counts`);
        setData(data);
      } catch (e) {
        push({title: intl.formatMessage({id: 'generic_error'}), type: "error"});
        throwError(e);
      } finally {
        setLoading(false);
      }
    })();

  }, [runId, push, throwError, intl]);

  return {counts: data, loading};
};
