import React from "react";

const Checkbox = ({readOnly, id, name, checked, classNames, onChange, disabled = false}) => {
  return <input
    type="checkbox"
    id={id}
    name={name}
    checked={checked}
    readOnly={readOnly}
    onChange={onChange || (() => null)}
    className={`focus:ring-am-500 h-4 w-4 text-am-600 border-gray-300 rounded ${classNames} ${disabled && 'opacity-90 pointer-events-none'}`}
  />
}

export default Checkbox;