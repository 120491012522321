import React from "react";
import {MaterialTableCustomCellProps} from "./MaterialTableCustomCell.types";

function MaterialTableCustomCell({data1, data2, vertical = false, substitution_value}: MaterialTableCustomCellProps) {

  const new_value = substitution_value &&
  substitution_value.toString() !== data1.toString()
    ? substitution_value
    : undefined;

  return (
    <div className={`${substitution_value && 'flex flex-col items-center'}`}>
      {
        new_value &&
        <span className={`flex ${vertical ? 'flex-col' : 'gap-x-2 text-sm'}`}>
          <b>{new_value}</b>
          {data2 && <p>{data2}</p>}
        </span>
      }
      <span
        className={`flex ${vertical ? 'flex-col' : 'gap-x-2'} ${new_value && 'line-through text-xs'}`}>
      <b>{data1}</b>
        {data2 && <p>{data2}</p>}
    </span>
    </div>
  )
}

export default MaterialTableCustomCell;