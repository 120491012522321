import React, {useState} from "react";
import {useIntl} from "react-intl";
import {PencilSquareIcon, PlusCircleIcon} from "@heroicons/react/24/outline";
import Tooltip from "@ui-components/Tooltip";
import {PalletizationHeaderProps, useUnassignedItems} from "@feature/entry";
import Toggle from "@ui-components/Toggle";
import Placeholder from "@ui-components/Placeholder";
import {AssignModal} from "@feature/entry/palletization/assign-address";

export const PalletizationHeader = ({
	                                    showInvalidConstraints,
	                                    setShowInvalidConstraints,
	                                    setOpenCreateGroup,
													refresh,
                                    }: PalletizationHeaderProps) => {
	
	const intl = useIntl();
	
	const {unassignedItems, loading, retriggerUnassignedCount} = useUnassignedItems();

	const refreshData = () => {
		refresh();
		retriggerUnassignedCount();
	}
	
	const [openAssignDialog, setOpenAssignDialog] = useState<boolean>(false);
	
	return (
		<>
			{
				!loading
					? (
						<div className="flex flex-col md:flex-row justify-around gap-x-8 mb-6">
							<div className="flex gap-x-2 items-center">
								<Tooltip
									placement="right"
									text={intl.formatMessage({id: "grouping_tooltip"})}
								/>
								<span className="align-middle">{intl.formatMessage({id: "create_new_grouping"})}</span>
								<PlusCircleIcon
									className="w-7 h-7 cursor-pointer text-am-700"
									onClick={() => setOpenCreateGroup(true)}
								/>
							</div>
							{
								unassignedItems.length > 0 &&
                <div className="flex gap-x-2 items-center">
                  <Tooltip
                    placement="right"
                    text={intl.formatMessage({id: "unassigned_tooltip"})}/>
									{intl.formatMessage({id: "unassigned_items"}, {unassigned_num: unassignedItems.length})}
                  <PencilSquareIcon
                    className="w-8 h-8 cursor-pointer text-am-700"
                    onClick={() => setOpenAssignDialog(true)}
                  />
                </div>
							}
							<Toggle
								label={intl.formatMessage({id: "wrong_suppliers"})}
								onChange={() => setShowInvalidConstraints(prevState => !prevState)}
								checked={showInvalidConstraints}/>
						</div>
					)
					: <Placeholder height="h-20"/>
			}
			<AssignModal
				opened={openAssignDialog && unassignedItems.length > 0}
				onExit={() => setOpenAssignDialog(false)}
				unassignedItems={unassignedItems}
				refresh={refreshData}/>
		</>
	)
}
	